import React, {useEffect, useState} from 'react';
import 'leaflet/dist/leaflet.css';
import {Icon, LatLngLiteral} from "leaflet";
import {MapContainer, Marker, Popup, TileLayer, useMap, useMapEvents} from "react-leaflet";

export interface IMapComponentProps {
  coordinates?: LatLngLiteral;
  zoom?: number;
  updateCoordinates?: (coordinates: LatLngLiteral) => void;
  updateZoom?: (zoom: number) => void;
}
const MapComponent = ({coordinates, zoom = 10, updateCoordinates, updateZoom} : IMapComponentProps) => {

  const icon = new Icon({
    iconUrl: "https://unpkg.com/leaflet@1.9.3/dist/images/marker-icon.png",
    iconSize: [25, 40],
    iconAnchor: [12, 12],
    popupAnchor: [0, 0],
  });

  return (
    <MapContainer
      center={coordinates ?? {lat: 50.85045, lng: 4.34878} }
      zoom={zoom}
      style={{ width: '100%', height: '600px' }}
    >
      <TileLayer
        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
        attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
      />
      {coordinates && (
        <Marker icon={icon} position={coordinates}>
          <Popup>Your Marker</Popup>
        </Marker>
      )}
      <MapEventsHandler
        coordinates={coordinates}
        zoom={zoom}
        updateCoordinates={updateCoordinates}
        updateZoom={updateZoom}
      />
    </MapContainer>
  );
};

const MapEventsHandler = ({ coordinates, zoom, updateCoordinates, updateZoom }: IMapComponentProps) => {
  const map = useMap();

  useEffect(() => {
    if(!coordinates) {
      return;
    }

    map.panTo(coordinates);
    if(zoom) {
      map.setZoom(zoom);
    }
  }, [coordinates, zoom]);

  const mapEvents = useMapEvents({
    zoomend: () => {
      if(updateZoom) {
        updateZoom(mapEvents.getZoom());
      }
    },
    click: (e) => {
      if(updateCoordinates) {
        updateCoordinates(e.latlng);
      }
    },
  });
  return null;
};

export default MapComponent;

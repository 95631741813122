import { ISelectOption } from '../components/forms/select-component';

export enum EditTeamView {
  general = 'general',
  media = 'media',
  matches = 'matches'
}

export enum EditParams {
  teamId = 'team',
  seriesId = 'series',
  view = 'view'
}

import React, {createContext, useContext, useState} from 'react';
import {Series, Team} from '../api-client/sport-bo-api';
import { useEditTeam } from '../hooks/edit-team-hook';
import {useEditSeries} from "../hooks/edit-series-hook";
import {EditTeamView} from "../models/edition-enums";

export interface IEditionContextProps {
  team: Team | undefined;
  updateTeam: (team: Team | undefined) => void;
  series: Series | undefined;
  updateSeries: (series: Series | undefined) => void;
  reload: boolean;
  setReload: (reload: boolean) => void;
  view: EditTeamView;
  setView: (view: EditTeamView) => void;
}

export const EditionContext = createContext<IEditionContextProps | null>(null);

interface IEditionContextProviderProps {
  children: React.ReactNode;
}
export const EditionContextProvider = (props: IEditionContextProviderProps) => {
  const [reload, setReload] = useState(false);
  const { team, updateTeam: setTeam, view, setView } = useEditTeam({ reload });
  const { series, updateSeries: setSeries } = useEditSeries({ reload });

  const updateSeries = (series?: Series) => {
    setTeam(undefined);
    setSeries(series);
  }

  const updateTeam = (team?: Team) => {
    setSeries(undefined);
    setTeam(team);
  }

  return (
    <EditionContext.Provider value={{ team, updateTeam, series, updateSeries, reload, setReload, view, setView }}>
      {props.children}
    </EditionContext.Provider>
  );
};

export const useEditionContext = () => {
  const context = useContext(EditionContext);

  if (!context) {
    throw new Error('useEditionContext has to be used within <EditionContext.Provider>');
  }

  return context;
};

import React, { useEffect, useState } from 'react';
import { Alert } from 'react-bootstrap';
import { Variant } from 'react-bootstrap/types';
import { useAppContext } from '../../contexts/app-context';

export interface IAlertComponentProps {
  header: string;
  variant?: Variant;
}
export const AlertGeneral = () => {
  const { alert } = useAppContext();
  const [alertGeneral, setAlertGeneral] = useState<IAlertComponentProps | undefined>();
  const [show, setShow] = useState(false);

  useEffect(() => {
    if(!alert && !show) {
      setAlertGeneral(alert);
      fadeAwayTimer();
    }
    if (alert) {
      setShow(true);
      setAlertGeneral(alert);
      fadeAwayTimer();
    }
  }, [alert]);

  const fadeAwayTimer = () => {
    setTimeout(() => {
      setShow(false);
      setAlertGeneral(undefined);
    }, 3000);
  }

  return (
    <>
      {alertGeneral && (
        <Alert
          className="alert-fixed"
          show={show}
          variant={alertGeneral.variant ?? 'primary'}
          onClose={() => setShow(false)}
          dismissible>
          {alertGeneral.header}
          <button
            type="button"
            className="btn-close"
            data-bs-dismiss="alert"
            aria-label="Close"></button>
        </Alert>
      )}
    </>
  );
};

import React, { useState } from 'react';
import { TeamMedia } from '../../api-client/sport-bo-api';
import { TeamMediaEditionModalComponent } from './team-media-edition-modal-component';
import { ternary } from '../../utils/ternary-util';

export interface ITeamMediaListComponentProps {
  teamMedia: TeamMedia;
  setReload?: (reload: boolean) => void;
}
export const TeamMediaListComponent = (props: ITeamMediaListComponentProps) => {
  const [show, setShow] = useState(false);
  return (
    <>
      <div className="team-media-item" onClick={() => setShow(true)}>
        <img alt={'no picture.'} src={props.teamMedia.media?.url} />
        <span>
          {props.teamMedia.type} ({ternary(props.teamMedia.validated, 'validé', 'à valider')})
        </span>
      </div>

      <TeamMediaEditionModalComponent
        teamMedia={props.teamMedia}
        show={show}
        setShow={setShow}
        setReload={props.setReload}
      />
    </>
  );
};

import React from 'react';
import { UseFormRegister } from 'react-hook-form/dist/types/form';
import { FieldValues } from 'react-hook-form/dist/types/fields';

export interface IInputComponentProps {
  label?: string;
  name: string;
  register: UseFormRegister<FieldValues>;
  type?: string;
  className?: string | undefined;
  required?: boolean;
}
export const Input = (props: IInputComponentProps) => {
  return (
    <div className={`mb-3 d-flex flex-row ${props.className}`}>
      {props.label && (
        <label htmlFor={props.name} className="col-form-label">
          {props.label}
        </label>
      )}

      <input
        id={props.name}
        type={props.type ?? 'text'}
        className="form-control"
        {...props.register(props.name, { required: props.required })}
      />
    </div>
  );
};

import React, { useEffect, useState } from 'react';
import { Input } from '../../forms/input-component';
import { useAppContext } from '../../../contexts/app-context';
import { useForm } from 'react-hook-form';
import { addMediaToTeam, createSportTeamMediaApi } from '../../../services/sport-services';
import { AddTeamMediaForm } from '../../../models/add-team-media-form';
import { emptyPage, Page } from '../../../models/search';
import { SearchNavbar } from '../../search/search-navbar-component';
import { TeamMediaListComponent } from '../../media/team-media-list-component';
import Accordion from 'react-bootstrap/Accordion';
import { Select } from '../../forms/select-component';
import { teamMediaTypeList } from '../../../models/team-media-enum';
import { SearchContextProvider, useSearchContext } from '../../../contexts/search-content';
import { PageTeamMedia } from '../../../api-client/sport-bo-api';
import { SpinnerLoader } from '../../utils/spinner-loader-component';
import {useEditionContext} from "../../../contexts/edition-context";

export const TeamMediaEditionComponent = () => {
  return (
    <SearchContextProvider>
      <TeamMediaEditionComponentContent />
    </SearchContextProvider>
  );
};

const TeamMediaEditionComponentContent = () => {
  const { makeApiCall, auth } = useAppContext();
  const { search, setSearch } = useSearchContext();
  const { team, reload, setReload } = useEditionContext();
  const [medias, setMedias] = useState<PageTeamMedia>(emptyPage());
  const [formLoad, setFormLoad] = useState(false);
  const {
    register,
    handleSubmit,
    formState: { errors }
  } = useForm();

  useEffect(() => {
    doSearch();
  }, [team, search, reload]);

  const doSearch = async () => {
    setMedias(
      (await makeApiCall(
          (await createSportTeamMediaApi(auth)).searchTeamMedias(team?.id, undefined, search.pageable.page, search.pageable.size, search.pageable.sort)
      )) ?? emptyPage()
    );
    setReload(false);
  };

  const onSubmit = async (form: AddTeamMediaForm) => {
    if (form.fileList && form.fileList.length > 0 && form.type) {
      const file = form.fileList[0];
      const dto = {
        mediaType: 'image',
        teamId: team?.id,
        validated: true,
        source: '',
        teamMediaType: form.type
      };

      setFormLoad(true);
      await makeApiCall(addMediaToTeam(dto, file));
      setFormLoad(false);
      await doSearch();
    }
  };

  return (
    <>
      <div className="p-3">
        {formLoad ? (
          <SpinnerLoader />
        ) : (
          <form onSubmit={handleSubmit(onSubmit)}>
            <Input label="Upload" type="file" name="fileList" register={register} required />
            <Select
              label="Type"
              name="type"
              register={register}
              options={teamMediaTypeList}
              defaultOption="Sélectionner un type"
              required
            />
            <button type="submit" className="btn btn-primary">
              Envoyer
            </button>
          </form>
        )}
      </div>

      <Accordion defaultActiveKey={['0']} alwaysOpen>
        <Accordion.Item eventKey="0">
          <Accordion.Header>Galerie</Accordion.Header>
          <Accordion.Body>
            <div>
              <div className="d-flex">
                {medias.content &&
                  medias.content.map((media) => (
                    <TeamMediaListComponent
                      key={media.id}
                      teamMedia={media}
                      setReload={setReload}
                    />
                  ))}
              </div>
              <SearchNavbar page={medias as Page} onlyPagination simplePagination />
            </div>
          </Accordion.Body>
        </Accordion.Item>
      </Accordion>
    </>
  );
};

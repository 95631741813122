import React, { useEffect, useState } from 'react';
import Accordion from 'react-bootstrap/Accordion';
import { TableSearchHeader } from '../../table/table-search-header-component';
import { SearchNavbar } from '../../search/search-navbar-component';
import { emptyPage, Page } from '../../../models/search';
import { PageMatch } from '../../../api-client/sport-bo-api';
import { createSportMatchApi } from '../../../services/sport-services';
import { useAppContext } from '../../../contexts/app-context';
import { SearchContextProvider, useSearchContext } from '../../../contexts/search-content';
import { TeamMatchEditionLine } from './team-match-edition-line-component';
import { SpinnerLoader } from '../../utils/spinner-loader-component';
import {
  EditMatchesContextProvider,
  useEditMatchesContext
} from '../../../contexts/edit-matches-context';
import { useKeyDown } from '../../../hooks/use-key-down-hook';
import {useEditionContext} from "../../../contexts/edition-context";

export const TeamMatchesEditionComponent = () => {
  return (
    <SearchContextProvider>
      <EditMatchesContextProvider>
        <TeamMatchesEditionComponentContent />
      </EditMatchesContextProvider>
    </SearchContextProvider>
  );
};

const TeamMatchesEditionComponentContent = () => {
  const { makeApiCall, auth } = useAppContext();
  const { team } = useEditionContext();
  const { search, setSearch } = useSearchContext();
  const { editedMatchMap, updateEditedMatchMap, updateEditedCellMap, updateFocusedCellMap } =
    useEditMatchesContext();
  const [matches, setMatches] = useState<PageMatch>(emptyPage());
  const [loading, setLoading] = useState<boolean>(false);

  useKeyDown(() => {
    updateFocusedCellMap.reset();
  }, ['Escape']);

  useEffect(() => {
    setSearch({ ...search, pageable: { ...search.pageable, page: 1, sort: ['date,asc'] } });
  }, []);

  useEffect(() => {
    doSearch();
  }, [search]);

  const doSearch = async () => {
    const teamId = team?.id;

    if (!teamId) {
      return;
    }

    setMatches(
      (await makeApiCall(
          (await createSportMatchApi(auth)).searchMatches(undefined, teamId, undefined, undefined, undefined, search.pageable.page, search.pageable.size, search.pageable.sort)
      )) ?? emptyPage()
    );
  };

  const handleSaveMatchesEdition = async () => {
    setLoading(true);

    for await (const match of Array.from(editedMatchMap.values())) {
      await makeApiCall((await createSportMatchApi(auth)).updateMatch(match.id!, match));
    }

    updateEditedMatchMap.reset();
    updateEditedCellMap.reset();
    updateFocusedCellMap.reset();

    await doSearch();

    setLoading(false);
  };

  return (
    <div>
      <Accordion defaultActiveKey={['0']} alwaysOpen>
        <Accordion.Item eventKey="0">
          <Accordion.Header>Liste des matchs</Accordion.Header>
          <Accordion.Body>
            {loading ? (
              <SpinnerLoader />
            ): (
              matches.content && matches.content.length > 0 ? (
                  <>
                    <div className="d-flex justify-content-between">
                      <SearchNavbar page={matches as Page} disabled={loading} />
                      <div className="d-flex align-items-center">
                        <button
                          type="submit"
                          className="btn btn-primary"
                          disabled={loading || editedMatchMap.size === 0}
                          onClick={handleSaveMatchesEdition}>
                          Sauvegarder
                        </button>
                      </div>
                    </div>
                    <table className="table">
                      <thead>
                      <tr>
                        <TableSearchHeader property="weekNumber">Semaine</TableSearchHeader>
                        <TableSearchHeader property="day">Jour</TableSearchHeader>
                        <TableSearchHeader property="date">Date</TableSearchHeader>
                        <TableSearchHeader property="homeTeam.name">Equipe dom.</TableSearchHeader>
                        <TableSearchHeader property="result.homeScore">Score dom.</TableSearchHeader>
                        <TableSearchHeader property="result.awayScore">Score ext.</TableSearchHeader>
                        <TableSearchHeader property="awayTeam.name">Equipe ext.</TableSearchHeader>
                        <TableSearchHeader property="type">Type</TableSearchHeader>
                        <TableSearchHeader property="status">Statut</TableSearchHeader>
                        <TableSearchHeader property="referee">Arbitre</TableSearchHeader>
                        {/*<TableSearchHeader property="referee">Commentaire</TableSearchHeader>*/}
                      </tr>
                      </thead>
                      <tbody>
                        {matches.content.map((match) => (
                          <TeamMatchEditionLine key={match.id} match={match} />
                        ))}
                      </tbody>
                    </table>
                  </>
                ) : (
                  <span>Pas de match à afficher pour cette équipe.</span>
                )
            )}
          </Accordion.Body>
        </Accordion.Item>
      </Accordion>
    </div>
  );
};

import React from 'react';
import { ISelectOption } from './select-component';

export interface ISelectionOptionsProps {
  options: ISelectOption[];
  defaultOption?: string;
}
export const SelectionOptions = (props: ISelectionOptionsProps) => {
  return (
    <>
      {props.defaultOption && (
        <option key={props.defaultOption} value="">
          {props.defaultOption}
        </option>
      )}
      {props.options.map((option) => (
        <option key={option.id} value={option.id}>
          {option.name}
        </option>
      ))}
    </>
  );
};

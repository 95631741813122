import React, { KeyboardEvent } from 'react';
import { Match } from '../../../api-client/sport-bo-api';
import { useEditMatchesContext } from '../../../contexts/edit-matches-context';
import { ternary } from '../../../utils/ternary-util';
import { getCellId } from '../../../utils/matches-table-utils';

export interface ITeamMatchEditionCellProps {
  match: Match;
  name: string;
  value?: any;
  children: React.ReactNode;
}
export const TeamMatchEditionCell = (props: ITeamMatchEditionCellProps) => {
  const {
    lastFocusedCellId,
    setLastFocusedCellId,
    editedCellMap,
    focusedCellMap,
    updateFocusedCellMap
  } = useEditMatchesContext();
  const cellId = getCellId(props.match, props.name);

  const isEdited = (): boolean => {
    return editedCellMap.has(cellId) && (editedCellMap.get(cellId) ?? false);
  };

  const handleFocus = () => {
    if (lastFocusedCellId) {
      updateFocusedCellMap.remove(lastFocusedCellId);
    }

    updateFocusedCellMap.set(cellId, true);
    setLastFocusedCellId(cellId);
  };

  const handleKeyDown = (event: KeyboardEvent<HTMLElement>) => {
    if (event.key === 'Escape') {
      updateFocusedCellMap.remove(cellId);
      setLastFocusedCellId(undefined);
    }
  };

  return (
    <td
      className={`table-search-cell ${ternary(isEdited(), 'edited')}`}
      onClick={handleFocus}
      onKeyDown={handleKeyDown}>
      {focusedCellMap.has(cellId) && focusedCellMap.get(cellId) ? props.children : props.value}
    </td>
  );
};

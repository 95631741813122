import React, {useState} from 'react';
import { UseFormSetValue } from 'react-hook-form/dist/types/form';
import { FieldValues } from 'react-hook-form/dist/types/fields';
import {Team, TeamUpdateProposal} from '../../../api-client/sport-bo-api';
import { getPropertyFromObject } from '../../../utils/object-utils';

export interface ITeamGeneralEditionFieldProposalProps {
  name: string;
  team: Team;
  proposals: TeamUpdateProposal[];
  setValue: UseFormSetValue<FieldValues>;
}
export const TeamGeneralEditionFieldProposal = (props: ITeamGeneralEditionFieldProposalProps) => {
  const [accepted, setAccepted] = useState(false);

  const acceptProposal = (proposal: TeamUpdateProposal) => {
    props.setValue(props.name, getPropertyFromObject(proposal, props.name));
    setAccepted(true);
  };

  return (
    <>
      {!accepted && (
        <div className="team-update-proposals">
          {props.proposals
            .filter((proposal, index) => {
              const proposedValue = getPropertyFromObject(proposal, props.name);

              if(proposedValue == null) {
                return false;
              }

              if(props.proposals.find((proposal) =>
                getPropertyFromObject(proposal, props.name) === proposedValue) !== proposal) {
                return false;
              }

              const teamValue = props.team[props.name] ?? '';

              return proposedValue !== teamValue;
            })
            .map((proposal) => (
              <div key={proposal.id} className="d-flex align-items-center mb-2">
                <>
                  {getPropertyFromObject(proposal, props.name)}
                  <button
                    type="button"
                    className="btn btn-light ms-2"
                    onClick={() => acceptProposal(proposal)}>
                    <i className="fa-solid fa-check"></i>
                  </button>
                </>
              </div>
            ))}
        </div>
      )}
    </>
  );
};

import React from 'react';
import { Outlet } from 'react-router-dom';
import { NavLink } from './nav-link';
import {Dropdown} from "react-bootstrap";
import {useMsal} from "@azure/msal-react";

const Layout = () => {
  const { instance, accounts } = useMsal();

  const handleLogout = () => {
    instance.logoutPopup({
      postLogoutRedirectUri: '/',
      mainWindowRedirectUri: '/',
    });
  };

  return (
    <>
      <nav className="app-header navbar navbar-expand-lg navbar-light bg-light">
        <div className="container-fluid">
          <a className="navbar-brand" href="/">
            Sport BO
          </a>
          <button
            className="navbar-toggler"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbarText"
            aria-controls="navbarText"
            aria-expanded="false"
            aria-label="Toggle navigation">
            <span className="navbar-toggler-icon"></span>
          </button>
          <div className="collapse navbar-collapse" id="navbarText">
            <ul className="navbar-nav me-auto mb-2 mb-lg-0">
              <li className="nav-item">
                <NavLink route="/teams/treat" otherActiveRoutes={['/']}>
                  A traiter
                </NavLink>
              </li>
              <li className="nav-item">
                <NavLink route="/teams/browse">Parcourir tout</NavLink>
              </li>
              <li className="nav-item">
                <NavLink route="/teams/search">Rechercher</NavLink>
              </li>
            </ul>

            <Dropdown title={accounts.length > 0 ? accounts[0].name : ''}>
              <div className="dropdown-item">{accounts.length > 0 ? accounts[0].username : ''}</div>
              <div className="dropdown-divider"></div>
              <button className="dropdown-item" onClick={handleLogout}>
                Se déconnecter
              </button>
            </Dropdown>
          </div>
        </div>
      </nav>

      <div className="layout">
        <Outlet />
      </div>
    </>
  );
};

export default Layout;

import {AccountInfo} from "@azure/msal-browser";

export const aadRoles = {
  admin: 'aiglon.admin',
  journalist: 'aiglon.marketing',
  horoscope: 'aiglon.advertising',
  redaction: 'aiglon.redaction',
  serviceDesk: 'aiglon.webmaster',
};

export const checkUserRolesHasRoles = (userRoles, authorizedRoles) => {
  return authorizedRoles.some((role) => userRoles.includes(role));
};

export const checkUserIsAuthorized = (accounts: AccountInfo[], authorizedRoles) => {
  if (!Array.isArray(accounts) || accounts.length === 0) {
    return false;
  }

  const user = accounts[0];

  return (
    user &&
    user.idTokenClaims &&
    Array.isArray(user.idTokenClaims.roles) &&
    checkUserRolesHasRoles(user.idTokenClaims.roles, authorizedRoles)
  );
};

export const checkUserIsAuthorizedAdmin = (accounts: AccountInfo[]) => {
  return checkUserIsAuthorized(accounts, [aadRoles.admin]);
};

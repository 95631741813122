import React, { useEffect, useState } from 'react';
import {
  convertOrdersToSortStringArray,
  convertSortStringArrayToOrders,
  SortOrderDirection
} from '../../models/search';
import { ternary } from '../../utils/ternary-util';
import { useSearchContext } from '../../contexts/search-content';

export interface ITableHeaderProps {
  property: string;
  children: React.ReactNode;
}
export const TableSearchHeader = (props: ITableHeaderProps) => {
  const { search, setSearch } = useSearchContext();
  const [direction, setDirection] = useState<SortOrderDirection | undefined>();

  useEffect(() => {
    if (search.pageable.sort && search.pageable.sort.length > 0) {
      const order = convertSortStringArrayToOrders(search.pageable.sort).find(
        (order) => order.property === props.property
      );

      if (order) {
        setDirection(order.direction);
        return;
      }
    }

    setDirection(undefined);
  }, [search]);

  const handleClick = () => {
    const newDirection =
      direction === SortOrderDirection.asc ? SortOrderDirection.desc : SortOrderDirection.asc;
    const sortArray = convertOrdersToSortStringArray([
      { property: props.property, direction: newDirection }
    ]);

    setSearch({ ...search, pageable: { ...search.pageable, sort: sortArray } });
    setDirection(newDirection);
  };

  return (
    <th
      className={`table-search-header ${ternary(direction !== undefined, 'sorted')}`}
      scope="col"
      onClick={handleClick}>
      {props.children}
      {direction !== undefined && (
        <span className={`ms-1 caret ${ternary(direction === SortOrderDirection.desc, 'down')}`} />
      )}
    </th>
  );
};

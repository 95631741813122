import { ISelectOption } from '../components/forms/select-component';

export const matchStatusList: ISelectOption[] = [
  { id: 'AJO', name: 'AJO' },
  { id: 'JOU', name: 'JOU' },
  { id: 'XXX', name: 'XXX' },
  { id: 'RM', name: 'RM' },
  { id: 'FA', name: 'FA' },
  { id: 'FH', name: 'FH' },
  { id: 'NC', name: 'NC' },
  { id: 'ARR', name: 'ARR' },
  { id: 'DF', name: 'DF' },
  { id: 'BJO', name: 'BJO' },
  { id: 'BNC', name: 'BNC' },
  { id: 'NJ', name: 'NJ' },
  { id: 'BRM', name: 'BRM' },
  { id: 'PAS', name: 'PAS' },
  { id: 'PJO', name: 'PJO' },
  { id: 'BOW', name: 'BOW' },
  { id: 'F2T', name: 'F2T' }
];

export const matchTypeList: ISelectOption[] = [
  { id: 'aller', name: 'aller' },
  { id: 'retour', name: 'retour' }
];

export const matchDayList: ISelectOption[] = [
  { id: 'Lundi', name: 'Lundi' },
  { id: 'Mardi', name: 'Mardi' },
  { id: 'Mercredi', name: 'Mercredi' },
  { id: 'Jeudi', name: 'Jeudi' },
  { id: 'Vendredi', name: 'Vendredi' },
  { id: 'Samedi', name: 'Samedi' },
  { id: 'Dimanche', name: 'Dimanche' }
];

import React, { useEffect, useState } from 'react';
import { Group, Series } from '../../api-client/sport-bo-api';
import { createSportGroupApi, createSportSeriesApi } from '../../services/sport-services';
import { Menu, Item, useContextMenu, TriggerEvent } from 'react-contexify';
import { SeriesTreeComponent } from '../series/series-tree-component';
import { ternary } from '../../utils/ternary-util';
import { SpinnerLoader } from '../utils/spinner-loader-component';
import { useAppContext } from '../../contexts/app-context';
import {useEditionContext} from "../../contexts/edition-context";

export interface IGroupTreeComponentProps {
  group: Group;
  forceLoadChildren?: boolean;
}
export const GroupTreeComponent = (props: IGroupTreeComponentProps) => {
  const { makeApiCall, auth } = useAppContext();
  const MENU_ID = `group-context-menu-${props.group.id}`;
  const { show } = useContextMenu({
    id: MENU_ID
  });
  const [loading, setLoading] = useState(false);
  const [childrenLoaded, setChildrenLoaded] = useState(false);
  const [displayChildren, setDisplayChildren] = useState(props.forceLoadChildren);
  const [forceLoadChildren, setForceLoadChildren] = useState(props.forceLoadChildren);
  const [groupChildren, setGroupChildren] = useState<Group[]>([]);
  const [seriesChildren, setSeriesChildren] = useState<Series[]>([]);
  const { updateTeam, updateSeries, setReload } = useEditionContext();

  useEffect(() => {
    setForceLoadChildren(props.forceLoadChildren);

    if (props.forceLoadChildren) {
      setDisplayChildren(true);
      loadChildren();
    }
  }, [props.forceLoadChildren]);

  const handleContextMenu = (event: TriggerEvent) => {
    show({
      event
    });
  };

  const handleDisplayChildren = async () => {
    setDisplayChildren(!displayChildren);
    updateTeam(undefined);
    updateSeries(undefined);
    await loadChildren();
  };

  const loadChildren = async () => {
    if (childrenLoaded) {
      return;
    }

    setLoading(true);

    if (props.group.leaf) {
      setSeriesChildren(
        (await makeApiCall(
            (await createSportSeriesApi(auth)).getSeriesInGroupById(props.group.id!)
        )) ?? []
      );
    } else {
      setGroupChildren(
        (await makeApiCall((await createSportGroupApi(auth)).getGroupsByParentId(props.group.id!))) ??
          []
      );
    }

    setLoading(false);
    setChildrenLoaded(true);
    setDisplayChildren(true);
  };

  const handleSetGroupAsDefault = async () => {
    await makeApiCall((await createSportGroupApi(auth)).setGroupAsDefault(props.group.id!));
  };

  const handleLoadAllHierarchy = async () => {
    setForceLoadChildren(true);
    setDisplayChildren(true);
    updateTeam(undefined);
    updateSeries(undefined);
    await loadChildren();
    setTimeout(() => setForceLoadChildren(false), 1000);
  };

  return (
    <li>
      <div
        className={`caret ${ternary(displayChildren, 'down')} ${ternary(
          props.group.default,
          'fw-bold'
        )}`}
        onClick={handleDisplayChildren}
        onContextMenu={handleContextMenu}>
        <i className="fa-regular fa-folder"></i> {props.group.name}
      </div>
      {loading && <SpinnerLoader />}
      <ul className={ternary(displayChildren, '', 'd-none')}>
        {props.group.leaf ? (
          <>
            {seriesChildren.map((series: Series) => (
              <SeriesTreeComponent
                key={series.id}
                series={series}
                forceLoadChildren={forceLoadChildren}
              />
            ))}
          </>
        ) : (
          <>
            {groupChildren.map((group: Group) => (
              <GroupTreeComponent
                key={group.id}
                group={group}
                forceLoadChildren={forceLoadChildren}
              />
            ))}
          </>
        )}
      </ul>
      <Menu id={MENU_ID}>
        <Item id="set-default" onClick={handleSetGroupAsDefault}>
          Définir par défaut
        </Item>
        <Item id="open-hierarchy" onClick={handleLoadAllHierarchy}>
          Ouvrir tous les sous-groupes
        </Item>
      </Menu>
    </li>
  );
};

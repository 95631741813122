import React, { useEffect } from 'react';
import { TeamMatchesEditionComponent } from './team-matches-edition-component';
import { TeamMediaEditionComponent } from './team-media-edition-component';
import { TeamGeneralEditionComponent } from './team-general-edition-component';
import { useSearchParams } from 'react-router-dom';
import {useEditionContext} from "../../../contexts/edition-context";
import {NavTabElement} from "../../utils/nav-tab-element";
import {EditParams, EditTeamView} from "../../../models/edition-enums";

export const TeamEditionComponent = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const { team, view, setView } = useEditionContext();

  useEffect(() => {
    if (team && team.id) {
      const teamId = team.id.toString();
      setSearchParams((params) => {
        params.set(EditParams.teamId, teamId);
        return params;
      });
    }
  }, [team]);

  const handleViewChange = (newView: EditTeamView) => {
    setView(newView);
    setSearchParams((params) => {
      params.set(EditParams.view, newView);
      return params;
    });
  };

  return (
    <div>
      <ul className="nav nav-tabs">
        <li className="nav-item">
          <NavTabElement
            view={EditTeamView.general}
            currentView={view}
            handleClick={handleViewChange}
          >
            General
          </NavTabElement>
        </li>
        <li className="nav-item">
          <NavTabElement
            view={EditTeamView.media}
            currentView={view}
            handleClick={handleViewChange}
          >
            Media
          </NavTabElement>
        </li>
        <li className="nav-item">
          <NavTabElement
            view={EditTeamView.matches}
            currentView={view}
            handleClick={handleViewChange}
          >
            Matchs
          </NavTabElement>
        </li>
      </ul>
      <div>
        {team && <div className="p-3">Edition de {team.fullyQualifiedName}</div>}
        {view === EditTeamView.matches ? (
          <TeamMatchesEditionComponent />
        ) : (
          <>
            {view === EditTeamView.media ? (
              <TeamMediaEditionComponent />
            ) : (
              <TeamGeneralEditionComponent />
            )}
          </>
        )}
      </div>
    </div>
  );
};

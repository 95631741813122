import {SortObject} from '../api-client/sport-bo-api';
import {Pageable} from "./pageable";

export interface Search {
  query: string;
  searched: boolean;
  pageable: Pageable;
  type: SearchType;
}

export enum SearchType {
  teams = 'teams',
  series = 'series',
}

export interface Order {
  property: string;
  direction: SortOrderDirection;
}

export interface Page {
  totalPages?: number;
  totalElements?: number;
  size?: number;
  content?: Array<any>;
  number?: number;
  sort?: SortObject;
  pageable?: Pageable;
  first?: boolean;
  last?: boolean;
  numberOfElements?: number;
  empty?: boolean;
}

export const defaultSearch = (): Search => {
  return { query: '', searched: false, pageable: defaultPageable(), type: SearchType.teams };
};

export const emptyPage = () => {
  return { content: [] };
};

export const defaultPageable = (): Pageable => {
  return { page: 0, size: 10 };
};

export const convertSortStringArrayToOrders = (sort: string[]): Order[] => {
  return sort.map((sort) => convertSortStringToOrder(sort));
};
export const convertSortStringToOrder = (sort: string): Order => {
  if (!sort.includes(',')) {
    return { property: sort, direction: SortOrderDirection.asc };
  }

  const split = sort.split(',');
  const property = split[0];
  const direction = split[1] === 'desc' ? SortOrderDirection.desc : SortOrderDirection.asc;

  return { property, direction };
};

export const convertOrdersToSortStringArray = (orders: Order[]): string[] => {
  return orders.map((order) => convertOrderToSortString(order));
};
export const convertOrderToSortString = (order: Order): string => {
  return `${order.property},${order.direction}`;
};

export enum SortOrderDirection {
  asc = 'asc',
  desc = 'desc'
}

import React from 'react';
import { Team } from '../../api-client/sport-bo-api';
import { createSportTeamApi } from '../../services/sport-services';
import { useAppContext } from '../../contexts/app-context';
import {useEditionContext} from "../../contexts/edition-context";

export interface ITeamListElementComponentProps {
  team: Team;
  displayFullyQualifiedName?: boolean;
  separator?: boolean;
}
export const TeamListElementComponent = (props: ITeamListElementComponentProps) => {
  const { makeApiCall, auth } = useAppContext();
  const { updateTeam } = useEditionContext();

  const handleTeamSelection = async () => {
    updateTeam(await makeApiCall((await createSportTeamApi(auth)).getTeamsById(props.team.id!)));
  };

  return (
    <div>
      <button className="btn btn-link" onClick={handleTeamSelection}>
        {props.displayFullyQualifiedName && props.team.fullyQualifiedName ? props.team.fullyQualifiedName : props.team.name}
      </button>
      {props.separator && <hr className="search-separator" />}
    </div>
  );
};

import React, { KeyboardEvent, useEffect, useState } from 'react';
import { useSearchContext } from '../../contexts/search-content';

export const SearchQuery = () => {
  const { search, setSearch } = useSearchContext();
  const [query, setQuery] = useState<string>(search.query);

  useEffect(() => {
    setQuery(search.query);
  }, [search.query]);

  const handleKeyDown = (event: KeyboardEvent<HTMLElement>) => {
    if (event.key === 'Enter') {
      handleSearch();
    }
  };

  const handleSearch = () => {
    setSearch({ ...search, query, searched: true });
  };

  return (
    <div className="d-flex p-3">
      <input
        name="query"
        className="flex-grow-1"
        value={query}
        onKeyDown={handleKeyDown}
        onChange={(event) => setQuery(event.target.value)}
      />
      <button className="btn btn-secondary my-2 my-sm-0" type="submit" onClick={handleSearch}>
        Search
      </button>
    </div>
  );
};

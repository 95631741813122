import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'react-contexify/ReactContexify.css';
import '@fortawesome/fontawesome-free/css/fontawesome.min.css';
import '@fortawesome/fontawesome-free/css/solid.min.css';
import './App.css';
import { Routes, Route } from 'react-router-dom';
import Layout from './components/layout/layout';
import NoPage from './pages/404/404-page';
import TeamsToTreatPage from './pages/to-treat/teams-to-treat-page';
import { AlertGeneral } from './components/utils/alert-component';
import { AuthenticatedTemplate, MsalAuthenticationTemplate, UnauthenticatedTemplate } from '@azure/msal-react';
import { loginRequest } from './authConfig';
import { InteractionType } from '@azure/msal-browser';
import BrowsePage from "./pages/browse/browse-page";
import SearchPage from "./pages/search/search-page";

function App() {
  return (
    <div className="App">
      <AuthenticatedTemplate>
        <Routes>
          <Route path="/" element={<Layout />}>
            <Route index element={<TeamsToTreatPage />} />
            <Route path="teams/treat" element={<TeamsToTreatPage />} />
            <Route path="teams/browse" element={<BrowsePage />} />
            <Route path="teams/search" element={<SearchPage />} />
            <Route path="*" element={<NoPage />} />
          </Route>
        </Routes>

        {<AlertGeneral />}
      </AuthenticatedTemplate>
      <UnauthenticatedTemplate>
        <MsalAuthenticationTemplate interactionType={InteractionType.Redirect} authenticationRequest={loginRequest} />
        <div className="d-flex justify-content-center align-items-center" style={{ height: '100vh' }}>
          <div className="spinner-border" style={{ width: '3rem', height: '3rem' }} role="status">
            <span className="sr-only">Loading...</span>
          </div>
        </div>
      </UnauthenticatedTemplate>
    </div>
  );
}

export default App;

import React, { createContext, useContext, useState } from 'react';
import { IAlertComponentProps } from '../components/utils/alert-component';
import {AxiosResponse} from "axios";
import {manageAxiosError} from "../services/service";
import {useMsal} from "@azure/msal-react";
import {IMsalContext} from "@azure/msal-react/src/MsalContext";
import {loginRequest} from "../authConfig";

export interface IAppContextProps {
  alert?: IAlertComponentProps;
  setAlert: (alert?: IAlertComponentProps) => void;
  makeApiCall: <T, D>(request: Promise<AxiosResponse<T, D>>, ignoreErrors?: boolean, successAlert?: IAlertComponentProps) => Promise<T | undefined>;
  auth: IMsalContext;
}

export const AppContext = createContext<IAppContextProps | null>(null);

interface IAppContextProviderProps {
  children: React.ReactNode;
}
export const AppContextProvider = (props: IAppContextProviderProps) => {
  const auth = useMsal();
  const [alert, setAlert] = useState<IAlertComponentProps | undefined>();

  const makeApiCall = <T, D>(
    request: Promise<AxiosResponse<T, D>>,
    ignoreErrors?: boolean,
    successAlert?: IAlertComponentProps
  ): Promise<T | undefined> => {
    return request
      .then((response) => {
        setAlert(successAlert);
        return response.data;
      })
      .catch((error) => {
        const err = manageAxiosError(error, ignoreErrors, setAlert);
        if(err === 401) {
          auth.instance
            .acquireTokenSilent({
              ...loginRequest,
              account: auth.accounts[0],
              forceRefresh: true,
            });
        }
        return undefined;
      });
  };


  return <AppContext.Provider value={{ alert, setAlert, makeApiCall, auth }}>{props.children}</AppContext.Provider>;
};

export const useAppContext = () => {
  const currentUserContext = useContext(AppContext);

  if (!currentUserContext) {
    throw new Error('appContext has to be used within <AppContext.Provider>');
  }

  return currentUserContext;
};

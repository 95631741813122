import React, { useEffect, useState } from 'react';
import { Series, Team } from '../../api-client/sport-bo-api';
import {createSportPoolApi, createSportSeriesApi, createSportTeamApi} from '../../services/sport-services';
import { TeamListElementComponent } from '../team/team-list-element-component';
import { ternary } from '../../utils/ternary-util';
import { SpinnerLoader } from '../utils/spinner-loader-component';
import { useAppContext } from '../../contexts/app-context';
import {useEditionContext} from "../../contexts/edition-context";

export interface ISeriesTreeComponentProps {
  series: Series;
  forceLoadChildren?: boolean;
}
export const SeriesTreeComponent = (props: ISeriesTreeComponentProps) => {
  const { makeApiCall, auth } = useAppContext();
  const { updateSeries, updateTeam } = useEditionContext();
  const [loading, setLoading] = useState(false);
  const [childrenLoaded, setChildrenLoaded] = useState(false);
  const [displayChildren, setDisplayChildren] = useState(props.forceLoadChildren);
  const [teamChildren, setTeamChildren] = useState<Team[]>([]);

  useEffect(() => {
    if (props.forceLoadChildren) {
      setDisplayChildren(true);
      loadChildren();
    }
  }, [props.forceLoadChildren]);

  const handleDisplayChildren = async () => {
    setDisplayChildren(!displayChildren);
    updateSeries(undefined);
    updateTeam(undefined);
    await loadChildren();
  };

  const handleEditSeries = async() => {
    updateSeries(await makeApiCall((await createSportSeriesApi(auth)).getSeriesById(props.series.id!)));
  };

  const loadChildren = async () => {
    if (childrenLoaded) {
      return;
    }

    setLoading(true);

    const pool = await makeApiCall(
      (await createSportPoolApi(auth)).getLatestPoolInSeriesById(props.series.id!)
    );

    setTeamChildren(
      (pool ? await makeApiCall((await createSportTeamApi(auth)).getTeamsByPoolId(pool.id!)) : []) ??
        []
    );

    setLoading(false);
    setChildrenLoaded(true);
    setDisplayChildren(true);
  };

  return (
    <li>
      <div className="d-flex">
        <div className={`caret ${ternary(displayChildren, 'down')}`} onClick={handleDisplayChildren}>
          {props.series.name}
        </div>
        <button type="button" className="ms-2 btn btn-link small" onClick={handleEditSeries}>
          Editer
        </button>
      </div>
      {loading && <SpinnerLoader />}
      <ul className={ternary(displayChildren, '', 'd-none')}>
        {teamChildren.map((team: Team) => (
          <TeamListElementComponent key={team.id} team={team} />
        ))}
      </ul>
    </li>
  );
};

import React from 'react';
import { UseFormRegister } from 'react-hook-form/dist/types/form';
import { FieldValues } from 'react-hook-form/dist/types/fields';

export interface IInputComponentProps {
  label?: string;
  name: string;
  register: UseFormRegister<FieldValues>;
  required?: boolean;
}
export const Checkbox = (props: IInputComponentProps) => {
  return (
    <div className="form-check">
      <input
        id={props.name}
        type="checkbox"
        className="form-check-input"
        {...props.register(props.name, { required: props.required })}
      />

      {props.label && (
        <label htmlFor={props.name} className="form-check-label ms-1">
          {props.label}
        </label>
      )}
    </div>
  );
};

import React from 'react';
import { Button, Modal } from 'react-bootstrap';
import { TeamMedia } from '../../api-client/sport-bo-api';
import { useAppContext } from '../../contexts/app-context';
import { createSportTeamMediaApi } from '../../services/sport-services';

export interface ITeamMediaEditionModalComponentProps {
  teamMedia: TeamMedia;
  show: boolean;
  setShow: (show: boolean) => void;
  setReload?: (reload: boolean) => void;
}
export const TeamMediaEditionModalComponent = (props: ITeamMediaEditionModalComponentProps) => {
  const { makeApiCall, auth } = useAppContext();

  const handleClose = () => props.setShow(false);

  const handleValidateTeamMedia = async () => {
    if (window.confirm('Valider le media?')) {

      await makeApiCall((await createSportTeamMediaApi(auth)).validateTeamMedia(props.teamMedia.id!));
      reload();
    }
    handleClose();
  };

  const handleDeleteTeamMedia = async () => {
    if (window.confirm('Supprimer le media?')) {
      await makeApiCall(
        (await createSportTeamMediaApi(auth)).deleteTeamMediaById(props.teamMedia.id!)
      );
      reload();
    }
    handleClose();
  };

  const reload = () => {
    if (props.setReload) {
      props.setReload(true);
    }
  };

  return (
    <Modal show={props.show} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>Media edition</Modal.Title>
      </Modal.Header>

      <Modal.Body>
        <div className="team-media-edit-modal-body">
          <img alt={props.teamMedia?.media?.path} src={props.teamMedia?.media?.url} width="400px" />
        </div>
      </Modal.Body>

      <Modal.Footer>
        <div className="d-flex flex-grow-1">
          {!props.teamMedia.validated && (
            <Button variant="success" className="me-1" onClick={handleValidateTeamMedia}>
              Valider
            </Button>
          )}
          <Button variant="danger" onClick={handleDeleteTeamMedia}>
            Supprimer
          </Button>
        </div>
        <Button variant="secondary" onClick={handleClose}>
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

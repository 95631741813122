import React from 'react';
import { TeamEditionComponent } from '../team/edition/team-edition-component';
import { ternary } from '../../utils/ternary-util';
import {useEditionContext} from "../../contexts/edition-context";
import {SeriesEditionComponent} from "../series/series-edition-component";

export interface IEditionLayoutProps {
  leftMenuClassName?: string;
  children: React.ReactNode;
}
export const EditionLayout = (props: IEditionLayoutProps) => {
  const { team, series } = useEditionContext();

  return (
    <div className="d-flex work-menu">
      <div
        className={`left-menu ${ternary(
          props.leftMenuClassName !== undefined,
          props.leftMenuClassName
        )}`}>
        {props.children}
      </div>
      <div className="flex-grow-1 right-menu">
        {team && <TeamEditionComponent />}
        {series && <SeriesEditionComponent />}
      </div>
    </div>
  );
};

import React, { useEffect, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { ternary } from '../../utils/ternary-util';

export interface INavLinkProps {
  route: string;
  otherActiveRoutes?: string[];
  children?: React.ReactNode;
}
export const NavLink = (props: INavLinkProps) => {
  const location = useLocation();
  const [active, setActive] = useState(false);

  useEffect(() => {
    setActive(
      location.pathname === props.route ||
        (props.otherActiveRoutes !== undefined &&
          props.otherActiveRoutes.find((route) => location.pathname === route) !== undefined)
    );
  }, [location]);

  return (
    <Link to={props.route} className={`nav-link ${ternary(active, 'active')}`}>
      {props.children}
    </Link>
  );
};

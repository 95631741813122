import { useAppContext } from '../../contexts/app-context';
import React, { useEffect, useState } from 'react';
import { PageTeam } from '../../api-client/sport-bo-api';
import { emptyPage, Page } from '../../models/search';
import { createSportTeamApi } from '../../services/sport-services';
import { SearchQuery } from '../search/search-query-component';
import { TeamListElementComponent } from './team-list-element-component';
import { SearchNavbar } from '../search/search-navbar-component';
import { SpinnerLoader } from '../utils/spinner-loader-component';
import {useEditionContext} from "../../contexts/edition-context";
import {EditionLayout} from "../layout/edition-layout";
import {useSearchContext} from "../../contexts/search-content";
import {TeamFilters} from "../../models/team-filters";

export interface ISearchTeamsComponentProps {
  filter?: TeamFilters;
  doASearchAtStart?: boolean;
  emptyMessage?: string;
}
export const SearchTeamsComponent = (props: ISearchTeamsComponentProps) => {
  const { makeApiCall, auth } = useAppContext();
  const { search } = useSearchContext();
  const { reload } = useEditionContext();
  const [loading, setLoading] = useState<boolean>(false);
  const [teams, setTeams] = useState<PageTeam>(emptyPage());

  useEffect(() => {
    if (search.searched || props.doASearchAtStart) {
      doSearch();
    }
  }, [search, reload]);

  const doSearch = async () => {
    setLoading(true);
    setTeams(
      (await makeApiCall(
          (await createSportTeamApi(auth)).searchTeams(props.filter?.poolId, search.query, props.filter?.toProcess, search.pageable.page, search.pageable.size, search.pageable.sort)
      )) ?? emptyPage()
    );
    setLoading(false);
  };

  return (
    <EditionLayout leftMenuClassName="left-menu-search">
      <SearchQuery />
      <div className="p-3 pb-1 search-results">
        {loading ? (
          <SpinnerLoader />
        ) : (
          <>
            {teams.content &&
              teams.content.map((team) => (
                <TeamListElementComponent
                  key={team.id}
                  team={team}
                  displayFullyQualifiedName
                  separator
                />
              ))}
            {props.emptyMessage &&
              (search.searched || props.doASearchAtStart) &&
              (!teams.content || teams.content.length === 0) &&
              props.emptyMessage}
          </>
        )}
      </div>
      <SearchNavbar page={teams as Page} />
    </EditionLayout>
  );
};

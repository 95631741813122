import axios from 'axios';
import { IAlertComponentProps } from '../components/utils/alert-component';

export const manageAxiosError = (
  error: any,
  ignoreErrors?: boolean,
  setAlert?: (alert?: IAlertComponentProps) => void
): string | number => {
  console.error('Error with request: ' + error.message ?? error);

  if (axios.isAxiosError(error)) {
    if (error.response?.status === 401) {
      return 401;
    }
  }

  if (!ignoreErrors && setAlert) {
    setAlert({ header: error.message ?? error, variant: 'danger' });
  }

  return error.message ?? error;
};

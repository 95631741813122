import React from 'react';
import { SearchTeamsComponent } from '../../components/team/search-teams-component';
import {EditionContextProvider} from "../../contexts/edition-context";
import {SearchContextProvider, useSearchContext} from "../../contexts/search-content";
import {NavTabElement} from "../../components/utils/nav-tab-element";
import {SearchSeriesComponent} from "../../components/series/search-series-component";
import {SearchType} from "../../models/search";

export const SearchPage = () => {
  return (
    <EditionContextProvider>
      <SearchContextProvider withParams>
        <SearchPageContent />
      </SearchContextProvider>
    </EditionContextProvider>
  );
};

export const SearchPageContent = () => {
  const { search, setSearch } = useSearchContext();

  const handleViewChange = (type: SearchType) => {
    const viewModified = type !== search.type;
    setSearch({
      ...search,
      type,
      query: viewModified ? '' : search.query,
      searched: viewModified ? false : search.searched
    });
  };

  return (
    <div>
      <ul className="nav nav-tabs">
        <li className="nav-item d-flex align-items-center justify-content-center ps-3 pe-3">
          Rechercher par :
        </li>
        <li className="nav-item">
          <NavTabElement
            view={SearchType.teams}
            currentView={search.type}
            handleClick={handleViewChange}
          >
            Teams
          </NavTabElement>
        </li>
        <li className="nav-item">
          <NavTabElement
            view={SearchType.series}
            currentView={search.type}
            handleClick={handleViewChange}
          >
            Series
          </NavTabElement>
        </li>
      </ul>
      <div>
        {search.type === SearchType.series ? (
          <SearchSeriesComponent />
        ) : (
          <SearchTeamsComponent emptyMessage="Aucun résultat." />
        )}
      </div>
    </div>
  );
};

export default SearchPage;

import React, { ChangeEvent, useState } from 'react';
import { Match } from '../../../api-client/sport-bo-api';
import { matchDayList, matchStatusList, matchTypeList } from '../../../models/match-enum';
import { SelectionOptions } from '../../forms/select-options-component';
import { isObjectUpdated, updateObject } from '../../../hooks/update-object';
import { useEditMatchesContext } from '../../../contexts/edit-matches-context';
import { getCellId } from '../../../utils/matches-table-utils';
import { TeamMatchEditionCell } from './team-match-edition-cell-component';

export interface ITeamMatchEditionLineProps {
  match: Match;
}
export const TeamMatchEditionLine = (props: ITeamMatchEditionLineProps) => {
  const { updateEditedMatchMap, editedCellMap, updateEditedCellMap } = useEditMatchesContext();
  const [updatedMatch, setUpdatedMatch] = useState(props.match);

  const handleCellUpdate = (
    event: ChangeEvent<HTMLInputElement> | ChangeEvent<HTMLSelectElement>
  ) => {
    const { name, value } = event.target;
    const edited = isObjectUpdated(Object.entries(props.match), name, value);
    const match = updateObject(updatedMatch, Object.entries(updatedMatch), name, value);

    setUpdatedMatch(match);

    updateEditedCellMap.set(getCellId(props.match, name), edited);

    if (edited || Array.from(editedCellMap.values()).includes(true)) {
      updateEditedMatchMap.set(props.match.id!, match);
    } else {
      updateEditedMatchMap.remove(props.match.id!);
    }
  };

  return (
    <tr>
      <TeamMatchEditionCell match={props.match} name="weekNumber" value={updatedMatch.weekNumber}>
        <input
          type="number"
          name="weekNumber"
          style={{ width: '60px' }}
          value={updatedMatch.weekNumber ?? ''}
          onChange={handleCellUpdate}
        />
      </TeamMatchEditionCell>

      <TeamMatchEditionCell match={props.match} name="day" value={updatedMatch.day}>
        <select name="day" value={updatedMatch.day ?? ''} onChange={handleCellUpdate}>
          <SelectionOptions options={matchDayList} defaultOption=" " />
        </select>
      </TeamMatchEditionCell>

      <TeamMatchEditionCell match={props.match} name="date" value={updatedMatch.date}>
        <input
          type="date"
          name="date"
          style={{ width: '140px' }}
          value={updatedMatch.date ?? ''}
          onChange={handleCellUpdate}
        />
      </TeamMatchEditionCell>

      <td>{updatedMatch.homeTeam?.name}</td>

      <TeamMatchEditionCell
        match={props.match}
        name="result.homeScore"
        value={updatedMatch.result?.homeScore}>
        <input
          type="number"
          name="result.homeScore"
          style={{ width: '60px' }}
          value={updatedMatch.result?.homeScore ?? ''}
          onChange={handleCellUpdate}
        />
      </TeamMatchEditionCell>

      <TeamMatchEditionCell
        match={props.match}
        name="result.awayScore"
        value={updatedMatch.result?.awayScore}>
        <input
          type="number"
          name="result.awayScore"
          style={{ width: '60px' }}
          value={updatedMatch.result?.awayScore ?? ''}
          onChange={handleCellUpdate}
        />
      </TeamMatchEditionCell>

      <td>{updatedMatch.awayTeam?.name}</td>

      <TeamMatchEditionCell match={props.match} name="type" value={updatedMatch.type}>
        <select name="type" value={updatedMatch.type ?? ''} onChange={handleCellUpdate}>
          <SelectionOptions options={matchTypeList} defaultOption=" " />
        </select>
      </TeamMatchEditionCell>

      <TeamMatchEditionCell match={props.match} name="status" value={updatedMatch.status}>
        <select name="status" value={updatedMatch.status ?? ''} onChange={handleCellUpdate}>
          <SelectionOptions options={matchStatusList} defaultOption=" " />
        </select>
      </TeamMatchEditionCell>

      <TeamMatchEditionCell match={props.match} name="referee" value={updatedMatch.referee}>
        <input
          type="text"
          name="referee"
          style={{ width: '120px' }}
          value={updatedMatch.referee ?? ''}
          onChange={handleCellUpdate}
        />
      </TeamMatchEditionCell>

      {/*<TeamMatchEditionCell match={props.match} name="cesModerationType" editedCallMap={editedCellMap}>*/}
      {/*  <select*/}
      {/*    name="cesModerationType"*/}
      {/*    value={updatedMatch.cesModerationType ?? ''}*/}
      {/*    onChange={handleCellUpdate}*/}
      {/*  >*/}
      {/*    <SelectionOptions options={cesModerationTypeList} defaultOption=" " />*/}
      {/*  </select>*/}
      {/*</TeamMatchEditionCell>*/}
    </tr>
  );
};

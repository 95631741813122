import React from 'react';
import { Series } from '../../api-client/sport-bo-api';
import { createSportSeriesApi } from '../../services/sport-services';
import { useAppContext } from '../../contexts/app-context';
import {useEditionContext} from "../../contexts/edition-context";

export interface ISeriesListElementComponentProps {
  series: Series;
  displayFullyQualifiedName?: boolean;
  separator?: boolean;
}
export const SeriesListElementComponent = (props: ISeriesListElementComponentProps) => {
  const { makeApiCall, auth } = useAppContext();
  const { updateSeries } = useEditionContext();

  const handleSeriesSelection = async () => {
    updateSeries(await makeApiCall((await createSportSeriesApi(auth)).getSeriesById(props.series.id!)));
  };

  return (
    <div>
      <button className="btn btn-link" onClick={handleSeriesSelection}>
        {props.displayFullyQualifiedName && props.series.fullyQualifiedName ? props.series.fullyQualifiedName : props.series.name}
      </button>
      {props.separator && <hr className="search-separator" />}
    </div>
  );
};

import React, { useEffect, useState } from 'react';
import Accordion from 'react-bootstrap/Accordion';
import { useForm } from 'react-hook-form';
import { Input } from '../../forms/input-component';
import { Select } from '../../forms/select-component';
import { Checkbox } from '../../forms/checkbox-component';
import { Textarea } from '../../forms/textarea-component';
import {
  createSportTeamApi,
  createSportTeamUpdateProposalApi
} from '../../../services/sport-services';
import { Team, TeamUpdateProposal } from '../../../api-client/sport-bo-api';
import { useAppContext } from '../../../contexts/app-context';
import { TeamGeneralEditionFieldProposal } from './team-general-edition-field-proposal-component';
import {useEditionContext} from "../../../contexts/edition-context";
import {cesModerationTypeList} from "../../../models/teams-enum";
import MapComponent from "../../utils/map-component";
import {MapUtils} from "../../../utils/map-utils";
import {LatLngLiteral} from "leaflet";

export const TeamGeneralEditionComponent = () => {
  const { setAlert, makeApiCall, auth } = useAppContext();
  const { team, updateTeam, setReload } = useEditionContext();
  const [ mapZoom, setMapZoom ] = useState(10);
  const [updateProposals, setUpdateProposals] = useState<TeamUpdateProposal[]>([]);
  const {
    register,
    watch,
    getValues,
    setValue,
    handleSubmit,
    reset,
    formState: { errors }
  } = useForm();
  const watchMapCoordinates = watch('mapCoordinates');

  useEffect(() => {
    reset(team);
    setMapZoom(team.mapZoomLevel == null ? undefined : team.mapZoomLevel);
    loadUpdateProposals();
  }, [team]);

  const loadUpdateProposals = async () => {
    if (team) {
      setUpdateProposals(
        (await makeApiCall(
            (await createSportTeamUpdateProposalApi(auth)).getTeamUpdatesByTeamId(team.id!),
          true
        )) ?? []
      );
    }
  };

  const onSubmit = async (updatedTeam: Team) => {
    if (updatedTeam && updatedTeam.id) {

        if(updatedTeam.mapZoomLevel && !updatedTeam.mapCoordinates) {
          updatedTeam = { ...updatedTeam, mapZoomLevel: undefined };
        }

        const result = await makeApiCall(
          (await createSportTeamApi(auth)).updateTeam(updatedTeam.id, updatedTeam),
          false,
          { header: 'Equipe sauvegardée avec succès !', variant: 'success' });

        if(result) {
          updateTeam(result);

          if(updateProposals.length > 0) {
            setTimeout(async () => {
              await handleIgnoreTeamUpdateProposals();
            }, 500);
          }
        }
    }
  };

  const handleFormCancel = () => {
    reset(team);
    setAlert({
      header: 'Modifications annulées.',
      variant: 'secondary'
    });
  };

  const handleIgnoreTeamUpdateProposals = async () => {
    if (team) {
      setUpdateProposals([]);
      await makeApiCall(
        (await createSportTeamUpdateProposalApi(auth)).setTeamUpdateAsProcessedForAllOfTeam(team.id!)
      );
      setTimeout(() => setReload(true), 100);
    }
  };

  const updateMapCoordinates = (coordinates: LatLngLiteral) => {
    setValue('mapCoordinates', MapUtils.latLngToString(coordinates));
    setMapZoom(getValues('mapZoomLevel'));
  }

  const updateMapZoomLevel = (zoom: number) => {
    setValue('mapZoomLevel', zoom);
  }

  return (
    <form>
      <Accordion defaultActiveKey={['0', '1', '2', '3']} alwaysOpen>
        <Accordion.Item eventKey="0">
          <Accordion.Header>Gestion</Accordion.Header>
          <Accordion.Body>
            <div className="d-flex flex-row justify-content-around mb-3">
              <button type="button" className="btn btn-primary" onClick={handleSubmit(onSubmit)}>
                Sauvegarder
              </button>
              <button type="button" className="btn btn-secondary" onClick={handleFormCancel}>
                Annuler les modifications
              </button>
              {updateProposals.length > 0 && (
                <button
                  type="button"
                  className="btn btn-secondary"
                  onClick={handleIgnoreTeamUpdateProposals}
                >
                  Ignorer les propositions
                </button>
              )}
            </div>
            <Checkbox
              label="Accepter les propositions d'utilisateurs pour cette équipe"
              name="acceptUpdateProposals"
              register={register}
            />
          </Accordion.Body>
        </Accordion.Item>
        <Accordion.Item eventKey="1">
          <Accordion.Header>Infos générales</Accordion.Header>
          <Accordion.Body>
            <Input label="Nom" name="name" register={register} required />
            <TeamGeneralEditionFieldProposal
              name="name"
              team={team}
              proposals={updateProposals}
              setValue={setValue}
            />
            <Input label="Diminutif" name="nickname" register={register} />
            <TeamGeneralEditionFieldProposal
              name="nickname"
              team={team}
              proposals={updateProposals}
              setValue={setValue}
            />
            <Input
              label="Date de fondation"
              name="foundationDate"
              register={register}
              type="date"
            />
            <TeamGeneralEditionFieldProposal
              name="foundationDate"
              team={team}
              proposals={updateProposals}
              setValue={setValue}
            />
            <Textarea label="Adresse du terrain" name="address" register={register} />
            <TeamGeneralEditionFieldProposal
              name="address"
              team={team}
              proposals={updateProposals}
              setValue={setValue}
            />
            <Textarea label="Adresse du club" name="headquarters" register={register} />
            <TeamGeneralEditionFieldProposal
              name="headquarters"
              team={team}
              proposals={updateProposals}
              setValue={setValue}
            />
            <Input label="Site web" name="websiteUrl" register={register} />
            <TeamGeneralEditionFieldProposal
              name="websiteUrl"
              team={team}
              proposals={updateProposals}
              setValue={setValue}
            />
            <Input label="Blog" name="blogUrl" register={register} />
            <TeamGeneralEditionFieldProposal
              name="blogUrl"
              team={team}
              proposals={updateProposals}
              setValue={setValue}
            />
            <Input label="Téléphone" name="phone" register={register} />
            <TeamGeneralEditionFieldProposal
              name="phone"
              team={team}
              proposals={updateProposals}
              setValue={setValue}
            />
            <Input label="E-mail" name="email" register={register} />
            <TeamGeneralEditionFieldProposal
              name="email"
              team={team}
              proposals={updateProposals}
              setValue={setValue}
            />
          </Accordion.Body>
        </Accordion.Item>
        <Accordion.Item eventKey="2">
          <Accordion.Header>Commentaires</Accordion.Header>
          <Accordion.Body>
            <Checkbox label="Visibles" name="cesCommentsAllowed" register={register} />
            <Checkbox label="Permettre l'ajout" name="cesCommentsDisplayed" register={register} />
            <Select
              name="cesModerationType"
              register={register}
              options={cesModerationTypeList}
              width="200px"
            />
          </Accordion.Body>
        </Accordion.Item>
        <Accordion.Item eventKey="3">
          <Accordion.Header>Localisation</Accordion.Header>
          <Accordion.Body>
            <Input label="Coordonées" name="mapCoordinates" register={register} />
            <Input label="Zoom" name="mapZoomLevel" register={register} />
            <MapComponent
              coordinates={MapUtils.stringToLatLngLiteral(watchMapCoordinates)}
              zoom={mapZoom}
              updateCoordinates={updateMapCoordinates}
              updateZoom={updateMapZoomLevel}
            />
          </Accordion.Body>
        </Accordion.Item>
      </Accordion>
    </form>
  );
};

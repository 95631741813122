import React, { useEffect, useState } from 'react';
import { createSportGroupApi } from '../../services/sport-services';
import { Group } from '../../api-client/sport-bo-api';
import { GroupTreeComponent } from '../../components/group/group-tree-component';
import { manageAxiosError } from '../../services/service';
import {useAppContext} from "../../contexts/app-context";
import {EditionContextProvider, useEditionContext} from "../../contexts/edition-context";
import {EditionLayout} from "../../components/layout/edition-layout";
import {SpinnerLoader} from "../../components/utils/spinner-loader-component";

const BrowsePage = () => {
  return (
    <EditionContextProvider>
      <BrowsePageContent />
    </EditionContextProvider>
  );
};

const BrowsePageContent = () => {
  const { auth } = useAppContext();
  const { reload } = useEditionContext();
  const [loading, setLoading] = useState<boolean>(false);
  const [groups, setGroups] = useState<Group[]>([]);

  useEffect(() => {
    setLoading(true);

    createSportGroupApi(auth)
      .then((api) => api.getTopLevelGroups()
        .then((response) => {
          setGroups(response.data);
        })
        .catch((error) => manageAxiosError(error))
        .finally(() => setLoading(false)));
  }, [reload]);

  return (
    <EditionLayout>
      {loading ? (
        <SpinnerLoader />
      ) : (
        <ul className="folder-tree me-3">
          {groups &&
            groups.map((group: Group) => <GroupTreeComponent key={group.id} group={group} />)}
        </ul>
      )}
    </EditionLayout>
  );
};

export default BrowsePage;

import React, {useEffect, useState} from 'react';
import { useSearchParams } from 'react-router-dom';
import {Input} from "../forms/input-component";
import {Series} from "../../api-client/sport-bo-api";
import {createSportSeriesApi} from "../../services/sport-services";
import {useEditionContext} from "../../contexts/edition-context";
import {useForm} from "react-hook-form";
import {useAppContext} from "../../contexts/app-context";
import {EditParams} from "../../models/edition-enums";
import {FormHtmlEditor} from "../forms/form-html-editor-component";

export const SeriesEditionComponent = () => {
  const { setAlert, makeApiCall, auth } = useAppContext();
  const [searchParams, setSearchParams] = useSearchParams();
  const { series, updateSeries, setReload } = useEditionContext();
  const [ description, setDescription ] = useState<string>();
  const {
    register,
    handleSubmit,
    reset,
    setValue,
    formState: { errors }
  } = useForm();

  useEffect(() => {
    if (series && series.id) {
      reset(series);
      setDescription(series.description   ?? '')
      const seriesId = series.id.toString();
      setSearchParams((params) => {
        params.set(EditParams.seriesId, seriesId);
        return params;
      });
    }
  }, [series]);

  const onSubmit = async (updatedSeries: Series) => {
    if (updatedSeries && updatedSeries.id) {
      updatedSeries.name = series.name;

      updateSeries(
        await makeApiCall(
          (await createSportSeriesApi(auth)).updateSeries(updatedSeries.id, updatedSeries),
          false,
          { header: 'Series sauvegardée avec succès !', variant: 'success' })
      );
    }
  };

  const handleFormCancel = () => {
    reset(series);
    setAlert({
      header: 'Modifications annulées.',
      variant: 'secondary'
    });
  };

  return (
    <div>
      {series && <div className="p-3">Edition de {series.fullyQualifiedName ?? series.name}</div>}
      <form>
        <div className="d-flex flex-row justify-content-around mb-3">
          <button type="button" className="btn btn-primary" onClick={handleSubmit(onSubmit)}>
            Sauvegarder
          </button>
          <button type="button" className="btn btn-secondary" onClick={handleFormCancel}>
            Annuler les modifications
          </button>
        </div>

        <fieldset className="mt-4 m-3">
          <Input label="Section dhnet" name="dhnetSectionId" register={register} />
          <Input label="Section lavenir" name="lavenirSectionId" register={register} />
          <Input label="Section lalibre" name="lalibreSectionId" register={register} />
          <FormHtmlEditor label="Description" name="description" setValue={setValue} initialValue={description} />
        </fieldset>
      </form>
    </div>
  );
};

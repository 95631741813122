import React from 'react';
import { UseFormRegister } from 'react-hook-form/dist/types/form';
import { FieldValues } from 'react-hook-form/dist/types/fields';
import { SelectionOptions } from './select-options-component';

export interface ISelectOption {
  id: string;
  name: string;
}
export interface ISelectComponentProps {
  label?: string;
  name: string;
  register: UseFormRegister<FieldValues>;
  required?: boolean;
  width?: string;
  options?: ISelectOption[];
  defaultOption?: string;
  children?: React.ReactNode;
}
export const Select = (props: ISelectComponentProps) => {
  return (
    <div className="mb-3 d-flex flex-row">
      {props.label && (
        <label htmlFor={props.name} className="col-form-label">
          {props.label}
        </label>
      )}

      <select
        id={props.name}
        className="form-select"
        style={{ width: props.width }}
        {...props.register(props.name, { required: props.required })}>
        {props.children ? (
          props.children
        ) : (
          <>
            {props.options && (
              <SelectionOptions options={props.options} defaultOption={props.defaultOption} />
            )}
          </>
        )}
      </select>
    </div>
  );
};

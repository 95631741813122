import { useAppContext } from '../../contexts/app-context';
import React, { useEffect, useState } from 'react';
import {emptyPage, Page} from '../../models/search';
import {createSportSeriesApi} from '../../services/sport-services';
import { SearchQuery } from '../search/search-query-component';
import { SearchNavbar } from '../search/search-navbar-component';
import { SpinnerLoader } from '../utils/spinner-loader-component';
import {EditionLayout} from "../layout/edition-layout";
import {PageSeries} from "../../api-client/sport-bo-api";
import {SeriesListElementComponent} from "./series-list-element-component";
import {useEditionContext} from "../../contexts/edition-context";
import {useSearchContext} from "../../contexts/search-content";
import {checkUserIsAuthorizedAdmin} from "../../models/aadEnums";
import {useMsal} from "@azure/msal-react";

export const SearchSeriesComponent = () => {
  const { makeApiCall, auth } = useAppContext();
  const { accounts } = useMsal();
  const { reload } = useEditionContext();
  const { search } = useSearchContext();
  const [loading, setLoading] = useState<boolean>(false);
  const [series, setSeries] = useState<PageSeries>(emptyPage());

  useEffect(() => {
    if (search.searched) {
      doSearch();
    }
  }, [search, reload]);

  const doSearch = async () => {
    setLoading(true);
    setSeries(
      (await makeApiCall(
          (await createSportSeriesApi(auth)).searchSeries(search.query, search.pageable.page, search.pageable.size, search.pageable.sort)
      )) ?? emptyPage()
    );
    setLoading(false);
  };

  const feedFullyQualifiedNames = async () => {
    await makeApiCall((await createSportSeriesApi(auth)).feedFullyQualifiedNames());
  }


  return (
    <EditionLayout leftMenuClassName="left-menu-search">
      {checkUserIsAuthorizedAdmin(accounts) && (
        <button type="button" className="btn btn-primary m-3" onClick={feedFullyQualifiedNames}>Générer la recherche des séries</button>
      )}

      <SearchQuery />
      <div className="p-3 search-results">
        {loading ? (
          <SpinnerLoader />
        ) : (
          <>
            {series.content &&
              series.content.map((seriesElement) => (
                <SeriesListElementComponent
                  key={seriesElement.id}
                  series={seriesElement}
                  displayFullyQualifiedName
                  separator
                />
              ))}
            {(search.searched && (!series.content || series.content.length === 0)) && (
              <>Aucune série trouvée.</>
            )}
          </>
        )}
      </div>
      <SearchNavbar page={series as Page} />
    </EditionLayout>
  );
};

import React, { createContext, useContext, useState } from 'react';
import { Match } from '../api-client/sport-bo-api';
import { Actions, useMap } from '../hooks/use-map-hook';

export interface IEditMatchesContextProps {
  lastFocusedCellId: string | undefined;
  setLastFocusedCellId: (cellId: string | undefined) => void;
  editedMatchMap: Map<number, Match>;
  updateEditedMatchMap: Actions<number, Match>;
  focusedCellMap: Map<string, boolean>;
  updateFocusedCellMap: Actions<string, boolean>;
  editedCellMap: Map<string, boolean>;
  updateEditedCellMap: Actions<string, boolean>;
}

export const EditMatchesContext = createContext<IEditMatchesContextProps | null>(null);

interface IEditMatchesContextProviderProps {
  children: React.ReactNode;
}
export const EditMatchesContextProvider = (props: IEditMatchesContextProviderProps) => {
  const [lastFocusedCellId, setLastFocusedCellId] = useState<string>();
  const [editedMatchMap, updateEditedMatchMap] = useMap<number, Match>(new Map());
  const [focusedCellMap, updateFocusedCellMap] = useMap<string, boolean>(new Map());
  const [editedCellMap, updateEditedCellMap] = useMap<string, boolean>(new Map());

  return (
    <EditMatchesContext.Provider
      value={{
        lastFocusedCellId,
        setLastFocusedCellId,
        editedMatchMap,
        updateEditedMatchMap,
        focusedCellMap,
        updateFocusedCellMap,
        editedCellMap,
        updateEditedCellMap
      }}>
      {props.children}
    </EditMatchesContext.Provider>
  );
};

export const useEditMatchesContext = () => {
  const context = useContext(EditMatchesContext);

  if (!context) {
    throw new Error('useEditMatchesContext has to be used within <EditMatchesContext.Provider>');
  }

  return context;
};

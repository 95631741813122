import React from 'react';

import { Editor } from '@tinymce/tinymce-react';

// Import TinyMCE
import 'tinymce/tinymce';

// Default icons are required for TinyMCE 5.3 or above
import 'tinymce/icons/default';

// Import skin
import 'tinymce/skins/ui/oxide/skin.min.css';
import 'tinymce/skins/ui/oxide/content.min.css';

// A theme is also required
import 'tinymce/themes/silver';

// Any plugins you want to use has to be imported
import 'tinymce/plugins/preview';
import 'tinymce/plugins/directionality';
import 'tinymce/plugins/paste';
import 'tinymce/plugins/autolink';
import 'tinymce/plugins/autosave';
import 'tinymce/plugins/code';
import 'tinymce/plugins/fullscreen';
import 'tinymce/plugins/image';
import 'tinymce/plugins/link';
import 'tinymce/plugins/media';
import 'tinymce/plugins/table';
import 'tinymce/plugins/hr';
import 'tinymce/plugins/advlist';
import 'tinymce/plugins/lists';
import 'tinymce/plugins/wordcount';
import 'tinymce/plugins/imagetools';
import 'tinymce/plugins/help';
import 'tinymce/plugins/quickbars';
import {Variant} from "react-bootstrap/types";
import {Editor as TinyMCEEditor} from "tinymce";

export interface IHtmlEditorProps {
  initialValue: string;
  handleEditorChange: (value: string) => void;
}
const HtmlEditor = ({ initialValue, handleEditorChange }: IHtmlEditorProps) => {
  return (
    <Editor
      initialValue={initialValue}
      init={{
        // @ts-ignore
        selector: 'textarea#full-featured-non-premium',
        height: 300,
        plugins:
          'preview directionality paste autolink code link media hr advlist lists wordcount help quickbars',
        imagetools_cors_hosts: [
          'ipmgroup-dhnet-sandbox.cdn.arcpublishing.com',
          'ipmgroup-lalibre-sandbox.cdn.arcpublishing.com',
        ],
        extended_valid_elements: 'script',
        fontsize_formats: '8pt 9pt 10pt 11pt 12pt 13pt 14pt 15pt 18pt 24pt 36pt',
        menubar: 'edit view insert format tools table help',
        toolbar:
          'undo redo | bold italic underline strikethrough | fontselect fontsizeselect formatselect | alignleft aligncenter alignright alignjustify | outdent indent | numlist bullist | forecolor backcolor removeformat | pagebreak | charmap emoticons | ltr rtl',
        toolbar_sticky: true,
        quickbars_selection_toolbar: 'bold italic | quicklink h2 h3 blockquote',
        image_advtab: true,
        image_caption: true,
        noneditable_noneditable_class: 'mceNonEditable',
        toolbar_mode: 'sliding',
        contextmenu: 'link',
        skin: false,
        content_css: false,
        content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }',
      }}
      onEditorChange={handleEditorChange}
    />
  );
};

export default HtmlEditor;

/**
 * Return the first string argument (str1) if the condition is true.
 * Otherwise, return the second string argument (str2).
 * If the string argument to return is undefined, return an empty string.
 *
 * @param condition the ternary condition
 * @param str1 the string to return if condition is true
 * @param str2 the string to return if condition is false
 */
export const ternary = (
  condition: boolean | undefined,
  str1: string | undefined,
  str2: string | undefined = ''
): string => {
  return (condition ? str1 : str2) ?? '';
};

import React, {createContext, useContext, useEffect, useState} from 'react';
import {defaultSearch, Search, SearchType} from '../models/search';
import {SearchParams} from "../models/search-enums";
import {useSearchParams} from "react-router-dom";

export interface ISearchContextProps {
  search: Search;
  setSearch: (search: Search) => void;
}

export const SearchContext = createContext<ISearchContextProps | null>(null);

interface ISearchContextProviderProps {
  withParams?: boolean;
  children: React.ReactNode;
}
export const SearchContextProvider = (props: ISearchContextProviderProps) => {
  const [search, setSearch] = useState<Search>(defaultSearch());
  const [paramsInit, setParamsInit] = useState<boolean>(false);
  const [searchParams, setSearchParams] = useSearchParams();

  useEffect(() => {
    if(props.withParams) {
      const newSearch = { ...search };
      const query = searchParams.get(SearchParams.query);
      const page = searchParams.get(SearchParams.page);
      const size = searchParams.get(SearchParams.size);
      const type = searchParams.get(SearchParams.type);

      if (query != null) {
        newSearch.query = query;
      }
      if (page && !isNaN(parseInt(page))) {
        newSearch.pageable.page = parseInt(page) + 1;
      }
      if (size && !isNaN(parseInt(size))) {
        newSearch.pageable.size = parseInt(size);
      }
      if (type && SearchType[type]) {
        newSearch.type = SearchType[type];
      }
      if (search.query != null || (search.pageable.page && search.pageable.page > 1)) {
        newSearch.searched = true;
      }

      setSearch(newSearch);
      setParamsInit(true);
    }
  }, []);

  useEffect(() => {
    if(props.withParams && paramsInit) {
      setSearchParams((params) => {
        updateParam(
          params,
          SearchParams.query,
          search.query.trim()
        );
        updateParam(
          params,
          SearchParams.page,
          search.pageable.page == defaultSearch().pageable.page
            ? undefined
            : (search.pageable.page + 1).toString()
        );
        updateParam(
          params,
          SearchParams.size,
          search.pageable.size == defaultSearch().pageable.size
            ? undefined
            : search.pageable.size?.toString()
        );
        updateParam(
          params,
          SearchParams.type,
          search.type
        );
        return params;
      });
    }
  }, [search]);

  const updateParam = (params: URLSearchParams, name: string, value: string | undefined) => {
    if (value) {
      params.set(name, value);
    } else {
      params.delete(name);
    }
  };

  return (
    <SearchContext.Provider value={{ search, setSearch }}>{props.children}</SearchContext.Provider>
  );
};

export const useSearchContext = () => {
  const context = useContext(SearchContext);

  if (!context) {
    throw new Error('useSearchContext has to be used within <SearchContext.Provider>');
  }

  return context;
};

import React from 'react';
import { UseFormRegister } from 'react-hook-form/dist/types/form';
import { FieldValues } from 'react-hook-form/dist/types/fields';

export interface ITextareaProps {
  label?: string;
  name: string;
  register: UseFormRegister<FieldValues>;
  required?: boolean;
}
export const Textarea = (props: ITextareaProps) => {
  return (
    <div className="mb-3 d-flex flex-row">
      {props.label && (
        <label htmlFor={props.name} className="col-form-label">
          {props.label}
        </label>
      )}

      <textarea
        id={props.name}
        className="form-control"
        {...props.register(props.name, { required: props.required })}
      />
    </div>
  );
};

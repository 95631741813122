import React from 'react';
import {ternary} from "../../utils/ternary-util";

interface INavTabElementProps {
  view: any;
  currentView: any;
  handleClick: (newView: any) => void;
  children: React.ReactNode;
}
export const NavTabElement = (props: INavTabElementProps) => {
  const active = props.currentView === props.view;

  return (
    <a
      className={`nav-link ${ternary(active, 'active')}`}
      href="#"
      onClick={() => props.handleClick(props.view)}
    >
      {props.children}
    </a>
  );
};

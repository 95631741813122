import { useAppContext } from '../contexts/app-context';
import { useSearchParams } from 'react-router-dom';
import { useEffect, useState } from 'react';
import {Team} from '../api-client/sport-bo-api';
import { createSportTeamApi } from '../services/sport-services';
import {EditParams, EditTeamView} from "../models/edition-enums";

export interface IUseEditTeamProps {
  reload: boolean;
}
export const useEditTeam = ({ reload }: IUseEditTeamProps) => {
  const { makeApiCall, auth } = useAppContext();
  const [searchParams] = useSearchParams();
  const [team, setTeam] = useState<Team | undefined>();
  const [view, setView] = useState(EditTeamView.general);

  useEffect(() => {
    if (
      searchParams.has(EditParams.teamId) &&
      !isNaN(parseInt(searchParams.get(EditParams.teamId)!))
    ) {
      loadTeam(parseInt(searchParams.get(EditParams.teamId)!));
    }

    if (searchParams.has(EditParams.view) && (searchParams.get(EditParams.view) as EditTeamView)) {
      setView(searchParams.get(EditParams.view) as EditTeamView);
    }
  }, [reload]);

  const loadTeam = async (teamId: number) => {
    if (!team || team.id !== teamId) {
      setTeam(await makeApiCall((await createSportTeamApi(auth)).getTeamsById(teamId)));
    }
  };

  const updateTeam = (team?: Team) => {
    if(team && team.id) {
      searchParams.set(EditParams.teamId, String(team.id));
    } else {
      searchParams.delete(EditParams.teamId);
    }
    setTeam(team);
  }

  return { team, updateTeam, view, setView };
};

export const updateObject = <T>(
  toUpdate: T,
  entries: [string, any][],
  name: string,
  value: string
): T => {
  if (name.includes('.')) {
    const split = name.split('.');
    const key1 = split[0];
    const key2 = split[1];
    const matchMap = new Map<string, any>();
    entries.map((entry) => matchMap.set(entry[0], entry[1]));
    return { ...toUpdate, [key1]: { ...matchMap.get(key1), [key2]: value } };
  } else {
    return { ...toUpdate, [name]: value };
  }
};

export const isObjectUpdated = <T>(
  entries: [string, any][],
  name: string,
  value: string
): boolean => {
  const matchMap = new Map<string, any>();
  entries.map((entry) => matchMap.set(entry[0], entry[1]));

  if (name.includes('.')) {
    const split = name.split('.');
    const key2 = split[1];
    const subMap = new Map<string, any>();
    return subMap.get(key2) != value;
  } else {
    return matchMap.get(name) != value;
  }
};

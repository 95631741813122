import React, { ChangeEvent } from 'react';
import { Page } from '../../models/search';
import { ternary } from '../../utils/ternary-util';
import { pageSizeList } from '../../models/search-enums';
import { useSearchContext } from '../../contexts/search-content';
import {Pageable} from "../../models/pageable";

export interface ISearchNavbarProps {
  page: Page;
  withoutPagination?: boolean;
  onlyPagination?: boolean;
  simplePagination?: boolean;
  withoutPageIndicator?: boolean;
  withoutPageSizeList?: boolean;
  disabled?: boolean;
}

export const SearchNavbar = (props: ISearchNavbarProps) => {
  const { search, setSearch } = useSearchContext();
  const { pageable } = search;
  const { page, size } = pageable;
  const { content, totalPages } = props.page;

  const gotoPage = (pageNumber?: number) => {
    updateSearchPageable({ ...pageable, page: pageNumber });
  };

  const previousPage = () => {
    if (page) {
      updateSearchPageable({ ...pageable, page: page - 1 });
    }
  };

  const nextPage = () => {
    if (page !== undefined) {
      updateSearchPageable({ ...pageable, page: page + 1 });
    }
  };

  const onChangePageSize = (event: ChangeEvent<HTMLSelectElement>) => {
    updateSearchPageable({
      ...pageable,
      page: 1,
      size: Number.isNaN(event.target.value) ? pageSizeList[0] : Number(event.target.value)
    });
  };

  const updateSearchPageable = (pageable: Pageable) => {
    setSearch({ ...search, pageable: pageable });
  };

  const onGoToPage = (event: ChangeEvent<HTMLInputElement>) => {
    const value = parseInt(event.target.value) - 1;

    if (isNaN(value) || value <= 0 || !totalPages) {
      gotoPage(0);
      return;
    } else if (value >= totalPages) {
      gotoPage(totalPages - 1);
      return;
    }

    gotoPage(value);
  };

  return (
    <>
      {content && totalPages !== undefined && totalPages > 1 && (
        <div className="d-flex">
          {(!props.withoutPagination || props.onlyPagination) && (
            <div className="pagination align-self-center p-3 pt-0 pb-0">
              {!props.simplePagination && (
                <div className={`page-item ${ternary(page === 0, 'disabled')}`}>
                  <button
                    type="button"
                    className="page-link"
                    disabled={props.disabled}
                    onClick={() => gotoPage(0)}>
                    <span>&laquo;</span>
                  </button>
                </div>
              )}
              <div className={`page-item ${ternary(page === 0, 'disabled')}`}>
                <button
                  type="button"
                  className="page-link"
                  disabled={props.disabled}
                  onClick={previousPage}>
                  <span>&lsaquo;</span>
                </button>
              </div>
              <div className={`page-item ${ternary(page === totalPages - 1, 'disabled')}`}>
                <button
                  type="button"
                  className="page-link"
                  disabled={props.disabled}
                  onClick={nextPage}>
                  <span>&rsaquo;</span>
                </button>
              </div>
              {!props.simplePagination && (
                <div className={`page-item ${ternary(page === totalPages - 1, 'disabled')}`}>
                  <button
                    type="button"
                    className="page-link"
                    disabled={props.disabled}
                    onClick={() => gotoPage(totalPages - 1)}>
                    <span>&raquo;</span>
                  </button>
                </div>
              )}
            </div>
          )}
          {!props.withoutPageIndicator && !props.onlyPagination && (
            <div className="d-flex align-items-center p-3">
              <span className="pe-1">Page</span>
              <input
                type="number"
                className="form-control search-page-indicator-input"
                value={page + 1}
                min="1"
                max={totalPages}
                onChange={onGoToPage}
                disabled={props.disabled}
              />
              <strong className="ps-1">sur {totalPages}</strong>
            </div>
          )}
          {!props.withoutPageSizeList && !props.onlyPagination && (
            <div className="align-self-center p-3">
              <select
                className="custom-select"
                value={size}
                onChange={onChangePageSize}
                disabled={props.disabled}>
                {pageSizeList &&
                  pageSizeList.map((pageOptionSize) => (
                    <option key={pageOptionSize} value={pageOptionSize}>
                      Voir {pageOptionSize}
                    </option>
                  ))}
              </select>
            </div>
          )}
        </div>
      )}
    </>
  );
};

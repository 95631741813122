import React from 'react';
import { SearchTeamsComponent } from '../../components/team/search-teams-component';
import {EditionContextProvider} from "../../contexts/edition-context";
import {SearchContextProvider} from "../../contexts/search-content";

export const TeamsToTreatPage = () => {
  return (
    <EditionContextProvider>
      <SearchContextProvider>
        <TeamsToTreatPageContent />
      </SearchContextProvider>
    </EditionContextProvider>
  );
};

const TeamsToTreatPageContent = () => {
  return (
    <SearchTeamsComponent
      filter={{ toProcess: true }}
      emptyMessage="Pas d'équipe à traiter."
      doASearchAtStart
    />
  );
};
export default TeamsToTreatPage;

export enum SearchParams {
  query = 'q',
  page = 'page',
  size = 'size',
  sort = 'sort',
  type = 'type',
}

export const pageSizeList = [
  10,
  20,
  50,
  100,
];

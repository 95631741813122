import { useAppContext } from '../contexts/app-context';
import { useSearchParams } from 'react-router-dom';
import { useEffect, useState } from 'react';
import {Series} from '../api-client/sport-bo-api';
import { createSportSeriesApi } from '../services/sport-services';
import {EditParams} from "../models/edition-enums";

export interface IUseEditSeriesProps {
  reload: boolean;
}
export const useEditSeries = ({ reload }: IUseEditSeriesProps) => {
  const { makeApiCall, auth } = useAppContext();
  const [searchParams] = useSearchParams();
  const [series, setSeries] = useState<Series | undefined>();

  useEffect(() => {
    if (
      searchParams.has(EditParams.seriesId) &&
      !isNaN(parseInt(searchParams.get(EditParams.seriesId)!))
    ) {
      loadSeries(parseInt(searchParams.get(EditParams.seriesId)!));
    }
  }, [reload]);

  const loadSeries = async (seriesId: number) => {
    if (!series || series.id !== seriesId) {
      setSeries(await makeApiCall((await createSportSeriesApi(auth)).getSeriesById(seriesId)));
    }
  };

  const updateSeries = (series?: Series) => {
    if(series && series.id) {
      searchParams.set(EditParams.seriesId, String(series.id));
    } else {
      searchParams.delete(EditParams.seriesId);
    }
    setSeries(series);
  }

  return { series, updateSeries };
};

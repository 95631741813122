import React from 'react';
import {UseFormRegister, UseFormSetValue} from 'react-hook-form/dist/types/form';
import { FieldValues } from 'react-hook-form/dist/types/fields';
import HtmlEditor from "../utils/html-editor";

export interface ITextareaProps {
  label: string;
  name?: string;
  initialValue?: string;
  setValue: UseFormSetValue<FieldValues>;
}
export const FormHtmlEditor = (props: ITextareaProps) => {

  const handleEditorChange = (content) => {
    props.setValue(props.name ? props.name : props.label, content);
  }

  return (
    <div className="mb-3 d-flex flex-row">
      {props.label && (
        <label htmlFor={props.name} className="col-form-label">
          {props.label}
        </label>
      )}

      <HtmlEditor initialValue={props.initialValue} handleEditorChange={handleEditorChange} />
    </div>
  );
};

/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from './configuration';
import type { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from './common';
import type { RequestArgs } from './base';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, BaseAPI, RequiredError } from './base';

/**
 * 
 * @export
 * @interface AbstractRankingItemDto
 */
export interface AbstractRankingItemDto {
    /**
     * 
     * @type {string}
     * @memberof AbstractRankingItemDto
     */
    'date'?: string;
    /**
     * 
     * @type {number}
     * @memberof AbstractRankingItemDto
     */
    'place'?: number;
    /**
     * 
     * @type {number}
     * @memberof AbstractRankingItemDto
     */
    'placeBefore'?: number;
    /**
     * 
     * @type {TeamDto}
     * @memberof AbstractRankingItemDto
     */
    'team'?: TeamDto;
    /**
     * 
     * @type {string}
     * @memberof AbstractRankingItemDto
     */
    'ranking'?: string;
}
/**
 * 
 * @export
 * @interface AddMediaToTeamDto
 */
export interface AddMediaToTeamDto {
    /**
     * 
     * @type {string}
     * @memberof AddMediaToTeamDto
     */
    'mediaType'?: string;
    /**
     * 
     * @type {number}
     * @memberof AddMediaToTeamDto
     */
    'teamId'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof AddMediaToTeamDto
     */
    'validated'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof AddMediaToTeamDto
     */
    'source'?: string;
    /**
     * 
     * @type {string}
     * @memberof AddMediaToTeamDto
     */
    'teamMediaType'?: string;
}
/**
 * 
 * @export
 * @interface AddMediaToTeamRequest
 */
export interface AddMediaToTeamRequest {
    /**
     * 
     * @type {AddMediaToTeamDto}
     * @memberof AddMediaToTeamRequest
     */
    'dto': AddMediaToTeamDto;
    /**
     * 
     * @type {File}
     * @memberof AddMediaToTeamRequest
     */
    'file': File;
}
/**
 * 
 * @export
 * @interface ArtefactDto
 */
export interface ArtefactDto {
    /**
     * 
     * @type {string}
     * @memberof ArtefactDto
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof ArtefactDto
     */
    'product'?: string;
    /**
     * 
     * @type {string}
     * @memberof ArtefactDto
     */
    'type'?: string;
    /**
     * 
     * @type {string}
     * @memberof ArtefactDto
     */
    'title'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof ArtefactDto
     */
    'commentsAllowed'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ArtefactDto
     */
    'commentsDisplayed'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof ArtefactDto
     */
    'commentsModerationType'?: string;
}
/**
 * 
 * @export
 * @interface Group
 */
export interface Group {
    /**
     * 
     * @type {number}
     * @memberof Group
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof Group
     */
    'creationDate'?: string;
    /**
     * 
     * @type {string}
     * @memberof Group
     */
    'updatedDate'?: string;
    /**
     * 
     * @type {string}
     * @memberof Group
     */
    'name'?: string;
    /**
     * 
     * @type {number}
     * @memberof Group
     */
    'depth'?: number;
    /**
     * 
     * @type {number}
     * @memberof Group
     */
    'toProcessCount'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof Group
     */
    'leaf'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof Group
     */
    'default'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof Group
     */
    'parentGroup'?: string;
    /**
     * 
     * @type {string}
     * @memberof Group
     */
    'uri'?: string;
}
/**
 * 
 * @export
 * @interface Link
 */
export interface Link {
    /**
     * 
     * @type {number}
     * @memberof Link
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof Link
     */
    'creationDate'?: string;
    /**
     * 
     * @type {string}
     * @memberof Link
     */
    'updatedDate'?: string;
    /**
     * 
     * @type {string}
     * @memberof Link
     */
    'url'?: string;
    /**
     * 
     * @type {string}
     * @memberof Link
     */
    'title'?: string;
    /**
     * 
     * @type {string}
     * @memberof Link
     */
    'text'?: string;
    /**
     * 
     * @type {number}
     * @memberof Link
     */
    'teamId'?: number;
    /**
     * 
     * @type {string}
     * @memberof Link
     */
    'uri'?: string;
}
/**
 * 
 * @export
 * @interface Match
 */
export interface Match {
    /**
     * 
     * @type {number}
     * @memberof Match
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof Match
     */
    'creationDate'?: string;
    /**
     * 
     * @type {string}
     * @memberof Match
     */
    'updatedDate'?: string;
    /**
     * 
     * @type {string}
     * @memberof Match
     */
    'date'?: string;
    /**
     * 
     * @type {string}
     * @memberof Match
     */
    'time'?: string;
    /**
     * 
     * @type {string}
     * @memberof Match
     */
    'day'?: string;
    /**
     * 
     * @type {string}
     * @memberof Match
     */
    'type'?: string;
    /**
     * 
     * @type {string}
     * @memberof Match
     */
    'status'?: string;
    /**
     * 
     * @type {string}
     * @memberof Match
     */
    'referee'?: string;
    /**
     * 
     * @type {number}
     * @memberof Match
     */
    'weekNumber'?: number;
    /**
     * 
     * @type {Team}
     * @memberof Match
     */
    'homeTeam'?: Team;
    /**
     * 
     * @type {Team}
     * @memberof Match
     */
    'awayTeam'?: Team;
    /**
     * 
     * @type {Result}
     * @memberof Match
     */
    'result'?: Result;
    /**
     * 
     * @type {boolean}
     * @memberof Match
     */
    'cesCommentsAllowed'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof Match
     */
    'cesCommentsDisplayed'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof Match
     */
    'cesModerationType'?: string;
    /**
     * 
     * @type {string}
     * @memberof Match
     */
    'pool'?: string;
    /**
     * 
     * @type {string}
     * @memberof Match
     */
    'uri'?: string;
}
/**
 * 
 * @export
 * @interface MatchDto
 */
export interface MatchDto {
    /**
     * 
     * @type {string}
     * @memberof MatchDto
     */
    'date'?: string;
    /**
     * 
     * @type {string}
     * @memberof MatchDto
     */
    'time'?: string;
    /**
     * 
     * @type {string}
     * @memberof MatchDto
     */
    'day'?: string;
    /**
     * 
     * @type {string}
     * @memberof MatchDto
     */
    'type'?: string;
    /**
     * 
     * @type {string}
     * @memberof MatchDto
     */
    'status'?: string;
    /**
     * 
     * @type {string}
     * @memberof MatchDto
     */
    'referee'?: string;
    /**
     * 
     * @type {number}
     * @memberof MatchDto
     */
    'weekNumber'?: number;
    /**
     * 
     * @type {TeamDto}
     * @memberof MatchDto
     */
    'homeTeam'?: TeamDto;
    /**
     * 
     * @type {TeamDto}
     * @memberof MatchDto
     */
    'awayTeam'?: TeamDto;
    /**
     * 
     * @type {Result}
     * @memberof MatchDto
     */
    'result'?: Result;
    /**
     * 
     * @type {boolean}
     * @memberof MatchDto
     */
    'cesCommentsAllowed'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof MatchDto
     */
    'cesCommentsDisplayed'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof MatchDto
     */
    'cesModerationType'?: string;
}
/**
 * 
 * @export
 * @interface Media
 */
export interface Media {
    /**
     * 
     * @type {number}
     * @memberof Media
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof Media
     */
    'creationDate'?: string;
    /**
     * 
     * @type {string}
     * @memberof Media
     */
    'updatedDate'?: string;
    /**
     * 
     * @type {string}
     * @memberof Media
     */
    'type'?: string;
    /**
     * 
     * @type {string}
     * @memberof Media
     */
    'url'?: string;
    /**
     * 
     * @type {string}
     * @memberof Media
     */
    'source'?: string;
    /**
     * 
     * @type {string}
     * @memberof Media
     */
    'path'?: string;
    /**
     * 
     * @type {number}
     * @memberof Media
     */
    'height'?: number;
    /**
     * 
     * @type {number}
     * @memberof Media
     */
    'width'?: number;
    /**
     * 
     * @type {string}
     * @memberof Media
     */
    'resourceType'?: string;
    /**
     * 
     * @type {string}
     * @memberof Media
     */
    'uri'?: string;
}
/**
 * 
 * @export
 * @interface PageMatch
 */
export interface PageMatch {
    /**
     * 
     * @type {number}
     * @memberof PageMatch
     */
    'totalPages'?: number;
    /**
     * 
     * @type {number}
     * @memberof PageMatch
     */
    'totalElements'?: number;
    /**
     * 
     * @type {number}
     * @memberof PageMatch
     */
    'size'?: number;
    /**
     * 
     * @type {Array<Match>}
     * @memberof PageMatch
     */
    'content'?: Array<Match>;
    /**
     * 
     * @type {number}
     * @memberof PageMatch
     */
    'number'?: number;
    /**
     * 
     * @type {SortObject}
     * @memberof PageMatch
     */
    'sort'?: SortObject;
    /**
     * 
     * @type {PageableObject}
     * @memberof PageMatch
     */
    'pageable'?: PageableObject;
    /**
     * 
     * @type {boolean}
     * @memberof PageMatch
     */
    'first'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof PageMatch
     */
    'last'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof PageMatch
     */
    'numberOfElements'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof PageMatch
     */
    'empty'?: boolean;
}
/**
 * 
 * @export
 * @interface PageMatchDto
 */
export interface PageMatchDto {
    /**
     * 
     * @type {number}
     * @memberof PageMatchDto
     */
    'totalPages'?: number;
    /**
     * 
     * @type {number}
     * @memberof PageMatchDto
     */
    'totalElements'?: number;
    /**
     * 
     * @type {number}
     * @memberof PageMatchDto
     */
    'size'?: number;
    /**
     * 
     * @type {Array<MatchDto>}
     * @memberof PageMatchDto
     */
    'content'?: Array<MatchDto>;
    /**
     * 
     * @type {number}
     * @memberof PageMatchDto
     */
    'number'?: number;
    /**
     * 
     * @type {SortObject}
     * @memberof PageMatchDto
     */
    'sort'?: SortObject;
    /**
     * 
     * @type {PageableObject}
     * @memberof PageMatchDto
     */
    'pageable'?: PageableObject;
    /**
     * 
     * @type {boolean}
     * @memberof PageMatchDto
     */
    'first'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof PageMatchDto
     */
    'last'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof PageMatchDto
     */
    'numberOfElements'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof PageMatchDto
     */
    'empty'?: boolean;
}
/**
 * 
 * @export
 * @interface PageSeries
 */
export interface PageSeries {
    /**
     * 
     * @type {number}
     * @memberof PageSeries
     */
    'totalPages'?: number;
    /**
     * 
     * @type {number}
     * @memberof PageSeries
     */
    'totalElements'?: number;
    /**
     * 
     * @type {number}
     * @memberof PageSeries
     */
    'size'?: number;
    /**
     * 
     * @type {Array<Series>}
     * @memberof PageSeries
     */
    'content'?: Array<Series>;
    /**
     * 
     * @type {number}
     * @memberof PageSeries
     */
    'number'?: number;
    /**
     * 
     * @type {SortObject}
     * @memberof PageSeries
     */
    'sort'?: SortObject;
    /**
     * 
     * @type {PageableObject}
     * @memberof PageSeries
     */
    'pageable'?: PageableObject;
    /**
     * 
     * @type {boolean}
     * @memberof PageSeries
     */
    'first'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof PageSeries
     */
    'last'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof PageSeries
     */
    'numberOfElements'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof PageSeries
     */
    'empty'?: boolean;
}
/**
 * 
 * @export
 * @interface PageTeam
 */
export interface PageTeam {
    /**
     * 
     * @type {number}
     * @memberof PageTeam
     */
    'totalPages'?: number;
    /**
     * 
     * @type {number}
     * @memberof PageTeam
     */
    'totalElements'?: number;
    /**
     * 
     * @type {number}
     * @memberof PageTeam
     */
    'size'?: number;
    /**
     * 
     * @type {Array<Team>}
     * @memberof PageTeam
     */
    'content'?: Array<Team>;
    /**
     * 
     * @type {number}
     * @memberof PageTeam
     */
    'number'?: number;
    /**
     * 
     * @type {SortObject}
     * @memberof PageTeam
     */
    'sort'?: SortObject;
    /**
     * 
     * @type {PageableObject}
     * @memberof PageTeam
     */
    'pageable'?: PageableObject;
    /**
     * 
     * @type {boolean}
     * @memberof PageTeam
     */
    'first'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof PageTeam
     */
    'last'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof PageTeam
     */
    'numberOfElements'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof PageTeam
     */
    'empty'?: boolean;
}
/**
 * 
 * @export
 * @interface PageTeamDto
 */
export interface PageTeamDto {
    /**
     * 
     * @type {number}
     * @memberof PageTeamDto
     */
    'totalPages'?: number;
    /**
     * 
     * @type {number}
     * @memberof PageTeamDto
     */
    'totalElements'?: number;
    /**
     * 
     * @type {number}
     * @memberof PageTeamDto
     */
    'size'?: number;
    /**
     * 
     * @type {Array<TeamDto>}
     * @memberof PageTeamDto
     */
    'content'?: Array<TeamDto>;
    /**
     * 
     * @type {number}
     * @memberof PageTeamDto
     */
    'number'?: number;
    /**
     * 
     * @type {SortObject}
     * @memberof PageTeamDto
     */
    'sort'?: SortObject;
    /**
     * 
     * @type {PageableObject}
     * @memberof PageTeamDto
     */
    'pageable'?: PageableObject;
    /**
     * 
     * @type {boolean}
     * @memberof PageTeamDto
     */
    'first'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof PageTeamDto
     */
    'last'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof PageTeamDto
     */
    'numberOfElements'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof PageTeamDto
     */
    'empty'?: boolean;
}
/**
 * 
 * @export
 * @interface PageTeamMedia
 */
export interface PageTeamMedia {
    /**
     * 
     * @type {number}
     * @memberof PageTeamMedia
     */
    'totalPages'?: number;
    /**
     * 
     * @type {number}
     * @memberof PageTeamMedia
     */
    'totalElements'?: number;
    /**
     * 
     * @type {number}
     * @memberof PageTeamMedia
     */
    'size'?: number;
    /**
     * 
     * @type {Array<TeamMedia>}
     * @memberof PageTeamMedia
     */
    'content'?: Array<TeamMedia>;
    /**
     * 
     * @type {number}
     * @memberof PageTeamMedia
     */
    'number'?: number;
    /**
     * 
     * @type {SortObject}
     * @memberof PageTeamMedia
     */
    'sort'?: SortObject;
    /**
     * 
     * @type {PageableObject}
     * @memberof PageTeamMedia
     */
    'pageable'?: PageableObject;
    /**
     * 
     * @type {boolean}
     * @memberof PageTeamMedia
     */
    'first'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof PageTeamMedia
     */
    'last'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof PageTeamMedia
     */
    'numberOfElements'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof PageTeamMedia
     */
    'empty'?: boolean;
}
/**
 * 
 * @export
 * @interface PageableObject
 */
export interface PageableObject {
    /**
     * 
     * @type {number}
     * @memberof PageableObject
     */
    'offset'?: number;
    /**
     * 
     * @type {SortObject}
     * @memberof PageableObject
     */
    'sort'?: SortObject;
    /**
     * 
     * @type {number}
     * @memberof PageableObject
     */
    'pageNumber'?: number;
    /**
     * 
     * @type {number}
     * @memberof PageableObject
     */
    'pageSize'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof PageableObject
     */
    'paged'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof PageableObject
     */
    'unpaged'?: boolean;
}
/**
 * 
 * @export
 * @interface Pool
 */
export interface Pool {
    /**
     * 
     * @type {number}
     * @memberof Pool
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof Pool
     */
    'creationDate'?: string;
    /**
     * 
     * @type {string}
     * @memberof Pool
     */
    'updatedDate'?: string;
    /**
     * 
     * @type {string}
     * @memberof Pool
     */
    'name'?: string;
    /**
     * 
     * @type {number}
     * @memberof Pool
     */
    'seasonNumber'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof Pool
     */
    'finished'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof Pool
     */
    'toProcessCount'?: number;
    /**
     * 
     * @type {string}
     * @memberof Pool
     */
    'resourceType'?: string;
    /**
     * 
     * @type {string}
     * @memberof Pool
     */
    'series'?: string;
    /**
     * 
     * @type {string}
     * @memberof Pool
     */
    'uri'?: string;
}
/**
 * 
 * @export
 * @interface RankingDto
 */
export interface RankingDto {
    /**
     * 
     * @type {number}
     * @memberof RankingDto
     */
    'weekNumber'?: number;
    /**
     * 
     * @type {string}
     * @memberof RankingDto
     */
    'startDate'?: string;
    /**
     * 
     * @type {string}
     * @memberof RankingDto
     */
    'endDate'?: string;
    /**
     * 
     * @type {string}
     * @memberof RankingDto
     */
    'pool'?: string;
    /**
     * 
     * @type {Array<AbstractRankingItemDto>}
     * @memberof RankingDto
     */
    'items'?: Array<AbstractRankingItemDto>;
}
/**
 * 
 * @export
 * @interface Result
 */
export interface Result {
    /**
     * 
     * @type {number}
     * @memberof Result
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof Result
     */
    'creationDate'?: string;
    /**
     * 
     * @type {string}
     * @memberof Result
     */
    'updatedDate'?: string;
    /**
     * 
     * @type {number}
     * @memberof Result
     */
    'homeScore'?: number;
    /**
     * 
     * @type {number}
     * @memberof Result
     */
    'awayScore'?: number;
    /**
     * 
     * @type {string}
     * @memberof Result
     */
    'uri'?: string;
}
/**
 * 
 * @export
 * @interface SeriePool
 */
export interface SeriePool {
    /**
     * 
     * @type {number}
     * @memberof SeriePool
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof SeriePool
     */
    'creationDate'?: string;
    /**
     * 
     * @type {string}
     * @memberof SeriePool
     */
    'updatedDate'?: string;
    /**
     * 
     * @type {string}
     * @memberof SeriePool
     */
    'name'?: string;
    /**
     * 
     * @type {number}
     * @memberof SeriePool
     */
    'order'?: number;
    /**
     * 
     * @type {number}
     * @memberof SeriePool
     */
    'poolId'?: number;
    /**
     * 
     * @type {string}
     * @memberof SeriePool
     */
    'season'?: string;
    /**
     * 
     * @type {number}
     * @memberof SeriePool
     */
    'seasonNumber'?: number;
    /**
     * 
     * @type {Pool}
     * @memberof SeriePool
     */
    'pool'?: Pool;
    /**
     * 
     * @type {string}
     * @memberof SeriePool
     */
    'uri'?: string;
}
/**
 * 
 * @export
 * @interface Series
 */
export interface Series {
    /**
     * 
     * @type {number}
     * @memberof Series
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof Series
     */
    'creationDate'?: string;
    /**
     * 
     * @type {string}
     * @memberof Series
     */
    'updatedDate'?: string;
    /**
     * 
     * @type {string}
     * @memberof Series
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof Series
     */
    'sectionId'?: string;
    /**
     * 
     * @type {string}
     * @memberof Series
     */
    'description'?: string;
    /**
     * 
     * @type {number}
     * @memberof Series
     */
    'groupId'?: number;
    /**
     * 
     * @type {number}
     * @memberof Series
     */
    'order'?: number;
    /**
     * 
     * @type {number}
     * @memberof Series
     */
    'toProcessCount'?: number;
    /**
     * 
     * @type {string}
     * @memberof Series
     */
    'fullyQualifiedName'?: string;
    /**
     * 
     * @type {string}
     * @memberof Series
     */
    'group'?: string;
    /**
     * 
     * @type {string}
     * @memberof Series
     */
    'uri'?: string;
}
/**
 * 
 * @export
 * @interface SortObject
 */
export interface SortObject {
    /**
     * 
     * @type {boolean}
     * @memberof SortObject
     */
    'empty'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof SortObject
     */
    'sorted'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof SortObject
     */
    'unsorted'?: boolean;
}
/**
 * 
 * @export
 * @interface Supporter
 */
export interface Supporter {
    /**
     * 
     * @type {number}
     * @memberof Supporter
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof Supporter
     */
    'creationDate'?: string;
    /**
     * 
     * @type {string}
     * @memberof Supporter
     */
    'updatedDate'?: string;
    /**
     * 
     * @type {string}
     * @memberof Supporter
     */
    'username'?: string;
    /**
     * 
     * @type {string}
     * @memberof Supporter
     */
    'nickname'?: string;
    /**
     * 
     * @type {string}
     * @memberof Supporter
     */
    'uri'?: string;
}
/**
 * 
 * @export
 * @interface SupporterOfTeam
 */
export interface SupporterOfTeam {
    /**
     * 
     * @type {number}
     * @memberof SupporterOfTeam
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof SupporterOfTeam
     */
    'creationDate'?: string;
    /**
     * 
     * @type {string}
     * @memberof SupporterOfTeam
     */
    'updatedDate'?: string;
    /**
     * 
     * @type {string}
     * @memberof SupporterOfTeam
     */
    'date'?: string;
    /**
     * 
     * @type {Supporter}
     * @memberof SupporterOfTeam
     */
    'supporter'?: Supporter;
    /**
     * 
     * @type {string}
     * @memberof SupporterOfTeam
     */
    'team'?: string;
    /**
     * 
     * @type {string}
     * @memberof SupporterOfTeam
     */
    'uri'?: string;
}
/**
 * 
 * @export
 * @interface Team
 */
export interface Team {
    /**
     * 
     * @type {number}
     * @memberof Team
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof Team
     */
    'creationDate'?: string;
    /**
     * 
     * @type {string}
     * @memberof Team
     */
    'updatedDate'?: string;
    /**
     * 
     * @type {string}
     * @memberof Team
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof Team
     */
    'nickname'?: string;
    /**
     * 
     * @type {string}
     * @memberof Team
     */
    'foundationDate'?: string;
    /**
     * 
     * @type {string}
     * @memberof Team
     */
    'address'?: string;
    /**
     * 
     * @type {string}
     * @memberof Team
     */
    'headquarters'?: string;
    /**
     * 
     * @type {string}
     * @memberof Team
     */
    'websiteUrl'?: string;
    /**
     * 
     * @type {string}
     * @memberof Team
     */
    'blogUrl'?: string;
    /**
     * 
     * @type {string}
     * @memberof Team
     */
    'mapCoordinates'?: string;
    /**
     * 
     * @type {number}
     * @memberof Team
     */
    'mapZoomLevel'?: number;
    /**
     * 
     * @type {string}
     * @memberof Team
     */
    'mapType'?: string;
    /**
     * 
     * @type {string}
     * @memberof Team
     */
    'phone'?: string;
    /**
     * 
     * @type {string}
     * @memberof Team
     */
    'email'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof Team
     */
    'toProcess'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof Team
     */
    'acceptUpdateProposals'?: boolean;
    /**
     * 
     * @type {Array<TeamUpdateProposal>}
     * @memberof Team
     */
    'updateProposals'?: Array<TeamUpdateProposal>;
    /**
     * 
     * @type {boolean}
     * @memberof Team
     */
    'cesCommentsAllowed'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof Team
     */
    'cesCommentsDisplayed'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof Team
     */
    'cesModerationType'?: string;
    /**
     * 
     * @type {string}
     * @memberof Team
     */
    'fullyQualifiedName'?: string;
    /**
     * 
     * @type {Array<TeamMedia>}
     * @memberof Team
     */
    'teamMedias'?: Array<TeamMedia>;
    /**
     * 
     * @type {Array<Link>}
     * @memberof Team
     */
    'teamLinks'?: Array<Link>;
    /**
     * 
     * @type {number}
     * @memberof Team
     */
    'supporterCount'?: number;
    /**
     * 
     * @type {string}
     * @memberof Team
     */
    'logoUrl'?: string;
    /**
     * 
     * @type {string}
     * @memberof Team
     */
    'uri'?: string;
}
/**
 * 
 * @export
 * @interface TeamDto
 */
export interface TeamDto {
    /**
     * 
     * @type {string}
     * @memberof TeamDto
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof TeamDto
     */
    'nickname'?: string;
    /**
     * 
     * @type {string}
     * @memberof TeamDto
     */
    'foundationDate'?: string;
    /**
     * 
     * @type {string}
     * @memberof TeamDto
     */
    'address'?: string;
    /**
     * 
     * @type {string}
     * @memberof TeamDto
     */
    'headquarters'?: string;
    /**
     * 
     * @type {string}
     * @memberof TeamDto
     */
    'websiteUrl'?: string;
    /**
     * 
     * @type {string}
     * @memberof TeamDto
     */
    'blogUrl'?: string;
    /**
     * 
     * @type {string}
     * @memberof TeamDto
     */
    'mapCoordinates'?: string;
    /**
     * 
     * @type {number}
     * @memberof TeamDto
     */
    'mapZoomLevel'?: number;
    /**
     * 
     * @type {string}
     * @memberof TeamDto
     */
    'mapType'?: string;
    /**
     * 
     * @type {string}
     * @memberof TeamDto
     */
    'phone'?: string;
    /**
     * 
     * @type {string}
     * @memberof TeamDto
     */
    'email'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof TeamDto
     */
    'toProcess'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof TeamDto
     */
    'acceptUpdateProposals'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof TeamDto
     */
    'cesCommentsAllowed'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof TeamDto
     */
    'cesCommentsDisplayed'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof TeamDto
     */
    'cesModerationType'?: string;
    /**
     * 
     * @type {string}
     * @memberof TeamDto
     */
    'fullyQualifiedName'?: string;
    /**
     * 
     * @type {Array<Link>}
     * @memberof TeamDto
     */
    'teamLinks'?: Array<Link>;
    /**
     * 
     * @type {number}
     * @memberof TeamDto
     */
    'supporterCount'?: number;
    /**
     * 
     * @type {string}
     * @memberof TeamDto
     */
    'logoUrl'?: string;
}
/**
 * 
 * @export
 * @interface TeamMedia
 */
export interface TeamMedia {
    /**
     * 
     * @type {number}
     * @memberof TeamMedia
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof TeamMedia
     */
    'creationDate'?: string;
    /**
     * 
     * @type {string}
     * @memberof TeamMedia
     */
    'updatedDate'?: string;
    /**
     * 
     * @type {Team}
     * @memberof TeamMedia
     */
    'team'?: Team;
    /**
     * 
     * @type {Media}
     * @memberof TeamMedia
     */
    'media'?: Media;
    /**
     * 
     * @type {string}
     * @memberof TeamMedia
     */
    'type'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof TeamMedia
     */
    'validated'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof TeamMedia
     */
    'uri'?: string;
}
/**
 * 
 * @export
 * @interface TeamUpdateProposal
 */
export interface TeamUpdateProposal {
    /**
     * 
     * @type {number}
     * @memberof TeamUpdateProposal
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof TeamUpdateProposal
     */
    'creationDate'?: string;
    /**
     * 
     * @type {string}
     * @memberof TeamUpdateProposal
     */
    'updatedDate'?: string;
    /**
     * 
     * @type {number}
     * @memberof TeamUpdateProposal
     */
    'teamId'?: number;
    /**
     * 
     * @type {string}
     * @memberof TeamUpdateProposal
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof TeamUpdateProposal
     */
    'nickname'?: string;
    /**
     * 
     * @type {string}
     * @memberof TeamUpdateProposal
     */
    'foundationDate'?: string;
    /**
     * 
     * @type {string}
     * @memberof TeamUpdateProposal
     */
    'address'?: string;
    /**
     * 
     * @type {string}
     * @memberof TeamUpdateProposal
     */
    'headquarters'?: string;
    /**
     * 
     * @type {string}
     * @memberof TeamUpdateProposal
     */
    'websiteUrl'?: string;
    /**
     * 
     * @type {string}
     * @memberof TeamUpdateProposal
     */
    'blogUrl'?: string;
    /**
     * 
     * @type {string}
     * @memberof TeamUpdateProposal
     */
    'mapCoordinates'?: string;
    /**
     * 
     * @type {number}
     * @memberof TeamUpdateProposal
     */
    'mapZoomLevel'?: number;
    /**
     * 
     * @type {string}
     * @memberof TeamUpdateProposal
     */
    'mapType'?: string;
    /**
     * 
     * @type {string}
     * @memberof TeamUpdateProposal
     */
    'phone'?: string;
    /**
     * 
     * @type {string}
     * @memberof TeamUpdateProposal
     */
    'email'?: string;
    /**
     * 
     * @type {string}
     * @memberof TeamUpdateProposal
     */
    'proposalDate'?: string;
    /**
     * 
     * @type {string}
     * @memberof TeamUpdateProposal
     */
    'username'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof TeamUpdateProposal
     */
    'toProcess'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof TeamUpdateProposal
     */
    'uri'?: string;
}
/**
 * 
 * @export
 * @interface UploadMediaRequest
 */
export interface UploadMediaRequest {
    /**
     * 
     * @type {File}
     * @memberof UploadMediaRequest
     */
    'media': File;
}
/**
 * 
 * @export
 * @interface Week
 */
export interface Week {
    /**
     * 
     * @type {number}
     * @memberof Week
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof Week
     */
    'creationDate'?: string;
    /**
     * 
     * @type {string}
     * @memberof Week
     */
    'updatedDate'?: string;
    /**
     * 
     * @type {string}
     * @memberof Week
     */
    'startDate'?: string;
    /**
     * 
     * @type {string}
     * @memberof Week
     */
    'endDate'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof Week
     */
    'current'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof Week
     */
    'uri'?: string;
}

/**
 * ArtefactControllerApi - axios parameter creator
 * @export
 */
export const ArtefactControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findArtefactById: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('findArtefactById', 'id', id)
            const localVarPath = `/artefact/{id}/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findArtefactById1: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('findArtefactById1', 'id', id)
            const localVarPath = `/artefact/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ArtefactControllerApi - functional programming interface
 * @export
 */
export const ArtefactControllerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ArtefactControllerApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async findArtefactById(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ArtefactDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.findArtefactById(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async findArtefactById1(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ArtefactDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.findArtefactById1(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ArtefactControllerApi - factory interface
 * @export
 */
export const ArtefactControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ArtefactControllerApiFp(configuration)
    return {
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findArtefactById(id: string, options?: any): AxiosPromise<ArtefactDto> {
            return localVarFp.findArtefactById(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findArtefactById1(id: string, options?: any): AxiosPromise<ArtefactDto> {
            return localVarFp.findArtefactById1(id, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ArtefactControllerApi - object-oriented interface
 * @export
 * @class ArtefactControllerApi
 * @extends {BaseAPI}
 */
export class ArtefactControllerApi extends BaseAPI {
    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ArtefactControllerApi
     */
    public findArtefactById(id: string, options?: AxiosRequestConfig) {
        return ArtefactControllerApiFp(this.configuration).findArtefactById(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ArtefactControllerApi
     */
    public findArtefactById1(id: string, options?: AxiosRequestConfig) {
        return ArtefactControllerApiFp(this.configuration).findArtefactById1(id, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * GroupBoRestControllerApi - axios parameter creator
 * @export
 */
export const GroupBoRestControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getGroupsByParentId: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getGroupsByParentId', 'id', id)
            const localVarPath = `/bo/group/groupsByParentId/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTopLevelGroups: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/bo/group/topLevelGroups`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setGroupAsDefault: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('setGroupAsDefault', 'id', id)
            const localVarPath = `/bo/group/setDefault/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * GroupBoRestControllerApi - functional programming interface
 * @export
 */
export const GroupBoRestControllerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = GroupBoRestControllerApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getGroupsByParentId(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Group>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getGroupsByParentId(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getTopLevelGroups(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Group>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getTopLevelGroups(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async setGroupAsDefault(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.setGroupAsDefault(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * GroupBoRestControllerApi - factory interface
 * @export
 */
export const GroupBoRestControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = GroupBoRestControllerApiFp(configuration)
    return {
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getGroupsByParentId(id: number, options?: any): AxiosPromise<Array<Group>> {
            return localVarFp.getGroupsByParentId(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTopLevelGroups(options?: any): AxiosPromise<Array<Group>> {
            return localVarFp.getTopLevelGroups(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setGroupAsDefault(id: number, options?: any): AxiosPromise<void> {
            return localVarFp.setGroupAsDefault(id, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * GroupBoRestControllerApi - object-oriented interface
 * @export
 * @class GroupBoRestControllerApi
 * @extends {BaseAPI}
 */
export class GroupBoRestControllerApi extends BaseAPI {
    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GroupBoRestControllerApi
     */
    public getGroupsByParentId(id: number, options?: AxiosRequestConfig) {
        return GroupBoRestControllerApiFp(this.configuration).getGroupsByParentId(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GroupBoRestControllerApi
     */
    public getTopLevelGroups(options?: AxiosRequestConfig) {
        return GroupBoRestControllerApiFp(this.configuration).getTopLevelGroups(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GroupBoRestControllerApi
     */
    public setGroupAsDefault(id: number, options?: AxiosRequestConfig) {
        return GroupBoRestControllerApiFp(this.configuration).setGroupAsDefault(id, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * GroupControllerApi - axios parameter creator
 * @export
 */
export const GroupControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {Group} group 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createGroup: async (group: Group, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'group' is not null or undefined
            assertParamExists('createGroup', 'group', group)
            const localVarPath = `/group`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(group, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {Group} group 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createGroup1: async (group: Group, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'group' is not null or undefined
            assertParamExists('createGroup1', 'group', group)
            const localVarPath = `/group/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(group, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {Series} series 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createSeriesInGroupById: async (id: number, series: Series, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('createSeriesInGroupById', 'id', id)
            // verify required parameter 'series' is not null or undefined
            assertParamExists('createSeriesInGroupById', 'series', series)
            const localVarPath = `/group/{id}/series/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(series, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {Series} series 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createSeriesInGroupById1: async (id: number, series: Series, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('createSeriesInGroupById1', 'id', id)
            // verify required parameter 'series' is not null or undefined
            assertParamExists('createSeriesInGroupById1', 'series', series)
            const localVarPath = `/group/{id}/series`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(series, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteGroupById: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('deleteGroupById', 'id', id)
            const localVarPath = `/group/{id}/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteGroupById1: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('deleteGroupById1', 'id', id)
            const localVarPath = `/group/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findGroupById: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('findGroupById', 'id', id)
            const localVarPath = `/group/{id}/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findGroupById1: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('findGroupById1', 'id', id)
            const localVarPath = `/group/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findGroupsByParent: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('findGroupsByParent', 'id', id)
            const localVarPath = `/group/{id}/group`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findGroupsByParent1: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('findGroupsByParent1', 'id', id)
            const localVarPath = `/group/{id}/group/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findPoolsOfGroupById: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('findPoolsOfGroupById', 'id', id)
            const localVarPath = `/group/{id}/pools/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findPoolsOfGroupById1: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('findPoolsOfGroupById1', 'id', id)
            const localVarPath = `/group/{id}/pools`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findSeriesOfGroupById: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('findSeriesOfGroupById', 'id', id)
            const localVarPath = `/group/{id}/series/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findSeriesOfGroupById1: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('findSeriesOfGroupById1', 'id', id)
            const localVarPath = `/group/{id}/series`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findTopLevelGroupList: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/group`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findTopLevelGroupList1: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/group/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {Group} group 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateGroupById: async (id: number, group: Group, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('updateGroupById', 'id', id)
            // verify required parameter 'group' is not null or undefined
            assertParamExists('updateGroupById', 'group', group)
            const localVarPath = `/group/{id}/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (group !== undefined) {
                localVarQueryParameter['group'] = group;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {Group} group 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateGroupById1: async (id: number, group: Group, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('updateGroupById1', 'id', id)
            // verify required parameter 'group' is not null or undefined
            assertParamExists('updateGroupById1', 'group', group)
            const localVarPath = `/group/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (group !== undefined) {
                localVarQueryParameter['group'] = group;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * GroupControllerApi - functional programming interface
 * @export
 */
export const GroupControllerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = GroupControllerApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {Group} group 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createGroup(group: Group, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Group>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createGroup(group, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {Group} group 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createGroup1(group: Group, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Group>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createGroup1(group, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {Series} series 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createSeriesInGroupById(id: number, series: Series, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Series>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createSeriesInGroupById(id, series, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {Series} series 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createSeriesInGroupById1(id: number, series: Series, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Series>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createSeriesInGroupById1(id, series, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteGroupById(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteGroupById(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteGroupById1(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteGroupById1(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async findGroupById(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Group>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.findGroupById(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async findGroupById1(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Group>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.findGroupById1(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async findGroupsByParent(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Group>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.findGroupsByParent(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async findGroupsByParent1(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Group>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.findGroupsByParent1(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async findPoolsOfGroupById(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<SeriePool>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.findPoolsOfGroupById(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async findPoolsOfGroupById1(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<SeriePool>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.findPoolsOfGroupById1(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async findSeriesOfGroupById(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Series>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.findSeriesOfGroupById(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async findSeriesOfGroupById1(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Series>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.findSeriesOfGroupById1(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async findTopLevelGroupList(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Group>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.findTopLevelGroupList(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async findTopLevelGroupList1(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Group>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.findTopLevelGroupList1(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {Group} group 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateGroupById(id: number, group: Group, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Group>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateGroupById(id, group, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {Group} group 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateGroupById1(id: number, group: Group, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Group>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateGroupById1(id, group, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * GroupControllerApi - factory interface
 * @export
 */
export const GroupControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = GroupControllerApiFp(configuration)
    return {
        /**
         * 
         * @param {Group} group 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createGroup(group: Group, options?: any): AxiosPromise<Group> {
            return localVarFp.createGroup(group, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {Group} group 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createGroup1(group: Group, options?: any): AxiosPromise<Group> {
            return localVarFp.createGroup1(group, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {Series} series 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createSeriesInGroupById(id: number, series: Series, options?: any): AxiosPromise<Series> {
            return localVarFp.createSeriesInGroupById(id, series, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {Series} series 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createSeriesInGroupById1(id: number, series: Series, options?: any): AxiosPromise<Series> {
            return localVarFp.createSeriesInGroupById1(id, series, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteGroupById(id: number, options?: any): AxiosPromise<void> {
            return localVarFp.deleteGroupById(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteGroupById1(id: number, options?: any): AxiosPromise<void> {
            return localVarFp.deleteGroupById1(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findGroupById(id: number, options?: any): AxiosPromise<Group> {
            return localVarFp.findGroupById(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findGroupById1(id: number, options?: any): AxiosPromise<Group> {
            return localVarFp.findGroupById1(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findGroupsByParent(id: number, options?: any): AxiosPromise<Array<Group>> {
            return localVarFp.findGroupsByParent(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findGroupsByParent1(id: number, options?: any): AxiosPromise<Array<Group>> {
            return localVarFp.findGroupsByParent1(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findPoolsOfGroupById(id: number, options?: any): AxiosPromise<Array<SeriePool>> {
            return localVarFp.findPoolsOfGroupById(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findPoolsOfGroupById1(id: number, options?: any): AxiosPromise<Array<SeriePool>> {
            return localVarFp.findPoolsOfGroupById1(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findSeriesOfGroupById(id: number, options?: any): AxiosPromise<Array<Series>> {
            return localVarFp.findSeriesOfGroupById(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findSeriesOfGroupById1(id: number, options?: any): AxiosPromise<Array<Series>> {
            return localVarFp.findSeriesOfGroupById1(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findTopLevelGroupList(options?: any): AxiosPromise<Array<Group>> {
            return localVarFp.findTopLevelGroupList(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findTopLevelGroupList1(options?: any): AxiosPromise<Array<Group>> {
            return localVarFp.findTopLevelGroupList1(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {Group} group 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateGroupById(id: number, group: Group, options?: any): AxiosPromise<Group> {
            return localVarFp.updateGroupById(id, group, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {Group} group 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateGroupById1(id: number, group: Group, options?: any): AxiosPromise<Group> {
            return localVarFp.updateGroupById1(id, group, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * GroupControllerApi - object-oriented interface
 * @export
 * @class GroupControllerApi
 * @extends {BaseAPI}
 */
export class GroupControllerApi extends BaseAPI {
    /**
     * 
     * @param {Group} group 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GroupControllerApi
     */
    public createGroup(group: Group, options?: AxiosRequestConfig) {
        return GroupControllerApiFp(this.configuration).createGroup(group, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {Group} group 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GroupControllerApi
     */
    public createGroup1(group: Group, options?: AxiosRequestConfig) {
        return GroupControllerApiFp(this.configuration).createGroup1(group, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {Series} series 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GroupControllerApi
     */
    public createSeriesInGroupById(id: number, series: Series, options?: AxiosRequestConfig) {
        return GroupControllerApiFp(this.configuration).createSeriesInGroupById(id, series, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {Series} series 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GroupControllerApi
     */
    public createSeriesInGroupById1(id: number, series: Series, options?: AxiosRequestConfig) {
        return GroupControllerApiFp(this.configuration).createSeriesInGroupById1(id, series, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GroupControllerApi
     */
    public deleteGroupById(id: number, options?: AxiosRequestConfig) {
        return GroupControllerApiFp(this.configuration).deleteGroupById(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GroupControllerApi
     */
    public deleteGroupById1(id: number, options?: AxiosRequestConfig) {
        return GroupControllerApiFp(this.configuration).deleteGroupById1(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GroupControllerApi
     */
    public findGroupById(id: number, options?: AxiosRequestConfig) {
        return GroupControllerApiFp(this.configuration).findGroupById(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GroupControllerApi
     */
    public findGroupById1(id: number, options?: AxiosRequestConfig) {
        return GroupControllerApiFp(this.configuration).findGroupById1(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GroupControllerApi
     */
    public findGroupsByParent(id: number, options?: AxiosRequestConfig) {
        return GroupControllerApiFp(this.configuration).findGroupsByParent(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GroupControllerApi
     */
    public findGroupsByParent1(id: number, options?: AxiosRequestConfig) {
        return GroupControllerApiFp(this.configuration).findGroupsByParent1(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GroupControllerApi
     */
    public findPoolsOfGroupById(id: number, options?: AxiosRequestConfig) {
        return GroupControllerApiFp(this.configuration).findPoolsOfGroupById(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GroupControllerApi
     */
    public findPoolsOfGroupById1(id: number, options?: AxiosRequestConfig) {
        return GroupControllerApiFp(this.configuration).findPoolsOfGroupById1(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GroupControllerApi
     */
    public findSeriesOfGroupById(id: number, options?: AxiosRequestConfig) {
        return GroupControllerApiFp(this.configuration).findSeriesOfGroupById(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GroupControllerApi
     */
    public findSeriesOfGroupById1(id: number, options?: AxiosRequestConfig) {
        return GroupControllerApiFp(this.configuration).findSeriesOfGroupById1(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GroupControllerApi
     */
    public findTopLevelGroupList(options?: AxiosRequestConfig) {
        return GroupControllerApiFp(this.configuration).findTopLevelGroupList(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GroupControllerApi
     */
    public findTopLevelGroupList1(options?: AxiosRequestConfig) {
        return GroupControllerApiFp(this.configuration).findTopLevelGroupList1(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {Group} group 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GroupControllerApi
     */
    public updateGroupById(id: number, group: Group, options?: AxiosRequestConfig) {
        return GroupControllerApiFp(this.configuration).updateGroupById(id, group, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {Group} group 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GroupControllerApi
     */
    public updateGroupById1(id: number, group: Group, options?: AxiosRequestConfig) {
        return GroupControllerApiFp(this.configuration).updateGroupById1(id, group, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * MatchBoRestControllerApi - axios parameter creator
 * @export
 */
export const MatchBoRestControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {number} [poolId] 
         * @param {number} [teamId] 
         * @param {string} [startDate] 
         * @param {string} [endDate] 
         * @param {string} [date] 
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        searchMatches: async (poolId?: number, teamId?: number, startDate?: string, endDate?: string, date?: string, page?: number, size?: number, sort?: Array<string>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/bo/match/search`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (poolId !== undefined) {
                localVarQueryParameter['poolId'] = poolId;
            }

            if (teamId !== undefined) {
                localVarQueryParameter['teamId'] = teamId;
            }

            if (startDate !== undefined) {
                localVarQueryParameter['startDate'] = (startDate as any instanceof Date) ?
                    (startDate as any).toISOString() :
                    startDate;
            }

            if (endDate !== undefined) {
                localVarQueryParameter['endDate'] = (endDate as any instanceof Date) ?
                    (endDate as any).toISOString() :
                    endDate;
            }

            if (date !== undefined) {
                localVarQueryParameter['date'] = (date as any instanceof Date) ?
                    (date as any).toISOString() :
                    date;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }

            if (sort) {
                localVarQueryParameter['sort'] = sort;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {Match} match 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateMatch: async (id: number, match: Match, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('updateMatch', 'id', id)
            // verify required parameter 'match' is not null or undefined
            assertParamExists('updateMatch', 'match', match)
            const localVarPath = `/bo/match/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(match, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * MatchBoRestControllerApi - functional programming interface
 * @export
 */
export const MatchBoRestControllerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = MatchBoRestControllerApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {number} [poolId] 
         * @param {number} [teamId] 
         * @param {string} [startDate] 
         * @param {string} [endDate] 
         * @param {string} [date] 
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async searchMatches(poolId?: number, teamId?: number, startDate?: string, endDate?: string, date?: string, page?: number, size?: number, sort?: Array<string>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PageMatch>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.searchMatches(poolId, teamId, startDate, endDate, date, page, size, sort, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {Match} match 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateMatch(id: number, match: Match, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Match>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateMatch(id, match, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * MatchBoRestControllerApi - factory interface
 * @export
 */
export const MatchBoRestControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = MatchBoRestControllerApiFp(configuration)
    return {
        /**
         * 
         * @param {number} [poolId] 
         * @param {number} [teamId] 
         * @param {string} [startDate] 
         * @param {string} [endDate] 
         * @param {string} [date] 
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        searchMatches(poolId?: number, teamId?: number, startDate?: string, endDate?: string, date?: string, page?: number, size?: number, sort?: Array<string>, options?: any): AxiosPromise<PageMatch> {
            return localVarFp.searchMatches(poolId, teamId, startDate, endDate, date, page, size, sort, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {Match} match 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateMatch(id: number, match: Match, options?: any): AxiosPromise<Match> {
            return localVarFp.updateMatch(id, match, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * MatchBoRestControllerApi - object-oriented interface
 * @export
 * @class MatchBoRestControllerApi
 * @extends {BaseAPI}
 */
export class MatchBoRestControllerApi extends BaseAPI {
    /**
     * 
     * @param {number} [poolId] 
     * @param {number} [teamId] 
     * @param {string} [startDate] 
     * @param {string} [endDate] 
     * @param {string} [date] 
     * @param {number} [page] Zero-based page index (0..N)
     * @param {number} [size] The size of the page to be returned
     * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MatchBoRestControllerApi
     */
    public searchMatches(poolId?: number, teamId?: number, startDate?: string, endDate?: string, date?: string, page?: number, size?: number, sort?: Array<string>, options?: AxiosRequestConfig) {
        return MatchBoRestControllerApiFp(this.configuration).searchMatches(poolId, teamId, startDate, endDate, date, page, size, sort, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {Match} match 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MatchBoRestControllerApi
     */
    public updateMatch(id: number, match: Match, options?: AxiosRequestConfig) {
        return MatchBoRestControllerApiFp(this.configuration).updateMatch(id, match, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * MatchControllerApi - axios parameter creator
 * @export
 */
export const MatchControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findMatchById: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('findMatchById', 'id', id)
            const localVarPath = `/match/{id}/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findMatchById1: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('findMatchById1', 'id', id)
            const localVarPath = `/match/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * MatchControllerApi - functional programming interface
 * @export
 */
export const MatchControllerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = MatchControllerApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async findMatchById(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MatchDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.findMatchById(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async findMatchById1(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MatchDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.findMatchById1(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * MatchControllerApi - factory interface
 * @export
 */
export const MatchControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = MatchControllerApiFp(configuration)
    return {
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findMatchById(id: number, options?: any): AxiosPromise<MatchDto> {
            return localVarFp.findMatchById(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findMatchById1(id: number, options?: any): AxiosPromise<MatchDto> {
            return localVarFp.findMatchById1(id, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * MatchControllerApi - object-oriented interface
 * @export
 * @class MatchControllerApi
 * @extends {BaseAPI}
 */
export class MatchControllerApi extends BaseAPI {
    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MatchControllerApi
     */
    public findMatchById(id: number, options?: AxiosRequestConfig) {
        return MatchControllerApiFp(this.configuration).findMatchById(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MatchControllerApi
     */
    public findMatchById1(id: number, options?: AxiosRequestConfig) {
        return MatchControllerApiFp(this.configuration).findMatchById1(id, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * PoolBoRestControllerApi - axios parameter creator
 * @export
 */
export const PoolBoRestControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {number} seriesId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getLatestPoolInSeriesById: async (seriesId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'seriesId' is not null or undefined
            assertParamExists('getLatestPoolInSeriesById', 'seriesId', seriesId)
            const localVarPath = `/bo/pool/latest/series/{seriesId}`
                .replace(`{${"seriesId"}}`, encodeURIComponent(String(seriesId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * PoolBoRestControllerApi - functional programming interface
 * @export
 */
export const PoolBoRestControllerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = PoolBoRestControllerApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {number} seriesId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getLatestPoolInSeriesById(seriesId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Pool>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getLatestPoolInSeriesById(seriesId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * PoolBoRestControllerApi - factory interface
 * @export
 */
export const PoolBoRestControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = PoolBoRestControllerApiFp(configuration)
    return {
        /**
         * 
         * @param {number} seriesId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getLatestPoolInSeriesById(seriesId: number, options?: any): AxiosPromise<Pool> {
            return localVarFp.getLatestPoolInSeriesById(seriesId, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * PoolBoRestControllerApi - object-oriented interface
 * @export
 * @class PoolBoRestControllerApi
 * @extends {BaseAPI}
 */
export class PoolBoRestControllerApi extends BaseAPI {
    /**
     * 
     * @param {number} seriesId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PoolBoRestControllerApi
     */
    public getLatestPoolInSeriesById(seriesId: number, options?: AxiosRequestConfig) {
        return PoolBoRestControllerApiFp(this.configuration).getLatestPoolInSeriesById(seriesId, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * PoolControllerApi - axios parameter creator
 * @export
 */
export const PoolControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {number} id 
         * @param {number} [poolId] 
         * @param {number} [timespan] 
         * @param {FindLatestRankingInPoolRankingTypeEnum} [rankingType] 
         * @param {FindLatestRankingInPoolSortFieldEnum} [sortField] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findLatestRankingInPool: async (id: number, poolId?: number, timespan?: number, rankingType?: FindLatestRankingInPoolRankingTypeEnum, sortField?: FindLatestRankingInPoolSortFieldEnum, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('findLatestRankingInPool', 'id', id)
            const localVarPath = `/pool/{id}/ranking/latest/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (poolId !== undefined) {
                localVarQueryParameter['poolId'] = poolId;
            }

            if (timespan !== undefined) {
                localVarQueryParameter['timespan'] = timespan;
            }

            if (rankingType !== undefined) {
                localVarQueryParameter['rankingType'] = rankingType;
            }

            if (sortField !== undefined) {
                localVarQueryParameter['sortField'] = sortField;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {number} [poolId] 
         * @param {number} [timespan] 
         * @param {FindLatestRankingInPool1RankingTypeEnum} [rankingType] 
         * @param {FindLatestRankingInPool1SortFieldEnum} [sortField] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findLatestRankingInPool1: async (id: number, poolId?: number, timespan?: number, rankingType?: FindLatestRankingInPool1RankingTypeEnum, sortField?: FindLatestRankingInPool1SortFieldEnum, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('findLatestRankingInPool1', 'id', id)
            const localVarPath = `/pool/{id}/ranking/latest`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (poolId !== undefined) {
                localVarQueryParameter['poolId'] = poolId;
            }

            if (timespan !== undefined) {
                localVarQueryParameter['timespan'] = timespan;
            }

            if (rankingType !== undefined) {
                localVarQueryParameter['rankingType'] = rankingType;
            }

            if (sortField !== undefined) {
                localVarQueryParameter['sortField'] = sortField;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {number} [poolId] 
         * @param {number} [teamId] 
         * @param {string} [startDate] 
         * @param {string} [endDate] 
         * @param {string} [date] 
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findMatchesInPool: async (id: number, poolId?: number, teamId?: number, startDate?: string, endDate?: string, date?: string, page?: number, size?: number, sort?: Array<string>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('findMatchesInPool', 'id', id)
            const localVarPath = `/pool/{id}/match/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (poolId !== undefined) {
                localVarQueryParameter['poolId'] = poolId;
            }

            if (teamId !== undefined) {
                localVarQueryParameter['teamId'] = teamId;
            }

            if (startDate !== undefined) {
                localVarQueryParameter['startDate'] = (startDate as any instanceof Date) ?
                    (startDate as any).toISOString() :
                    startDate;
            }

            if (endDate !== undefined) {
                localVarQueryParameter['endDate'] = (endDate as any instanceof Date) ?
                    (endDate as any).toISOString() :
                    endDate;
            }

            if (date !== undefined) {
                localVarQueryParameter['date'] = (date as any instanceof Date) ?
                    (date as any).toISOString() :
                    date;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }

            if (sort) {
                localVarQueryParameter['sort'] = sort;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {number} [poolId] 
         * @param {number} [teamId] 
         * @param {string} [startDate] 
         * @param {string} [endDate] 
         * @param {string} [date] 
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findMatchesInPool1: async (id: number, poolId?: number, teamId?: number, startDate?: string, endDate?: string, date?: string, page?: number, size?: number, sort?: Array<string>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('findMatchesInPool1', 'id', id)
            const localVarPath = `/pool/{id}/match`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (poolId !== undefined) {
                localVarQueryParameter['poolId'] = poolId;
            }

            if (teamId !== undefined) {
                localVarQueryParameter['teamId'] = teamId;
            }

            if (startDate !== undefined) {
                localVarQueryParameter['startDate'] = (startDate as any instanceof Date) ?
                    (startDate as any).toISOString() :
                    startDate;
            }

            if (endDate !== undefined) {
                localVarQueryParameter['endDate'] = (endDate as any instanceof Date) ?
                    (endDate as any).toISOString() :
                    endDate;
            }

            if (date !== undefined) {
                localVarQueryParameter['date'] = (date as any instanceof Date) ?
                    (date as any).toISOString() :
                    date;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }

            if (sort) {
                localVarQueryParameter['sort'] = sort;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} idPool 
         * @param {number} idTeam 
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findMatchesOfTeamInsidePool: async (idPool: number, idTeam: number, page?: number, size?: number, sort?: Array<string>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'idPool' is not null or undefined
            assertParamExists('findMatchesOfTeamInsidePool', 'idPool', idPool)
            // verify required parameter 'idTeam' is not null or undefined
            assertParamExists('findMatchesOfTeamInsidePool', 'idTeam', idTeam)
            const localVarPath = `/pool/{idPool}/team/{idTeam}/match`
                .replace(`{${"idPool"}}`, encodeURIComponent(String(idPool)))
                .replace(`{${"idTeam"}}`, encodeURIComponent(String(idTeam)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }

            if (sort) {
                localVarQueryParameter['sort'] = sort;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} idPool 
         * @param {number} idTeam 
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findMatchesOfTeamInsidePool1: async (idPool: number, idTeam: number, page?: number, size?: number, sort?: Array<string>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'idPool' is not null or undefined
            assertParamExists('findMatchesOfTeamInsidePool1', 'idPool', idPool)
            // verify required parameter 'idTeam' is not null or undefined
            assertParamExists('findMatchesOfTeamInsidePool1', 'idTeam', idTeam)
            const localVarPath = `/pool/{idPool}/team/{idTeam}/match/`
                .replace(`{${"idPool"}}`, encodeURIComponent(String(idPool)))
                .replace(`{${"idTeam"}}`, encodeURIComponent(String(idTeam)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }

            if (sort) {
                localVarQueryParameter['sort'] = sort;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findPoolById: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('findPoolById', 'id', id)
            const localVarPath = `/pool/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findPoolById1: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('findPoolById1', 'id', id)
            const localVarPath = `/pool/{id}/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {number} [poolId] 
         * @param {string} [date] 
         * @param {number} [timespan] 
         * @param {FindRankingInPoolByEndDateRankingTypeEnum} [rankingType] 
         * @param {FindRankingInPoolByEndDateSortFieldEnum} [sortField] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findRankingInPoolByEndDate: async (id: number, poolId?: number, date?: string, timespan?: number, rankingType?: FindRankingInPoolByEndDateRankingTypeEnum, sortField?: FindRankingInPoolByEndDateSortFieldEnum, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('findRankingInPoolByEndDate', 'id', id)
            const localVarPath = `/pool/{id}/ranking`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (poolId !== undefined) {
                localVarQueryParameter['poolId'] = poolId;
            }

            if (date !== undefined) {
                localVarQueryParameter['date'] = (date as any instanceof Date) ?
                    (date as any).toISOString() :
                    date;
            }

            if (timespan !== undefined) {
                localVarQueryParameter['timespan'] = timespan;
            }

            if (rankingType !== undefined) {
                localVarQueryParameter['rankingType'] = rankingType;
            }

            if (sortField !== undefined) {
                localVarQueryParameter['sortField'] = sortField;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {number} [poolId] 
         * @param {string} [date] 
         * @param {number} [timespan] 
         * @param {FindRankingInPoolByEndDate1RankingTypeEnum} [rankingType] 
         * @param {FindRankingInPoolByEndDate1SortFieldEnum} [sortField] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findRankingInPoolByEndDate1: async (id: number, poolId?: number, date?: string, timespan?: number, rankingType?: FindRankingInPoolByEndDate1RankingTypeEnum, sortField?: FindRankingInPoolByEndDate1SortFieldEnum, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('findRankingInPoolByEndDate1', 'id', id)
            const localVarPath = `/pool/{id}/ranking/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (poolId !== undefined) {
                localVarQueryParameter['poolId'] = poolId;
            }

            if (date !== undefined) {
                localVarQueryParameter['date'] = (date as any instanceof Date) ?
                    (date as any).toISOString() :
                    date;
            }

            if (timespan !== undefined) {
                localVarQueryParameter['timespan'] = timespan;
            }

            if (rankingType !== undefined) {
                localVarQueryParameter['rankingType'] = rankingType;
            }

            if (sortField !== undefined) {
                localVarQueryParameter['sortField'] = sortField;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} idPool 
         * @param {number} idTeam 
         * @param {FindRankingsOfTeamInsidePoolRankingTypeEnum} [rankingType] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findRankingsOfTeamInsidePool: async (idPool: number, idTeam: number, rankingType?: FindRankingsOfTeamInsidePoolRankingTypeEnum, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'idPool' is not null or undefined
            assertParamExists('findRankingsOfTeamInsidePool', 'idPool', idPool)
            // verify required parameter 'idTeam' is not null or undefined
            assertParamExists('findRankingsOfTeamInsidePool', 'idTeam', idTeam)
            const localVarPath = `/pool/{idPool}/team/{idTeam}/ranking`
                .replace(`{${"idPool"}}`, encodeURIComponent(String(idPool)))
                .replace(`{${"idTeam"}}`, encodeURIComponent(String(idTeam)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (rankingType !== undefined) {
                localVarQueryParameter['rankingType'] = rankingType;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} idPool 
         * @param {number} idTeam 
         * @param {FindRankingsOfTeamInsidePool1RankingTypeEnum} [rankingType] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findRankingsOfTeamInsidePool1: async (idPool: number, idTeam: number, rankingType?: FindRankingsOfTeamInsidePool1RankingTypeEnum, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'idPool' is not null or undefined
            assertParamExists('findRankingsOfTeamInsidePool1', 'idPool', idPool)
            // verify required parameter 'idTeam' is not null or undefined
            assertParamExists('findRankingsOfTeamInsidePool1', 'idTeam', idTeam)
            const localVarPath = `/pool/{idPool}/team/{idTeam}/ranking/`
                .replace(`{${"idPool"}}`, encodeURIComponent(String(idPool)))
                .replace(`{${"idTeam"}}`, encodeURIComponent(String(idTeam)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (rankingType !== undefined) {
                localVarQueryParameter['rankingType'] = rankingType;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findTeamsInPool: async (id: number, page?: number, size?: number, sort?: Array<string>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('findTeamsInPool', 'id', id)
            const localVarPath = `/pool/{id}/team`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }

            if (sort) {
                localVarQueryParameter['sort'] = sort;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findTeamsInPool1: async (id: number, page?: number, size?: number, sort?: Array<string>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('findTeamsInPool1', 'id', id)
            const localVarPath = `/pool/{id}/team/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }

            if (sort) {
                localVarQueryParameter['sort'] = sort;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPoolCalendar: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getPoolCalendar', 'id', id)
            const localVarPath = `/pool/{id}/calendar/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPoolCalendar1: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getPoolCalendar1', 'id', id)
            const localVarPath = `/pool/{id}/calendar`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * PoolControllerApi - functional programming interface
 * @export
 */
export const PoolControllerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = PoolControllerApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {number} id 
         * @param {number} [poolId] 
         * @param {number} [timespan] 
         * @param {FindLatestRankingInPoolRankingTypeEnum} [rankingType] 
         * @param {FindLatestRankingInPoolSortFieldEnum} [sortField] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async findLatestRankingInPool(id: number, poolId?: number, timespan?: number, rankingType?: FindLatestRankingInPoolRankingTypeEnum, sortField?: FindLatestRankingInPoolSortFieldEnum, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RankingDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.findLatestRankingInPool(id, poolId, timespan, rankingType, sortField, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {number} [poolId] 
         * @param {number} [timespan] 
         * @param {FindLatestRankingInPool1RankingTypeEnum} [rankingType] 
         * @param {FindLatestRankingInPool1SortFieldEnum} [sortField] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async findLatestRankingInPool1(id: number, poolId?: number, timespan?: number, rankingType?: FindLatestRankingInPool1RankingTypeEnum, sortField?: FindLatestRankingInPool1SortFieldEnum, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RankingDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.findLatestRankingInPool1(id, poolId, timespan, rankingType, sortField, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {number} [poolId] 
         * @param {number} [teamId] 
         * @param {string} [startDate] 
         * @param {string} [endDate] 
         * @param {string} [date] 
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async findMatchesInPool(id: number, poolId?: number, teamId?: number, startDate?: string, endDate?: string, date?: string, page?: number, size?: number, sort?: Array<string>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PageMatchDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.findMatchesInPool(id, poolId, teamId, startDate, endDate, date, page, size, sort, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {number} [poolId] 
         * @param {number} [teamId] 
         * @param {string} [startDate] 
         * @param {string} [endDate] 
         * @param {string} [date] 
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async findMatchesInPool1(id: number, poolId?: number, teamId?: number, startDate?: string, endDate?: string, date?: string, page?: number, size?: number, sort?: Array<string>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PageMatchDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.findMatchesInPool1(id, poolId, teamId, startDate, endDate, date, page, size, sort, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} idPool 
         * @param {number} idTeam 
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async findMatchesOfTeamInsidePool(idPool: number, idTeam: number, page?: number, size?: number, sort?: Array<string>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PageMatchDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.findMatchesOfTeamInsidePool(idPool, idTeam, page, size, sort, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} idPool 
         * @param {number} idTeam 
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async findMatchesOfTeamInsidePool1(idPool: number, idTeam: number, page?: number, size?: number, sort?: Array<string>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PageMatchDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.findMatchesOfTeamInsidePool1(idPool, idTeam, page, size, sort, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async findPoolById(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Pool>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.findPoolById(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async findPoolById1(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Pool>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.findPoolById1(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {number} [poolId] 
         * @param {string} [date] 
         * @param {number} [timespan] 
         * @param {FindRankingInPoolByEndDateRankingTypeEnum} [rankingType] 
         * @param {FindRankingInPoolByEndDateSortFieldEnum} [sortField] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async findRankingInPoolByEndDate(id: number, poolId?: number, date?: string, timespan?: number, rankingType?: FindRankingInPoolByEndDateRankingTypeEnum, sortField?: FindRankingInPoolByEndDateSortFieldEnum, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RankingDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.findRankingInPoolByEndDate(id, poolId, date, timespan, rankingType, sortField, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {number} [poolId] 
         * @param {string} [date] 
         * @param {number} [timespan] 
         * @param {FindRankingInPoolByEndDate1RankingTypeEnum} [rankingType] 
         * @param {FindRankingInPoolByEndDate1SortFieldEnum} [sortField] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async findRankingInPoolByEndDate1(id: number, poolId?: number, date?: string, timespan?: number, rankingType?: FindRankingInPoolByEndDate1RankingTypeEnum, sortField?: FindRankingInPoolByEndDate1SortFieldEnum, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RankingDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.findRankingInPoolByEndDate1(id, poolId, date, timespan, rankingType, sortField, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} idPool 
         * @param {number} idTeam 
         * @param {FindRankingsOfTeamInsidePoolRankingTypeEnum} [rankingType] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async findRankingsOfTeamInsidePool(idPool: number, idTeam: number, rankingType?: FindRankingsOfTeamInsidePoolRankingTypeEnum, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<RankingDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.findRankingsOfTeamInsidePool(idPool, idTeam, rankingType, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} idPool 
         * @param {number} idTeam 
         * @param {FindRankingsOfTeamInsidePool1RankingTypeEnum} [rankingType] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async findRankingsOfTeamInsidePool1(idPool: number, idTeam: number, rankingType?: FindRankingsOfTeamInsidePool1RankingTypeEnum, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<RankingDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.findRankingsOfTeamInsidePool1(idPool, idTeam, rankingType, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async findTeamsInPool(id: number, page?: number, size?: number, sort?: Array<string>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PageTeamDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.findTeamsInPool(id, page, size, sort, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async findTeamsInPool1(id: number, page?: number, size?: number, sort?: Array<string>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PageTeamDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.findTeamsInPool1(id, page, size, sort, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getPoolCalendar(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Week>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getPoolCalendar(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getPoolCalendar1(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Week>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getPoolCalendar1(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * PoolControllerApi - factory interface
 * @export
 */
export const PoolControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = PoolControllerApiFp(configuration)
    return {
        /**
         * 
         * @param {number} id 
         * @param {number} [poolId] 
         * @param {number} [timespan] 
         * @param {FindLatestRankingInPoolRankingTypeEnum} [rankingType] 
         * @param {FindLatestRankingInPoolSortFieldEnum} [sortField] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findLatestRankingInPool(id: number, poolId?: number, timespan?: number, rankingType?: FindLatestRankingInPoolRankingTypeEnum, sortField?: FindLatestRankingInPoolSortFieldEnum, options?: any): AxiosPromise<RankingDto> {
            return localVarFp.findLatestRankingInPool(id, poolId, timespan, rankingType, sortField, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {number} [poolId] 
         * @param {number} [timespan] 
         * @param {FindLatestRankingInPool1RankingTypeEnum} [rankingType] 
         * @param {FindLatestRankingInPool1SortFieldEnum} [sortField] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findLatestRankingInPool1(id: number, poolId?: number, timespan?: number, rankingType?: FindLatestRankingInPool1RankingTypeEnum, sortField?: FindLatestRankingInPool1SortFieldEnum, options?: any): AxiosPromise<RankingDto> {
            return localVarFp.findLatestRankingInPool1(id, poolId, timespan, rankingType, sortField, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {number} [poolId] 
         * @param {number} [teamId] 
         * @param {string} [startDate] 
         * @param {string} [endDate] 
         * @param {string} [date] 
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findMatchesInPool(id: number, poolId?: number, teamId?: number, startDate?: string, endDate?: string, date?: string, page?: number, size?: number, sort?: Array<string>, options?: any): AxiosPromise<PageMatchDto> {
            return localVarFp.findMatchesInPool(id, poolId, teamId, startDate, endDate, date, page, size, sort, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {number} [poolId] 
         * @param {number} [teamId] 
         * @param {string} [startDate] 
         * @param {string} [endDate] 
         * @param {string} [date] 
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findMatchesInPool1(id: number, poolId?: number, teamId?: number, startDate?: string, endDate?: string, date?: string, page?: number, size?: number, sort?: Array<string>, options?: any): AxiosPromise<PageMatchDto> {
            return localVarFp.findMatchesInPool1(id, poolId, teamId, startDate, endDate, date, page, size, sort, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} idPool 
         * @param {number} idTeam 
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findMatchesOfTeamInsidePool(idPool: number, idTeam: number, page?: number, size?: number, sort?: Array<string>, options?: any): AxiosPromise<PageMatchDto> {
            return localVarFp.findMatchesOfTeamInsidePool(idPool, idTeam, page, size, sort, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} idPool 
         * @param {number} idTeam 
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findMatchesOfTeamInsidePool1(idPool: number, idTeam: number, page?: number, size?: number, sort?: Array<string>, options?: any): AxiosPromise<PageMatchDto> {
            return localVarFp.findMatchesOfTeamInsidePool1(idPool, idTeam, page, size, sort, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findPoolById(id: number, options?: any): AxiosPromise<Pool> {
            return localVarFp.findPoolById(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findPoolById1(id: number, options?: any): AxiosPromise<Pool> {
            return localVarFp.findPoolById1(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {number} [poolId] 
         * @param {string} [date] 
         * @param {number} [timespan] 
         * @param {FindRankingInPoolByEndDateRankingTypeEnum} [rankingType] 
         * @param {FindRankingInPoolByEndDateSortFieldEnum} [sortField] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findRankingInPoolByEndDate(id: number, poolId?: number, date?: string, timespan?: number, rankingType?: FindRankingInPoolByEndDateRankingTypeEnum, sortField?: FindRankingInPoolByEndDateSortFieldEnum, options?: any): AxiosPromise<RankingDto> {
            return localVarFp.findRankingInPoolByEndDate(id, poolId, date, timespan, rankingType, sortField, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {number} [poolId] 
         * @param {string} [date] 
         * @param {number} [timespan] 
         * @param {FindRankingInPoolByEndDate1RankingTypeEnum} [rankingType] 
         * @param {FindRankingInPoolByEndDate1SortFieldEnum} [sortField] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findRankingInPoolByEndDate1(id: number, poolId?: number, date?: string, timespan?: number, rankingType?: FindRankingInPoolByEndDate1RankingTypeEnum, sortField?: FindRankingInPoolByEndDate1SortFieldEnum, options?: any): AxiosPromise<RankingDto> {
            return localVarFp.findRankingInPoolByEndDate1(id, poolId, date, timespan, rankingType, sortField, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} idPool 
         * @param {number} idTeam 
         * @param {FindRankingsOfTeamInsidePoolRankingTypeEnum} [rankingType] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findRankingsOfTeamInsidePool(idPool: number, idTeam: number, rankingType?: FindRankingsOfTeamInsidePoolRankingTypeEnum, options?: any): AxiosPromise<Array<RankingDto>> {
            return localVarFp.findRankingsOfTeamInsidePool(idPool, idTeam, rankingType, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} idPool 
         * @param {number} idTeam 
         * @param {FindRankingsOfTeamInsidePool1RankingTypeEnum} [rankingType] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findRankingsOfTeamInsidePool1(idPool: number, idTeam: number, rankingType?: FindRankingsOfTeamInsidePool1RankingTypeEnum, options?: any): AxiosPromise<Array<RankingDto>> {
            return localVarFp.findRankingsOfTeamInsidePool1(idPool, idTeam, rankingType, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findTeamsInPool(id: number, page?: number, size?: number, sort?: Array<string>, options?: any): AxiosPromise<PageTeamDto> {
            return localVarFp.findTeamsInPool(id, page, size, sort, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findTeamsInPool1(id: number, page?: number, size?: number, sort?: Array<string>, options?: any): AxiosPromise<PageTeamDto> {
            return localVarFp.findTeamsInPool1(id, page, size, sort, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPoolCalendar(id: number, options?: any): AxiosPromise<Array<Week>> {
            return localVarFp.getPoolCalendar(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPoolCalendar1(id: number, options?: any): AxiosPromise<Array<Week>> {
            return localVarFp.getPoolCalendar1(id, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * PoolControllerApi - object-oriented interface
 * @export
 * @class PoolControllerApi
 * @extends {BaseAPI}
 */
export class PoolControllerApi extends BaseAPI {
    /**
     * 
     * @param {number} id 
     * @param {number} [poolId] 
     * @param {number} [timespan] 
     * @param {FindLatestRankingInPoolRankingTypeEnum} [rankingType] 
     * @param {FindLatestRankingInPoolSortFieldEnum} [sortField] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PoolControllerApi
     */
    public findLatestRankingInPool(id: number, poolId?: number, timespan?: number, rankingType?: FindLatestRankingInPoolRankingTypeEnum, sortField?: FindLatestRankingInPoolSortFieldEnum, options?: AxiosRequestConfig) {
        return PoolControllerApiFp(this.configuration).findLatestRankingInPool(id, poolId, timespan, rankingType, sortField, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {number} [poolId] 
     * @param {number} [timespan] 
     * @param {FindLatestRankingInPool1RankingTypeEnum} [rankingType] 
     * @param {FindLatestRankingInPool1SortFieldEnum} [sortField] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PoolControllerApi
     */
    public findLatestRankingInPool1(id: number, poolId?: number, timespan?: number, rankingType?: FindLatestRankingInPool1RankingTypeEnum, sortField?: FindLatestRankingInPool1SortFieldEnum, options?: AxiosRequestConfig) {
        return PoolControllerApiFp(this.configuration).findLatestRankingInPool1(id, poolId, timespan, rankingType, sortField, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {number} [poolId] 
     * @param {number} [teamId] 
     * @param {string} [startDate] 
     * @param {string} [endDate] 
     * @param {string} [date] 
     * @param {number} [page] Zero-based page index (0..N)
     * @param {number} [size] The size of the page to be returned
     * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PoolControllerApi
     */
    public findMatchesInPool(id: number, poolId?: number, teamId?: number, startDate?: string, endDate?: string, date?: string, page?: number, size?: number, sort?: Array<string>, options?: AxiosRequestConfig) {
        return PoolControllerApiFp(this.configuration).findMatchesInPool(id, poolId, teamId, startDate, endDate, date, page, size, sort, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {number} [poolId] 
     * @param {number} [teamId] 
     * @param {string} [startDate] 
     * @param {string} [endDate] 
     * @param {string} [date] 
     * @param {number} [page] Zero-based page index (0..N)
     * @param {number} [size] The size of the page to be returned
     * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PoolControllerApi
     */
    public findMatchesInPool1(id: number, poolId?: number, teamId?: number, startDate?: string, endDate?: string, date?: string, page?: number, size?: number, sort?: Array<string>, options?: AxiosRequestConfig) {
        return PoolControllerApiFp(this.configuration).findMatchesInPool1(id, poolId, teamId, startDate, endDate, date, page, size, sort, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} idPool 
     * @param {number} idTeam 
     * @param {number} [page] Zero-based page index (0..N)
     * @param {number} [size] The size of the page to be returned
     * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PoolControllerApi
     */
    public findMatchesOfTeamInsidePool(idPool: number, idTeam: number, page?: number, size?: number, sort?: Array<string>, options?: AxiosRequestConfig) {
        return PoolControllerApiFp(this.configuration).findMatchesOfTeamInsidePool(idPool, idTeam, page, size, sort, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} idPool 
     * @param {number} idTeam 
     * @param {number} [page] Zero-based page index (0..N)
     * @param {number} [size] The size of the page to be returned
     * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PoolControllerApi
     */
    public findMatchesOfTeamInsidePool1(idPool: number, idTeam: number, page?: number, size?: number, sort?: Array<string>, options?: AxiosRequestConfig) {
        return PoolControllerApiFp(this.configuration).findMatchesOfTeamInsidePool1(idPool, idTeam, page, size, sort, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PoolControllerApi
     */
    public findPoolById(id: number, options?: AxiosRequestConfig) {
        return PoolControllerApiFp(this.configuration).findPoolById(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PoolControllerApi
     */
    public findPoolById1(id: number, options?: AxiosRequestConfig) {
        return PoolControllerApiFp(this.configuration).findPoolById1(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {number} [poolId] 
     * @param {string} [date] 
     * @param {number} [timespan] 
     * @param {FindRankingInPoolByEndDateRankingTypeEnum} [rankingType] 
     * @param {FindRankingInPoolByEndDateSortFieldEnum} [sortField] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PoolControllerApi
     */
    public findRankingInPoolByEndDate(id: number, poolId?: number, date?: string, timespan?: number, rankingType?: FindRankingInPoolByEndDateRankingTypeEnum, sortField?: FindRankingInPoolByEndDateSortFieldEnum, options?: AxiosRequestConfig) {
        return PoolControllerApiFp(this.configuration).findRankingInPoolByEndDate(id, poolId, date, timespan, rankingType, sortField, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {number} [poolId] 
     * @param {string} [date] 
     * @param {number} [timespan] 
     * @param {FindRankingInPoolByEndDate1RankingTypeEnum} [rankingType] 
     * @param {FindRankingInPoolByEndDate1SortFieldEnum} [sortField] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PoolControllerApi
     */
    public findRankingInPoolByEndDate1(id: number, poolId?: number, date?: string, timespan?: number, rankingType?: FindRankingInPoolByEndDate1RankingTypeEnum, sortField?: FindRankingInPoolByEndDate1SortFieldEnum, options?: AxiosRequestConfig) {
        return PoolControllerApiFp(this.configuration).findRankingInPoolByEndDate1(id, poolId, date, timespan, rankingType, sortField, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} idPool 
     * @param {number} idTeam 
     * @param {FindRankingsOfTeamInsidePoolRankingTypeEnum} [rankingType] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PoolControllerApi
     */
    public findRankingsOfTeamInsidePool(idPool: number, idTeam: number, rankingType?: FindRankingsOfTeamInsidePoolRankingTypeEnum, options?: AxiosRequestConfig) {
        return PoolControllerApiFp(this.configuration).findRankingsOfTeamInsidePool(idPool, idTeam, rankingType, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} idPool 
     * @param {number} idTeam 
     * @param {FindRankingsOfTeamInsidePool1RankingTypeEnum} [rankingType] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PoolControllerApi
     */
    public findRankingsOfTeamInsidePool1(idPool: number, idTeam: number, rankingType?: FindRankingsOfTeamInsidePool1RankingTypeEnum, options?: AxiosRequestConfig) {
        return PoolControllerApiFp(this.configuration).findRankingsOfTeamInsidePool1(idPool, idTeam, rankingType, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {number} [page] Zero-based page index (0..N)
     * @param {number} [size] The size of the page to be returned
     * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PoolControllerApi
     */
    public findTeamsInPool(id: number, page?: number, size?: number, sort?: Array<string>, options?: AxiosRequestConfig) {
        return PoolControllerApiFp(this.configuration).findTeamsInPool(id, page, size, sort, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {number} [page] Zero-based page index (0..N)
     * @param {number} [size] The size of the page to be returned
     * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PoolControllerApi
     */
    public findTeamsInPool1(id: number, page?: number, size?: number, sort?: Array<string>, options?: AxiosRequestConfig) {
        return PoolControllerApiFp(this.configuration).findTeamsInPool1(id, page, size, sort, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PoolControllerApi
     */
    public getPoolCalendar(id: number, options?: AxiosRequestConfig) {
        return PoolControllerApiFp(this.configuration).getPoolCalendar(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PoolControllerApi
     */
    public getPoolCalendar1(id: number, options?: AxiosRequestConfig) {
        return PoolControllerApiFp(this.configuration).getPoolCalendar1(id, options).then((request) => request(this.axios, this.basePath));
    }
}

/**
 * @export
 */
export const FindLatestRankingInPoolRankingTypeEnum = {
    General: 'general',
    Home: 'home',
    Away: 'away'
} as const;
export type FindLatestRankingInPoolRankingTypeEnum = typeof FindLatestRankingInPoolRankingTypeEnum[keyof typeof FindLatestRankingInPoolRankingTypeEnum];
/**
 * @export
 */
export const FindLatestRankingInPoolSortFieldEnum = {
    Points: 'points',
    GoalsFor: 'goalsFor',
    GoalsAgainst: 'goalsAgainst'
} as const;
export type FindLatestRankingInPoolSortFieldEnum = typeof FindLatestRankingInPoolSortFieldEnum[keyof typeof FindLatestRankingInPoolSortFieldEnum];
/**
 * @export
 */
export const FindLatestRankingInPool1RankingTypeEnum = {
    General: 'general',
    Home: 'home',
    Away: 'away'
} as const;
export type FindLatestRankingInPool1RankingTypeEnum = typeof FindLatestRankingInPool1RankingTypeEnum[keyof typeof FindLatestRankingInPool1RankingTypeEnum];
/**
 * @export
 */
export const FindLatestRankingInPool1SortFieldEnum = {
    Points: 'points',
    GoalsFor: 'goalsFor',
    GoalsAgainst: 'goalsAgainst'
} as const;
export type FindLatestRankingInPool1SortFieldEnum = typeof FindLatestRankingInPool1SortFieldEnum[keyof typeof FindLatestRankingInPool1SortFieldEnum];
/**
 * @export
 */
export const FindRankingInPoolByEndDateRankingTypeEnum = {
    General: 'general',
    Home: 'home',
    Away: 'away'
} as const;
export type FindRankingInPoolByEndDateRankingTypeEnum = typeof FindRankingInPoolByEndDateRankingTypeEnum[keyof typeof FindRankingInPoolByEndDateRankingTypeEnum];
/**
 * @export
 */
export const FindRankingInPoolByEndDateSortFieldEnum = {
    Points: 'points',
    GoalsFor: 'goalsFor',
    GoalsAgainst: 'goalsAgainst'
} as const;
export type FindRankingInPoolByEndDateSortFieldEnum = typeof FindRankingInPoolByEndDateSortFieldEnum[keyof typeof FindRankingInPoolByEndDateSortFieldEnum];
/**
 * @export
 */
export const FindRankingInPoolByEndDate1RankingTypeEnum = {
    General: 'general',
    Home: 'home',
    Away: 'away'
} as const;
export type FindRankingInPoolByEndDate1RankingTypeEnum = typeof FindRankingInPoolByEndDate1RankingTypeEnum[keyof typeof FindRankingInPoolByEndDate1RankingTypeEnum];
/**
 * @export
 */
export const FindRankingInPoolByEndDate1SortFieldEnum = {
    Points: 'points',
    GoalsFor: 'goalsFor',
    GoalsAgainst: 'goalsAgainst'
} as const;
export type FindRankingInPoolByEndDate1SortFieldEnum = typeof FindRankingInPoolByEndDate1SortFieldEnum[keyof typeof FindRankingInPoolByEndDate1SortFieldEnum];
/**
 * @export
 */
export const FindRankingsOfTeamInsidePoolRankingTypeEnum = {
    General: 'general',
    Home: 'home',
    Away: 'away'
} as const;
export type FindRankingsOfTeamInsidePoolRankingTypeEnum = typeof FindRankingsOfTeamInsidePoolRankingTypeEnum[keyof typeof FindRankingsOfTeamInsidePoolRankingTypeEnum];
/**
 * @export
 */
export const FindRankingsOfTeamInsidePool1RankingTypeEnum = {
    General: 'general',
    Home: 'home',
    Away: 'away'
} as const;
export type FindRankingsOfTeamInsidePool1RankingTypeEnum = typeof FindRankingsOfTeamInsidePool1RankingTypeEnum[keyof typeof FindRankingsOfTeamInsidePool1RankingTypeEnum];


/**
 * RankingControllerApi - axios parameter creator
 * @export
 */
export const RankingControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findRankingById: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('findRankingById', 'id', id)
            const localVarPath = `/ranking/{id}/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findRankingById1: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('findRankingById1', 'id', id)
            const localVarPath = `/ranking/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * RankingControllerApi - functional programming interface
 * @export
 */
export const RankingControllerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = RankingControllerApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async findRankingById(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RankingDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.findRankingById(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async findRankingById1(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RankingDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.findRankingById1(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * RankingControllerApi - factory interface
 * @export
 */
export const RankingControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = RankingControllerApiFp(configuration)
    return {
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findRankingById(id: number, options?: any): AxiosPromise<RankingDto> {
            return localVarFp.findRankingById(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findRankingById1(id: number, options?: any): AxiosPromise<RankingDto> {
            return localVarFp.findRankingById1(id, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * RankingControllerApi - object-oriented interface
 * @export
 * @class RankingControllerApi
 * @extends {BaseAPI}
 */
export class RankingControllerApi extends BaseAPI {
    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RankingControllerApi
     */
    public findRankingById(id: number, options?: AxiosRequestConfig) {
        return RankingControllerApiFp(this.configuration).findRankingById(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RankingControllerApi
     */
    public findRankingById1(id: number, options?: AxiosRequestConfig) {
        return RankingControllerApiFp(this.configuration).findRankingById1(id, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * SeriesBoRestControllerApi - axios parameter creator
 * @export
 */
export const SeriesBoRestControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        feedFullyQualifiedNames: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/bo/series/feedFullyQualifiedNames`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSeriesById: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getSeriesById', 'id', id)
            const localVarPath = `/bo/series/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} groupId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSeriesInGroupById: async (groupId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'groupId' is not null or undefined
            assertParamExists('getSeriesInGroupById', 'groupId', groupId)
            const localVarPath = `/bo/series/all/group/{groupId}`
                .replace(`{${"groupId"}}`, encodeURIComponent(String(groupId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [queryString] 
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        searchSeries: async (queryString?: string, page?: number, size?: number, sort?: Array<string>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/bo/series/search`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (queryString !== undefined) {
                localVarQueryParameter['queryString'] = queryString;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }

            if (sort) {
                localVarQueryParameter['sort'] = sort;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {Series} series 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateSeries: async (id: number, series: Series, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('updateSeries', 'id', id)
            // verify required parameter 'series' is not null or undefined
            assertParamExists('updateSeries', 'series', series)
            const localVarPath = `/bo/series/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(series, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * SeriesBoRestControllerApi - functional programming interface
 * @export
 */
export const SeriesBoRestControllerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = SeriesBoRestControllerApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async feedFullyQualifiedNames(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.feedFullyQualifiedNames(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getSeriesById(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Series>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getSeriesById(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} groupId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getSeriesInGroupById(groupId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Series>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getSeriesInGroupById(groupId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} [queryString] 
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async searchSeries(queryString?: string, page?: number, size?: number, sort?: Array<string>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PageSeries>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.searchSeries(queryString, page, size, sort, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {Series} series 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateSeries(id: number, series: Series, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Series>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateSeries(id, series, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * SeriesBoRestControllerApi - factory interface
 * @export
 */
export const SeriesBoRestControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = SeriesBoRestControllerApiFp(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        feedFullyQualifiedNames(options?: any): AxiosPromise<void> {
            return localVarFp.feedFullyQualifiedNames(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSeriesById(id: number, options?: any): AxiosPromise<Series> {
            return localVarFp.getSeriesById(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} groupId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSeriesInGroupById(groupId: number, options?: any): AxiosPromise<Array<Series>> {
            return localVarFp.getSeriesInGroupById(groupId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [queryString] 
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        searchSeries(queryString?: string, page?: number, size?: number, sort?: Array<string>, options?: any): AxiosPromise<PageSeries> {
            return localVarFp.searchSeries(queryString, page, size, sort, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {Series} series 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateSeries(id: number, series: Series, options?: any): AxiosPromise<Series> {
            return localVarFp.updateSeries(id, series, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * SeriesBoRestControllerApi - object-oriented interface
 * @export
 * @class SeriesBoRestControllerApi
 * @extends {BaseAPI}
 */
export class SeriesBoRestControllerApi extends BaseAPI {
    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SeriesBoRestControllerApi
     */
    public feedFullyQualifiedNames(options?: AxiosRequestConfig) {
        return SeriesBoRestControllerApiFp(this.configuration).feedFullyQualifiedNames(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SeriesBoRestControllerApi
     */
    public getSeriesById(id: number, options?: AxiosRequestConfig) {
        return SeriesBoRestControllerApiFp(this.configuration).getSeriesById(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} groupId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SeriesBoRestControllerApi
     */
    public getSeriesInGroupById(groupId: number, options?: AxiosRequestConfig) {
        return SeriesBoRestControllerApiFp(this.configuration).getSeriesInGroupById(groupId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [queryString] 
     * @param {number} [page] Zero-based page index (0..N)
     * @param {number} [size] The size of the page to be returned
     * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SeriesBoRestControllerApi
     */
    public searchSeries(queryString?: string, page?: number, size?: number, sort?: Array<string>, options?: AxiosRequestConfig) {
        return SeriesBoRestControllerApiFp(this.configuration).searchSeries(queryString, page, size, sort, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {Series} series 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SeriesBoRestControllerApi
     */
    public updateSeries(id: number, series: Series, options?: AxiosRequestConfig) {
        return SeriesBoRestControllerApiFp(this.configuration).updateSeries(id, series, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * SeriesControllerApi - axios parameter creator
 * @export
 */
export const SeriesControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findLatestPoolOfSeries: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('findLatestPoolOfSeries', 'id', id)
            const localVarPath = `/series/{id}/pool/latest/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findLatestPoolOfSeries1: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('findLatestPoolOfSeries1', 'id', id)
            const localVarPath = `/series/{id}/pool/latest`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findPoolsOfSeries: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('findPoolsOfSeries', 'id', id)
            const localVarPath = `/series/{id}/pool`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findPoolsOfSeries1: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('findPoolsOfSeries1', 'id', id)
            const localVarPath = `/series/{id}/pool/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findSeriesById: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('findSeriesById', 'id', id)
            const localVarPath = `/series/{id}/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findSeriesById1: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('findSeriesById1', 'id', id)
            const localVarPath = `/series/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * SeriesControllerApi - functional programming interface
 * @export
 */
export const SeriesControllerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = SeriesControllerApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async findLatestPoolOfSeries(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Pool>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.findLatestPoolOfSeries(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async findLatestPoolOfSeries1(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Pool>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.findLatestPoolOfSeries1(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async findPoolsOfSeries(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Pool>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.findPoolsOfSeries(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async findPoolsOfSeries1(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Pool>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.findPoolsOfSeries1(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async findSeriesById(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Series>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.findSeriesById(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async findSeriesById1(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Series>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.findSeriesById1(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * SeriesControllerApi - factory interface
 * @export
 */
export const SeriesControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = SeriesControllerApiFp(configuration)
    return {
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findLatestPoolOfSeries(id: number, options?: any): AxiosPromise<Pool> {
            return localVarFp.findLatestPoolOfSeries(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findLatestPoolOfSeries1(id: number, options?: any): AxiosPromise<Pool> {
            return localVarFp.findLatestPoolOfSeries1(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findPoolsOfSeries(id: number, options?: any): AxiosPromise<Array<Pool>> {
            return localVarFp.findPoolsOfSeries(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findPoolsOfSeries1(id: number, options?: any): AxiosPromise<Array<Pool>> {
            return localVarFp.findPoolsOfSeries1(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findSeriesById(id: number, options?: any): AxiosPromise<Series> {
            return localVarFp.findSeriesById(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findSeriesById1(id: number, options?: any): AxiosPromise<Series> {
            return localVarFp.findSeriesById1(id, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * SeriesControllerApi - object-oriented interface
 * @export
 * @class SeriesControllerApi
 * @extends {BaseAPI}
 */
export class SeriesControllerApi extends BaseAPI {
    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SeriesControllerApi
     */
    public findLatestPoolOfSeries(id: number, options?: AxiosRequestConfig) {
        return SeriesControllerApiFp(this.configuration).findLatestPoolOfSeries(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SeriesControllerApi
     */
    public findLatestPoolOfSeries1(id: number, options?: AxiosRequestConfig) {
        return SeriesControllerApiFp(this.configuration).findLatestPoolOfSeries1(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SeriesControllerApi
     */
    public findPoolsOfSeries(id: number, options?: AxiosRequestConfig) {
        return SeriesControllerApiFp(this.configuration).findPoolsOfSeries(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SeriesControllerApi
     */
    public findPoolsOfSeries1(id: number, options?: AxiosRequestConfig) {
        return SeriesControllerApiFp(this.configuration).findPoolsOfSeries1(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SeriesControllerApi
     */
    public findSeriesById(id: number, options?: AxiosRequestConfig) {
        return SeriesControllerApiFp(this.configuration).findSeriesById(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SeriesControllerApi
     */
    public findSeriesById1(id: number, options?: AxiosRequestConfig) {
        return SeriesControllerApiFp(this.configuration).findSeriesById1(id, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * SupporterControllerApi - axios parameter creator
 * @export
 */
export const SupporterControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} username 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findTeamsOfSupporter: async (username: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'username' is not null or undefined
            assertParamExists('findTeamsOfSupporter', 'username', username)
            const localVarPath = `/supporter/{username}/team/`
                .replace(`{${"username"}}`, encodeURIComponent(String(username)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} username 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findTeamsOfSupporter1: async (username: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'username' is not null or undefined
            assertParamExists('findTeamsOfSupporter1', 'username', username)
            const localVarPath = `/supporter/{username}/team`
                .replace(`{${"username"}}`, encodeURIComponent(String(username)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * SupporterControllerApi - functional programming interface
 * @export
 */
export const SupporterControllerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = SupporterControllerApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {string} username 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async findTeamsOfSupporter(username: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<TeamDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.findTeamsOfSupporter(username, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} username 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async findTeamsOfSupporter1(username: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<TeamDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.findTeamsOfSupporter1(username, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * SupporterControllerApi - factory interface
 * @export
 */
export const SupporterControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = SupporterControllerApiFp(configuration)
    return {
        /**
         * 
         * @param {string} username 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findTeamsOfSupporter(username: string, options?: any): AxiosPromise<Array<TeamDto>> {
            return localVarFp.findTeamsOfSupporter(username, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} username 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findTeamsOfSupporter1(username: string, options?: any): AxiosPromise<Array<TeamDto>> {
            return localVarFp.findTeamsOfSupporter1(username, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * SupporterControllerApi - object-oriented interface
 * @export
 * @class SupporterControllerApi
 * @extends {BaseAPI}
 */
export class SupporterControllerApi extends BaseAPI {
    /**
     * 
     * @param {string} username 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SupporterControllerApi
     */
    public findTeamsOfSupporter(username: string, options?: AxiosRequestConfig) {
        return SupporterControllerApiFp(this.configuration).findTeamsOfSupporter(username, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} username 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SupporterControllerApi
     */
    public findTeamsOfSupporter1(username: string, options?: AxiosRequestConfig) {
        return SupporterControllerApiFp(this.configuration).findTeamsOfSupporter1(username, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * TeamBoRestControllerApi - axios parameter creator
 * @export
 */
export const TeamBoRestControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTeamsById: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getTeamsById', 'id', id)
            const localVarPath = `/bo/team/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTeamsByPoolId: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getTeamsByPoolId', 'id', id)
            const localVarPath = `/bo/team/all/pool/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} [poolId] 
         * @param {string} [queryString] 
         * @param {boolean} [toProcess] 
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        searchTeams: async (poolId?: number, queryString?: string, toProcess?: boolean, page?: number, size?: number, sort?: Array<string>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/bo/team/search`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (poolId !== undefined) {
                localVarQueryParameter['poolId'] = poolId;
            }

            if (queryString !== undefined) {
                localVarQueryParameter['queryString'] = queryString;
            }

            if (toProcess !== undefined) {
                localVarQueryParameter['toProcess'] = toProcess;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }

            if (sort) {
                localVarQueryParameter['sort'] = sort;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {Team} team 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateTeam: async (id: number, team: Team, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('updateTeam', 'id', id)
            // verify required parameter 'team' is not null or undefined
            assertParamExists('updateTeam', 'team', team)
            const localVarPath = `/bo/team/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(team, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * TeamBoRestControllerApi - functional programming interface
 * @export
 */
export const TeamBoRestControllerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = TeamBoRestControllerApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getTeamsById(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Team>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getTeamsById(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getTeamsByPoolId(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Team>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getTeamsByPoolId(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} [poolId] 
         * @param {string} [queryString] 
         * @param {boolean} [toProcess] 
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async searchTeams(poolId?: number, queryString?: string, toProcess?: boolean, page?: number, size?: number, sort?: Array<string>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PageTeam>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.searchTeams(poolId, queryString, toProcess, page, size, sort, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {Team} team 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateTeam(id: number, team: Team, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Team>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateTeam(id, team, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * TeamBoRestControllerApi - factory interface
 * @export
 */
export const TeamBoRestControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = TeamBoRestControllerApiFp(configuration)
    return {
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTeamsById(id: number, options?: any): AxiosPromise<Team> {
            return localVarFp.getTeamsById(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTeamsByPoolId(id: number, options?: any): AxiosPromise<Array<Team>> {
            return localVarFp.getTeamsByPoolId(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} [poolId] 
         * @param {string} [queryString] 
         * @param {boolean} [toProcess] 
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        searchTeams(poolId?: number, queryString?: string, toProcess?: boolean, page?: number, size?: number, sort?: Array<string>, options?: any): AxiosPromise<PageTeam> {
            return localVarFp.searchTeams(poolId, queryString, toProcess, page, size, sort, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {Team} team 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateTeam(id: number, team: Team, options?: any): AxiosPromise<Team> {
            return localVarFp.updateTeam(id, team, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * TeamBoRestControllerApi - object-oriented interface
 * @export
 * @class TeamBoRestControllerApi
 * @extends {BaseAPI}
 */
export class TeamBoRestControllerApi extends BaseAPI {
    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TeamBoRestControllerApi
     */
    public getTeamsById(id: number, options?: AxiosRequestConfig) {
        return TeamBoRestControllerApiFp(this.configuration).getTeamsById(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TeamBoRestControllerApi
     */
    public getTeamsByPoolId(id: number, options?: AxiosRequestConfig) {
        return TeamBoRestControllerApiFp(this.configuration).getTeamsByPoolId(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} [poolId] 
     * @param {string} [queryString] 
     * @param {boolean} [toProcess] 
     * @param {number} [page] Zero-based page index (0..N)
     * @param {number} [size] The size of the page to be returned
     * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TeamBoRestControllerApi
     */
    public searchTeams(poolId?: number, queryString?: string, toProcess?: boolean, page?: number, size?: number, sort?: Array<string>, options?: AxiosRequestConfig) {
        return TeamBoRestControllerApiFp(this.configuration).searchTeams(poolId, queryString, toProcess, page, size, sort, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {Team} team 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TeamBoRestControllerApi
     */
    public updateTeam(id: number, team: Team, options?: AxiosRequestConfig) {
        return TeamBoRestControllerApiFp(this.configuration).updateTeam(id, team, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * TeamControllerApi - axios parameter creator
 * @export
 */
export const TeamControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {number} id 
         * @param {Supporter} supporter 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addSupporterInTeam: async (id: number, supporter: Supporter, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('addSupporterInTeam', 'id', id)
            // verify required parameter 'supporter' is not null or undefined
            assertParamExists('addSupporterInTeam', 'supporter', supporter)
            const localVarPath = `/team/{id}/supporter/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(supporter, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {Supporter} supporter 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addSupporterInTeam1: async (id: number, supporter: Supporter, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('addSupporterInTeam1', 'id', id)
            // verify required parameter 'supporter' is not null or undefined
            assertParamExists('addSupporterInTeam1', 'supporter', supporter)
            const localVarPath = `/team/{id}/supporter`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(supporter, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} teamId 
         * @param {string} username 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteSupporterFromTeam: async (teamId: number, username: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'teamId' is not null or undefined
            assertParamExists('deleteSupporterFromTeam', 'teamId', teamId)
            // verify required parameter 'username' is not null or undefined
            assertParamExists('deleteSupporterFromTeam', 'username', username)
            const localVarPath = `/team/{teamId}/supporter/{username}/`
                .replace(`{${"teamId"}}`, encodeURIComponent(String(teamId)))
                .replace(`{${"username"}}`, encodeURIComponent(String(username)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} teamId 
         * @param {string} username 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteSupporterFromTeam1: async (teamId: number, username: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'teamId' is not null or undefined
            assertParamExists('deleteSupporterFromTeam1', 'teamId', teamId)
            // verify required parameter 'username' is not null or undefined
            assertParamExists('deleteSupporterFromTeam1', 'username', username)
            const localVarPath = `/team/{teamId}/supporter/{username}`
                .replace(`{${"teamId"}}`, encodeURIComponent(String(teamId)))
                .replace(`{${"username"}}`, encodeURIComponent(String(username)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} teamId 
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findMediasByTeamId: async (teamId: number, page?: number, size?: number, sort?: Array<string>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'teamId' is not null or undefined
            assertParamExists('findMediasByTeamId', 'teamId', teamId)
            const localVarPath = `/team/{teamId}/media`
                .replace(`{${"teamId"}}`, encodeURIComponent(String(teamId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }

            if (sort) {
                localVarQueryParameter['sort'] = sort;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} teamId 
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findMediasByTeamId1: async (teamId: number, page?: number, size?: number, sort?: Array<string>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'teamId' is not null or undefined
            assertParamExists('findMediasByTeamId1', 'teamId', teamId)
            const localVarPath = `/team/{teamId}/media/`
                .replace(`{${"teamId"}}`, encodeURIComponent(String(teamId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }

            if (sort) {
                localVarQueryParameter['sort'] = sort;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findSupporterOfTeam: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('findSupporterOfTeam', 'id', id)
            const localVarPath = `/team/{id}/supporter/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findSupporterOfTeam1: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('findSupporterOfTeam1', 'id', id)
            const localVarPath = `/team/{id}/supporter`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findTeamById: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('findTeamById', 'id', id)
            const localVarPath = `/team/{id}/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findTeamById1: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('findTeamById1', 'id', id)
            const localVarPath = `/team/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postUpdateLogoUrls: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/team/technical/updateLogosUrls`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {string} username 
         * @param {Team} team 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        proposeTeamUpdate: async (id: number, username: string, team: Team, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('proposeTeamUpdate', 'id', id)
            // verify required parameter 'username' is not null or undefined
            assertParamExists('proposeTeamUpdate', 'username', username)
            // verify required parameter 'team' is not null or undefined
            assertParamExists('proposeTeamUpdate', 'team', team)
            const localVarPath = `/team/{id}/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (username !== undefined) {
                localVarQueryParameter['username'] = username;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(team, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {string} username 
         * @param {Team} team 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        proposeTeamUpdate1: async (id: number, username: string, team: Team, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('proposeTeamUpdate1', 'id', id)
            // verify required parameter 'username' is not null or undefined
            assertParamExists('proposeTeamUpdate1', 'username', username)
            // verify required parameter 'team' is not null or undefined
            assertParamExists('proposeTeamUpdate1', 'team', team)
            const localVarPath = `/team/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (username !== undefined) {
                localVarQueryParameter['username'] = username;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(team, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} [poolId] 
         * @param {string} [queryString] 
         * @param {boolean} [toProcess] 
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        searchTeam: async (poolId?: number, queryString?: string, toProcess?: boolean, page?: number, size?: number, sort?: Array<string>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/team/search/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (poolId !== undefined) {
                localVarQueryParameter['poolId'] = poolId;
            }

            if (queryString !== undefined) {
                localVarQueryParameter['queryString'] = queryString;
            }

            if (toProcess !== undefined) {
                localVarQueryParameter['toProcess'] = toProcess;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }

            if (sort) {
                localVarQueryParameter['sort'] = sort;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} [poolId] 
         * @param {string} [queryString] 
         * @param {boolean} [toProcess] 
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        searchTeam1: async (poolId?: number, queryString?: string, toProcess?: boolean, page?: number, size?: number, sort?: Array<string>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/team/search`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (poolId !== undefined) {
                localVarQueryParameter['poolId'] = poolId;
            }

            if (queryString !== undefined) {
                localVarQueryParameter['queryString'] = queryString;
            }

            if (toProcess !== undefined) {
                localVarQueryParameter['toProcess'] = toProcess;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }

            if (sort) {
                localVarQueryParameter['sort'] = sort;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} teamId 
         * @param {string} username 
         * @param {string} type 
         * @param {UploadMediaRequest} [uploadMediaRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uploadMedia: async (teamId: number, username: string, type: string, uploadMediaRequest?: UploadMediaRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'teamId' is not null or undefined
            assertParamExists('uploadMedia', 'teamId', teamId)
            // verify required parameter 'username' is not null or undefined
            assertParamExists('uploadMedia', 'username', username)
            // verify required parameter 'type' is not null or undefined
            assertParamExists('uploadMedia', 'type', type)
            const localVarPath = `/team/{teamId}/media`
                .replace(`{${"teamId"}}`, encodeURIComponent(String(teamId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (username !== undefined) {
                localVarQueryParameter['username'] = username;
            }

            if (type !== undefined) {
                localVarQueryParameter['type'] = type;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(uploadMediaRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} teamId 
         * @param {string} username 
         * @param {string} type 
         * @param {UploadMediaRequest} [uploadMediaRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uploadMedia1: async (teamId: number, username: string, type: string, uploadMediaRequest?: UploadMediaRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'teamId' is not null or undefined
            assertParamExists('uploadMedia1', 'teamId', teamId)
            // verify required parameter 'username' is not null or undefined
            assertParamExists('uploadMedia1', 'username', username)
            // verify required parameter 'type' is not null or undefined
            assertParamExists('uploadMedia1', 'type', type)
            const localVarPath = `/team/{teamId}/media/`
                .replace(`{${"teamId"}}`, encodeURIComponent(String(teamId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (username !== undefined) {
                localVarQueryParameter['username'] = username;
            }

            if (type !== undefined) {
                localVarQueryParameter['type'] = type;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(uploadMediaRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * TeamControllerApi - functional programming interface
 * @export
 */
export const TeamControllerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = TeamControllerApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {number} id 
         * @param {Supporter} supporter 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async addSupporterInTeam(id: number, supporter: Supporter, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SupporterOfTeam>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.addSupporterInTeam(id, supporter, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {Supporter} supporter 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async addSupporterInTeam1(id: number, supporter: Supporter, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SupporterOfTeam>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.addSupporterInTeam1(id, supporter, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} teamId 
         * @param {string} username 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteSupporterFromTeam(teamId: number, username: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteSupporterFromTeam(teamId, username, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} teamId 
         * @param {string} username 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteSupporterFromTeam1(teamId: number, username: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteSupporterFromTeam1(teamId, username, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} teamId 
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async findMediasByTeamId(teamId: number, page?: number, size?: number, sort?: Array<string>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PageTeamMedia>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.findMediasByTeamId(teamId, page, size, sort, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} teamId 
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async findMediasByTeamId1(teamId: number, page?: number, size?: number, sort?: Array<string>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PageTeamMedia>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.findMediasByTeamId1(teamId, page, size, sort, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async findSupporterOfTeam(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<SupporterOfTeam>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.findSupporterOfTeam(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async findSupporterOfTeam1(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<SupporterOfTeam>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.findSupporterOfTeam1(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async findTeamById(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TeamDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.findTeamById(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async findTeamById1(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TeamDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.findTeamById1(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postUpdateLogoUrls(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.postUpdateLogoUrls(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {string} username 
         * @param {Team} team 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async proposeTeamUpdate(id: number, username: string, team: Team, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.proposeTeamUpdate(id, username, team, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {string} username 
         * @param {Team} team 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async proposeTeamUpdate1(id: number, username: string, team: Team, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.proposeTeamUpdate1(id, username, team, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} [poolId] 
         * @param {string} [queryString] 
         * @param {boolean} [toProcess] 
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async searchTeam(poolId?: number, queryString?: string, toProcess?: boolean, page?: number, size?: number, sort?: Array<string>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PageTeamDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.searchTeam(poolId, queryString, toProcess, page, size, sort, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} [poolId] 
         * @param {string} [queryString] 
         * @param {boolean} [toProcess] 
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async searchTeam1(poolId?: number, queryString?: string, toProcess?: boolean, page?: number, size?: number, sort?: Array<string>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PageTeamDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.searchTeam1(poolId, queryString, toProcess, page, size, sort, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} teamId 
         * @param {string} username 
         * @param {string} type 
         * @param {UploadMediaRequest} [uploadMediaRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async uploadMedia(teamId: number, username: string, type: string, uploadMediaRequest?: UploadMediaRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.uploadMedia(teamId, username, type, uploadMediaRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} teamId 
         * @param {string} username 
         * @param {string} type 
         * @param {UploadMediaRequest} [uploadMediaRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async uploadMedia1(teamId: number, username: string, type: string, uploadMediaRequest?: UploadMediaRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.uploadMedia1(teamId, username, type, uploadMediaRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * TeamControllerApi - factory interface
 * @export
 */
export const TeamControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = TeamControllerApiFp(configuration)
    return {
        /**
         * 
         * @param {number} id 
         * @param {Supporter} supporter 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addSupporterInTeam(id: number, supporter: Supporter, options?: any): AxiosPromise<SupporterOfTeam> {
            return localVarFp.addSupporterInTeam(id, supporter, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {Supporter} supporter 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addSupporterInTeam1(id: number, supporter: Supporter, options?: any): AxiosPromise<SupporterOfTeam> {
            return localVarFp.addSupporterInTeam1(id, supporter, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} teamId 
         * @param {string} username 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteSupporterFromTeam(teamId: number, username: string, options?: any): AxiosPromise<void> {
            return localVarFp.deleteSupporterFromTeam(teamId, username, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} teamId 
         * @param {string} username 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteSupporterFromTeam1(teamId: number, username: string, options?: any): AxiosPromise<void> {
            return localVarFp.deleteSupporterFromTeam1(teamId, username, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} teamId 
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findMediasByTeamId(teamId: number, page?: number, size?: number, sort?: Array<string>, options?: any): AxiosPromise<PageTeamMedia> {
            return localVarFp.findMediasByTeamId(teamId, page, size, sort, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} teamId 
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findMediasByTeamId1(teamId: number, page?: number, size?: number, sort?: Array<string>, options?: any): AxiosPromise<PageTeamMedia> {
            return localVarFp.findMediasByTeamId1(teamId, page, size, sort, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findSupporterOfTeam(id: number, options?: any): AxiosPromise<Array<SupporterOfTeam>> {
            return localVarFp.findSupporterOfTeam(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findSupporterOfTeam1(id: number, options?: any): AxiosPromise<Array<SupporterOfTeam>> {
            return localVarFp.findSupporterOfTeam1(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findTeamById(id: number, options?: any): AxiosPromise<TeamDto> {
            return localVarFp.findTeamById(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findTeamById1(id: number, options?: any): AxiosPromise<TeamDto> {
            return localVarFp.findTeamById1(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postUpdateLogoUrls(options?: any): AxiosPromise<void> {
            return localVarFp.postUpdateLogoUrls(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {string} username 
         * @param {Team} team 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        proposeTeamUpdate(id: number, username: string, team: Team, options?: any): AxiosPromise<void> {
            return localVarFp.proposeTeamUpdate(id, username, team, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {string} username 
         * @param {Team} team 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        proposeTeamUpdate1(id: number, username: string, team: Team, options?: any): AxiosPromise<void> {
            return localVarFp.proposeTeamUpdate1(id, username, team, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} [poolId] 
         * @param {string} [queryString] 
         * @param {boolean} [toProcess] 
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        searchTeam(poolId?: number, queryString?: string, toProcess?: boolean, page?: number, size?: number, sort?: Array<string>, options?: any): AxiosPromise<PageTeamDto> {
            return localVarFp.searchTeam(poolId, queryString, toProcess, page, size, sort, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} [poolId] 
         * @param {string} [queryString] 
         * @param {boolean} [toProcess] 
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        searchTeam1(poolId?: number, queryString?: string, toProcess?: boolean, page?: number, size?: number, sort?: Array<string>, options?: any): AxiosPromise<PageTeamDto> {
            return localVarFp.searchTeam1(poolId, queryString, toProcess, page, size, sort, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} teamId 
         * @param {string} username 
         * @param {string} type 
         * @param {UploadMediaRequest} [uploadMediaRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uploadMedia(teamId: number, username: string, type: string, uploadMediaRequest?: UploadMediaRequest, options?: any): AxiosPromise<void> {
            return localVarFp.uploadMedia(teamId, username, type, uploadMediaRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} teamId 
         * @param {string} username 
         * @param {string} type 
         * @param {UploadMediaRequest} [uploadMediaRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uploadMedia1(teamId: number, username: string, type: string, uploadMediaRequest?: UploadMediaRequest, options?: any): AxiosPromise<void> {
            return localVarFp.uploadMedia1(teamId, username, type, uploadMediaRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * TeamControllerApi - object-oriented interface
 * @export
 * @class TeamControllerApi
 * @extends {BaseAPI}
 */
export class TeamControllerApi extends BaseAPI {
    /**
     * 
     * @param {number} id 
     * @param {Supporter} supporter 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TeamControllerApi
     */
    public addSupporterInTeam(id: number, supporter: Supporter, options?: AxiosRequestConfig) {
        return TeamControllerApiFp(this.configuration).addSupporterInTeam(id, supporter, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {Supporter} supporter 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TeamControllerApi
     */
    public addSupporterInTeam1(id: number, supporter: Supporter, options?: AxiosRequestConfig) {
        return TeamControllerApiFp(this.configuration).addSupporterInTeam1(id, supporter, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} teamId 
     * @param {string} username 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TeamControllerApi
     */
    public deleteSupporterFromTeam(teamId: number, username: string, options?: AxiosRequestConfig) {
        return TeamControllerApiFp(this.configuration).deleteSupporterFromTeam(teamId, username, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} teamId 
     * @param {string} username 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TeamControllerApi
     */
    public deleteSupporterFromTeam1(teamId: number, username: string, options?: AxiosRequestConfig) {
        return TeamControllerApiFp(this.configuration).deleteSupporterFromTeam1(teamId, username, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} teamId 
     * @param {number} [page] Zero-based page index (0..N)
     * @param {number} [size] The size of the page to be returned
     * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TeamControllerApi
     */
    public findMediasByTeamId(teamId: number, page?: number, size?: number, sort?: Array<string>, options?: AxiosRequestConfig) {
        return TeamControllerApiFp(this.configuration).findMediasByTeamId(teamId, page, size, sort, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} teamId 
     * @param {number} [page] Zero-based page index (0..N)
     * @param {number} [size] The size of the page to be returned
     * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TeamControllerApi
     */
    public findMediasByTeamId1(teamId: number, page?: number, size?: number, sort?: Array<string>, options?: AxiosRequestConfig) {
        return TeamControllerApiFp(this.configuration).findMediasByTeamId1(teamId, page, size, sort, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TeamControllerApi
     */
    public findSupporterOfTeam(id: number, options?: AxiosRequestConfig) {
        return TeamControllerApiFp(this.configuration).findSupporterOfTeam(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TeamControllerApi
     */
    public findSupporterOfTeam1(id: number, options?: AxiosRequestConfig) {
        return TeamControllerApiFp(this.configuration).findSupporterOfTeam1(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TeamControllerApi
     */
    public findTeamById(id: number, options?: AxiosRequestConfig) {
        return TeamControllerApiFp(this.configuration).findTeamById(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TeamControllerApi
     */
    public findTeamById1(id: number, options?: AxiosRequestConfig) {
        return TeamControllerApiFp(this.configuration).findTeamById1(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TeamControllerApi
     */
    public postUpdateLogoUrls(options?: AxiosRequestConfig) {
        return TeamControllerApiFp(this.configuration).postUpdateLogoUrls(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {string} username 
     * @param {Team} team 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TeamControllerApi
     */
    public proposeTeamUpdate(id: number, username: string, team: Team, options?: AxiosRequestConfig) {
        return TeamControllerApiFp(this.configuration).proposeTeamUpdate(id, username, team, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {string} username 
     * @param {Team} team 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TeamControllerApi
     */
    public proposeTeamUpdate1(id: number, username: string, team: Team, options?: AxiosRequestConfig) {
        return TeamControllerApiFp(this.configuration).proposeTeamUpdate1(id, username, team, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} [poolId] 
     * @param {string} [queryString] 
     * @param {boolean} [toProcess] 
     * @param {number} [page] Zero-based page index (0..N)
     * @param {number} [size] The size of the page to be returned
     * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TeamControllerApi
     */
    public searchTeam(poolId?: number, queryString?: string, toProcess?: boolean, page?: number, size?: number, sort?: Array<string>, options?: AxiosRequestConfig) {
        return TeamControllerApiFp(this.configuration).searchTeam(poolId, queryString, toProcess, page, size, sort, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} [poolId] 
     * @param {string} [queryString] 
     * @param {boolean} [toProcess] 
     * @param {number} [page] Zero-based page index (0..N)
     * @param {number} [size] The size of the page to be returned
     * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TeamControllerApi
     */
    public searchTeam1(poolId?: number, queryString?: string, toProcess?: boolean, page?: number, size?: number, sort?: Array<string>, options?: AxiosRequestConfig) {
        return TeamControllerApiFp(this.configuration).searchTeam1(poolId, queryString, toProcess, page, size, sort, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} teamId 
     * @param {string} username 
     * @param {string} type 
     * @param {UploadMediaRequest} [uploadMediaRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TeamControllerApi
     */
    public uploadMedia(teamId: number, username: string, type: string, uploadMediaRequest?: UploadMediaRequest, options?: AxiosRequestConfig) {
        return TeamControllerApiFp(this.configuration).uploadMedia(teamId, username, type, uploadMediaRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} teamId 
     * @param {string} username 
     * @param {string} type 
     * @param {UploadMediaRequest} [uploadMediaRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TeamControllerApi
     */
    public uploadMedia1(teamId: number, username: string, type: string, uploadMediaRequest?: UploadMediaRequest, options?: AxiosRequestConfig) {
        return TeamControllerApiFp(this.configuration).uploadMedia1(teamId, username, type, uploadMediaRequest, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * TeamMediaBoRestControllerApi - axios parameter creator
 * @export
 */
export const TeamMediaBoRestControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {AddMediaToTeamRequest} [addMediaToTeamRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addMediaToTeam: async (addMediaToTeamRequest?: AddMediaToTeamRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/bo/teamMedia/addMedia`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(addMediaToTeamRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteTeamMediaById: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('deleteTeamMediaById', 'id', id)
            const localVarPath = `/bo/teamMedia/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} [teamId] 
         * @param {boolean} [validated] 
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        searchTeamMedias: async (teamId?: number, validated?: boolean, page?: number, size?: number, sort?: Array<string>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/bo/teamMedia/search`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (teamId !== undefined) {
                localVarQueryParameter['teamId'] = teamId;
            }

            if (validated !== undefined) {
                localVarQueryParameter['validated'] = validated;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }

            if (sort) {
                localVarQueryParameter['sort'] = sort;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        validateTeamMedia: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('validateTeamMedia', 'id', id)
            const localVarPath = `/bo/teamMedia/validate/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * TeamMediaBoRestControllerApi - functional programming interface
 * @export
 */
export const TeamMediaBoRestControllerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = TeamMediaBoRestControllerApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {AddMediaToTeamRequest} [addMediaToTeamRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async addMediaToTeam(addMediaToTeamRequest?: AddMediaToTeamRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TeamMedia>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.addMediaToTeam(addMediaToTeamRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteTeamMediaById(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteTeamMediaById(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} [teamId] 
         * @param {boolean} [validated] 
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async searchTeamMedias(teamId?: number, validated?: boolean, page?: number, size?: number, sort?: Array<string>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PageTeamMedia>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.searchTeamMedias(teamId, validated, page, size, sort, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async validateTeamMedia(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.validateTeamMedia(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * TeamMediaBoRestControllerApi - factory interface
 * @export
 */
export const TeamMediaBoRestControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = TeamMediaBoRestControllerApiFp(configuration)
    return {
        /**
         * 
         * @param {AddMediaToTeamRequest} [addMediaToTeamRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addMediaToTeam(addMediaToTeamRequest?: AddMediaToTeamRequest, options?: any): AxiosPromise<TeamMedia> {
            return localVarFp.addMediaToTeam(addMediaToTeamRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteTeamMediaById(id: number, options?: any): AxiosPromise<void> {
            return localVarFp.deleteTeamMediaById(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} [teamId] 
         * @param {boolean} [validated] 
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        searchTeamMedias(teamId?: number, validated?: boolean, page?: number, size?: number, sort?: Array<string>, options?: any): AxiosPromise<PageTeamMedia> {
            return localVarFp.searchTeamMedias(teamId, validated, page, size, sort, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        validateTeamMedia(id: number, options?: any): AxiosPromise<void> {
            return localVarFp.validateTeamMedia(id, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * TeamMediaBoRestControllerApi - object-oriented interface
 * @export
 * @class TeamMediaBoRestControllerApi
 * @extends {BaseAPI}
 */
export class TeamMediaBoRestControllerApi extends BaseAPI {
    /**
     * 
     * @param {AddMediaToTeamRequest} [addMediaToTeamRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TeamMediaBoRestControllerApi
     */
    public addMediaToTeam(addMediaToTeamRequest?: AddMediaToTeamRequest, options?: AxiosRequestConfig) {
        return TeamMediaBoRestControllerApiFp(this.configuration).addMediaToTeam(addMediaToTeamRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TeamMediaBoRestControllerApi
     */
    public deleteTeamMediaById(id: number, options?: AxiosRequestConfig) {
        return TeamMediaBoRestControllerApiFp(this.configuration).deleteTeamMediaById(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} [teamId] 
     * @param {boolean} [validated] 
     * @param {number} [page] Zero-based page index (0..N)
     * @param {number} [size] The size of the page to be returned
     * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TeamMediaBoRestControllerApi
     */
    public searchTeamMedias(teamId?: number, validated?: boolean, page?: number, size?: number, sort?: Array<string>, options?: AxiosRequestConfig) {
        return TeamMediaBoRestControllerApiFp(this.configuration).searchTeamMedias(teamId, validated, page, size, sort, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TeamMediaBoRestControllerApi
     */
    public validateTeamMedia(id: number, options?: AxiosRequestConfig) {
        return TeamMediaBoRestControllerApiFp(this.configuration).validateTeamMedia(id, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * TeamUpdateProposalBoRestControllerApi - axios parameter creator
 * @export
 */
export const TeamUpdateProposalBoRestControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {number} teamId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTeamUpdatesByTeamId: async (teamId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'teamId' is not null or undefined
            assertParamExists('getTeamUpdatesByTeamId', 'teamId', teamId)
            const localVarPath = `/bo/teamUpdateProposal/all/team/{teamId}`
                .replace(`{${"teamId"}}`, encodeURIComponent(String(teamId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setTeamUpdateAsProcessed: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('setTeamUpdateAsProcessed', 'id', id)
            const localVarPath = `/bo/teamUpdateProposal/processed/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} teamId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setTeamUpdateAsProcessedForAllOfTeam: async (teamId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'teamId' is not null or undefined
            assertParamExists('setTeamUpdateAsProcessedForAllOfTeam', 'teamId', teamId)
            const localVarPath = `/bo/teamUpdateProposal/processed/team/{teamId}`
                .replace(`{${"teamId"}}`, encodeURIComponent(String(teamId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * TeamUpdateProposalBoRestControllerApi - functional programming interface
 * @export
 */
export const TeamUpdateProposalBoRestControllerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = TeamUpdateProposalBoRestControllerApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {number} teamId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getTeamUpdatesByTeamId(teamId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<TeamUpdateProposal>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getTeamUpdatesByTeamId(teamId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async setTeamUpdateAsProcessed(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.setTeamUpdateAsProcessed(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} teamId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async setTeamUpdateAsProcessedForAllOfTeam(teamId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.setTeamUpdateAsProcessedForAllOfTeam(teamId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * TeamUpdateProposalBoRestControllerApi - factory interface
 * @export
 */
export const TeamUpdateProposalBoRestControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = TeamUpdateProposalBoRestControllerApiFp(configuration)
    return {
        /**
         * 
         * @param {number} teamId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTeamUpdatesByTeamId(teamId: number, options?: any): AxiosPromise<Array<TeamUpdateProposal>> {
            return localVarFp.getTeamUpdatesByTeamId(teamId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setTeamUpdateAsProcessed(id: number, options?: any): AxiosPromise<void> {
            return localVarFp.setTeamUpdateAsProcessed(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} teamId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setTeamUpdateAsProcessedForAllOfTeam(teamId: number, options?: any): AxiosPromise<void> {
            return localVarFp.setTeamUpdateAsProcessedForAllOfTeam(teamId, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * TeamUpdateProposalBoRestControllerApi - object-oriented interface
 * @export
 * @class TeamUpdateProposalBoRestControllerApi
 * @extends {BaseAPI}
 */
export class TeamUpdateProposalBoRestControllerApi extends BaseAPI {
    /**
     * 
     * @param {number} teamId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TeamUpdateProposalBoRestControllerApi
     */
    public getTeamUpdatesByTeamId(teamId: number, options?: AxiosRequestConfig) {
        return TeamUpdateProposalBoRestControllerApiFp(this.configuration).getTeamUpdatesByTeamId(teamId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TeamUpdateProposalBoRestControllerApi
     */
    public setTeamUpdateAsProcessed(id: number, options?: AxiosRequestConfig) {
        return TeamUpdateProposalBoRestControllerApiFp(this.configuration).setTeamUpdateAsProcessed(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} teamId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TeamUpdateProposalBoRestControllerApi
     */
    public setTeamUpdateAsProcessedForAllOfTeam(teamId: number, options?: AxiosRequestConfig) {
        return TeamUpdateProposalBoRestControllerApiFp(this.configuration).setTeamUpdateAsProcessedForAllOfTeam(teamId, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * UtilControllerApi - axios parameter creator
 * @export
 */
export const UtilControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        computeQualifiedNames: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/util/computeQualifiedNames/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * UtilControllerApi - functional programming interface
 * @export
 */
export const UtilControllerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = UtilControllerApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async computeQualifiedNames(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.computeQualifiedNames(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * UtilControllerApi - factory interface
 * @export
 */
export const UtilControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = UtilControllerApiFp(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        computeQualifiedNames(options?: any): AxiosPromise<void> {
            return localVarFp.computeQualifiedNames(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * UtilControllerApi - object-oriented interface
 * @export
 * @class UtilControllerApi
 * @extends {BaseAPI}
 */
export class UtilControllerApi extends BaseAPI {
    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UtilControllerApi
     */
    public computeQualifiedNames(options?: AxiosRequestConfig) {
        return UtilControllerApiFp(this.configuration).computeQualifiedNames(options).then((request) => request(this.axios, this.basePath));
    }
}




import {LatLngLiteral} from "leaflet";

export const MapUtils = {

  stringToLatLngLiteral: (coordinates: string | undefined): LatLngLiteral | undefined => {

    if(!coordinates) {
      return undefined;
    }

    const split = coordinates.split(',');
    return {
      lat: Number(split[0]),
      lng: Number(split[1])
    }
  },

  latLngToString: (coordinates: LatLngLiteral | undefined): string | undefined => {

    if(!coordinates) {
      return undefined;
    }

    return `${coordinates.lat},${coordinates.lng}`
  }

}
